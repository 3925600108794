import React from "react";
import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import { DisplayGroup, setting_type_group } from "../../../../models/monitor/MonitorDetail.model";
import StatusLightItem from "./StatusLightItem";
import TextAndImgAndGraphItem from "./TextAndImgAndGraphItem";
import BtnAndTextInputItem from "./BtnAndTextInputItem";
interface CardImageProps {
  floodgateId: string;
  displaygroup?: setting_type_group[];
  socketData: { [key: string]: { [key: string]: string } };
  initialData: Array<{ [key: string]: { [key: string]: string }; }> | null;
}

const BoxContentIsArray = (item_index: number, name_group: string, children: React.ReactNode) => {
  return (
    <>
      <Grid item xs={12} sm={12} md={12} lg={12} sx={{
        borderRadius: "0.6rem",
        marginBottom: 2,
      }} key={`keyitem-${item_index}`}>
        <Card className="shadow-lg rounded-lg" sx={{
          textAlign: "center",
          borderRadius: "12px",
          backgroundColor: "rgb(244 246 255)",
          boxShadow: "none",
        }} key={`carditem-${item_index}`}>
          <div className="bg-blue-200 p-4 border-b border-gray-200">
            <Typography textAlign={"left"} variant="h6" component="div">
              {name_group}
            </Typography>
          </div>
          <CardContent key={`contentitem-${item_index}`}>
            <Box display="flex" flexWrap="wrap" alignItems="center" justifyContent="flex-start" key={`boxcontentitem-${item_index}`}>
              {/************* content */}
              {children}
              {/************* content */}
            </Box>
          </CardContent>
        </Card>
      </Grid>
    </>
  );
};

const NodeItem: React.FC<CardImageProps> = ({
  floodgateId,
  displaygroup,
  socketData,
  initialData,
}) => {
  return (
    <Grid container spacing={2} key={`gridfloodgateId-${floodgateId}`}>
      <Grid
        item
        xs={12}
        sx={{
          borderRadius: "0.6rem",
        }}
        key={`gridfloodgateId1-${floodgateId}`}
      >
        <Grid container spacing={2} key={`gridfloodgateId2-${floodgateId}`}>
          {Array.isArray(displaygroup) && displaygroup.map((display_group, index) => (
            <React.Fragment key={`displaygroup-${index}`}>
              {
                <Grid item xs={12} sm={12} md={12} lg={12} sx={{
                  borderRadius: "0.6rem",
                  marginBottom: 2,
                }} key={`griddisplaygroup-${index}`}>
                  <Card className="shadow-lg rounded-lg" sx={{
                    textAlign: "center",
                    borderRadius: "12px",
                    backgroundColor: "#e3edff",
                    boxShadow: "none",
                  }} key={`carddisplaygroup-${index}`}>
                    <div className="bg-blue-300 p-4 border-b border-gray-200">
                      <Typography variant="h6" component="div">
                        {display_group.setting_type.setting_type_name}
                      </Typography>
                    </div>
                    <CardContent key={`contentdisplaygroup-${index}`}>
                      <Box display="flex" flexWrap="wrap" alignItems="center" justifyContent="flex-start" key={`boxcontentdisplaygroup-${index}`}>
                        {/************* content */}
                        {display_group.item.map((item, item_index) => (
                          <>
                            {item.isArrayVariable && item.display_object.length > 1 ? (
                              BoxContentIsArray(item_index,
                                display_group.setting_type.setting_type_name + " (" + item.setting_node_data_key + ")",
                                (item.display_object.map((display_group_item, display_object_index) => (
                                  <React.Fragment key={`displayobjectitem-${display_object_index}`}>
                                    {
                                      display_group_item.is_control && (
                                        <BtnAndTextInputItem
                                          display_group_item={display_group_item}
                                          index={index}
                                          floodgateId={floodgateId}
                                        />
                                      )
                                    }
                                    {
                                      display_group_item.is_monitor && (display_group_item.is_img || display_group_item.is_graph) && (
                                        <TextAndImgAndGraphItem
                                          display_group_item={display_group_item}
                                          index={index}
                                          socketData={socketData}
                                          floodgateId={floodgateId}
                                          initialData={initialData}
                                        />
                                      )
                                    }
                                    {
                                      display_group_item.is_monitor && display_group_item.is_status_light && (
                                        <StatusLightItem
                                          display_group_item={display_group_item}
                                          index={index}
                                          socketData={socketData}
                                          floodgateId={floodgateId}
                                        />
                                      )
                                    }
                                  </React.Fragment>
                                ))
                                ))) : (
                              item.display_object.map((display_group_item, display_object_index) => (
                                <React.Fragment key={`displayobjectitem-${display_object_index}`}>
                                  {
                                    display_group_item.is_control && (
                                      <BtnAndTextInputItem
                                        display_group_item={display_group_item}
                                        index={index}
                                        floodgateId={floodgateId}
                                      />
                                    )
                                  }
                                  {
                                    display_group_item.is_monitor && (display_group_item.is_img || display_group_item.is_graph) && (
                                      <TextAndImgAndGraphItem
                                        display_group_item={display_group_item}
                                        index={index}
                                        socketData={socketData}
                                        floodgateId={floodgateId}
                                        initialData={initialData}
                                      />
                                    )
                                  }
                                  {
                                    display_group_item.is_monitor && display_group_item.is_status_light && (
                                      <StatusLightItem
                                        display_group_item={display_group_item}
                                        index={index}
                                        socketData={socketData}
                                        floodgateId={floodgateId}
                                      />
                                    )
                                  }
                                </React.Fragment>
                              ))
                            )}
                          </>
                        ))}

                        {/************* content */}
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
              }
            </React.Fragment>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default NodeItem;
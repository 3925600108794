import React, { useState, useEffect } from 'react';
import { Modal, Box, Button, Typography, TextField, Grid, MenuItem } from '@mui/material';
import { useAppDispatch } from '../../../stores/store';
import { manageUserInfo } from '../../../stores/slices/ManageUserInfoSlice';
import { fetchUserRoles } from '../../../stores/slices/UserRolesSlice';
import { ManageUserInfoRequest } from '../../../models/userInfo/ManageUserInfo.model';
import { toast } from 'react-toastify';

interface UserEditModalProps {
    open: boolean;
    onClose: () => void;
    user: ManageUserInfoRequest;
    onSave: (updatedUser: ManageUserInfoRequest) => void;
}

const UserEditModal: React.FC<UserEditModalProps> = ({ open, onClose, user, onSave }) => {
    const dispatch = useAppDispatch();
    const [editUser, setEditUser] = useState(user);
    const [roles, setRoles] = useState<{ role_account_id: string; role_account_name: string }[]>([]);
    const [errors, setErrors] = useState<{ [key: string]: string }>({});

    useEffect(() => {
        setEditUser(user); // รีเซ็ตค่าเมื่อ `user` เปลี่ยนแปลง
    }, [user]);

    useEffect(() => {
        const fetchRoles = async () => {
            const fetchedRoles = await dispatch(fetchUserRoles(true)).unwrap();
            if (Array.isArray(fetchedRoles)) {
                setRoles(fetchedRoles);
            }
        };
        fetchRoles();
    }, [dispatch]);

    const validate = () => {
        let tempErrors: { [key: string]: string } = {};

        // if (!editUser.role_account_id) tempErrors.role_account_id = 'กรุณาเลือกกลุ่มผู้ใช้งาน';
        // if (!editUser.account_username) tempErrors.account_username = 'กรุณาใส่ username';
        // if (!editUser.account_password) tempErrors.account_password = 'กรุณาใส่ password';
        if (!editUser.user_prefix) tempErrors.user_prefix = 'กรุณาเลือกคำนำหน้า';
        if (!editUser.user_first_name_th || !/^[ก-๙]+$/.test(editUser.user_first_name_th)) tempErrors.user_first_name_th = 'กรุณาใส่ชื่อภาษาไทยที่ถูกต้อง';
        if (!editUser.user_last_name_th || !/^[ก-๙]+$/.test(editUser.user_last_name_th)) tempErrors.user_last_name_th = 'กรุณาใส่นามสกุลภาษาไทยที่ถูกต้อง';
        if (!editUser.user_first_name_en || !/^[a-zA-Z]+$/.test(editUser.user_first_name_en)) tempErrors.user_first_name_en = 'กรุณาใส่ชื่อภาษาอังกฤษที่ถูกต้อง';
        if (!editUser.user_last_name_en || !/^[a-zA-Z]+$/.test(editUser.user_last_name_en)) tempErrors.user_last_name_en = 'กรุณาใส่นามสกุลภาษาอังกฤษที่ถูกต้อง';
        if (!editUser.user_phone_number || !/^\d{10}$/.test(editUser.user_phone_number)) tempErrors.user_phone_number = 'กรุณาใส่เบอร์โทรศัพท์ที่ถูกต้อง';
        if (!editUser.user_age || isNaN(Number(editUser.user_age))) tempErrors.user_age = 'กรุณาใส่อายุที่ถูกต้อง';
        if (!editUser.user_email || !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(editUser.user_email)) tempErrors.user_email = 'กรุณาใส่อีเมลล์ที่ถูกต้อง';

        setErrors(tempErrors);
        return Object.keys(tempErrors).length === 0;
    };

    const handleChange = (field: keyof ManageUserInfoRequest) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setEditUser({ ...editUser, [field]: event.target.value });
    };

    const handleSave = async () => {
        if (validate()) {
            try {
                const response = await dispatch(
                    manageUserInfo({ ...editUser, action: 'update' })
                ).unwrap();
                if (response.status) {
                    toast.success('บันทึกข้อมูลสำเร็จ');
                    onSave(editUser); // ส่งข้อมูลที่อัพเดตกลับไปยังตาราง
                    onClose(); // ปิด modal หลังบันทึกสำเร็จ
                } else {
                    toast.error('บันทึกข้อมูลไม่สำเร็จ');
                }
            } catch (error) {
                toast.error('เกิดข้อผิดพลาดในการบันทึก');
            }
        }
    };

    return (
        <Modal open={open} onClose={onClose}>
            <Box sx={{ ...style }}>
                <Typography variant="h6" component="h2" gutterBottom>
                    แก้ไขข้อมูลผู้ใช้งาน
                </Typography>

                <Grid container spacing={2}>

                    <Grid item xs={12} sm={12} >
                        <Grid container spacing={2} component="fieldset" 
                        sx={{
                            border: "1px solid red",
                            margin: "8px",
                            padding: "8px 25px 25px 8px",   
                            borderRadius: "4px"
                        }}>
                        <legend style={{ color: "red", padding: "5px"}}>หากไม่มีความประสงค์ในการแก้ไขข้อมูลส่วนนี้ ท่านสามารถเว้นว่างไว้ได้</legend>
                            <Grid item xs={12} sm={3}>
                                <TextField
                                    fullWidth
                                    label="username"
                                    value={editUser.account_username}
                                    onChange={handleChange('account_username')}
                                    error={!!errors.account_username}
                                    helperText={errors.account_username}
                                />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <TextField
                                    fullWidth
                                    label="password"
                                    type="password"
                                    value={editUser.account_password}
                                    onChange={handleChange('account_password')}
                                    error={!!errors.account_password}
                                    helperText={errors.account_password}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    select
                                    fullWidth
                                    label="กลุ่มผู้ใช้งาน"
                                    value={editUser.role_account_id}
                                    onChange={handleChange('role_account_id')}
                                    error={!!errors.role_account_id}
                                    helperText={errors.role_account_id}
                                >
                                    {roles.map((role) => (
                                        <MenuItem key={role.role_account_id} value={role.role_account_id}>
                                            {role.role_account_name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                        </Grid>

                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <TextField
                            select
                            fullWidth
                            label="คำนำหน้า"
                            value={editUser.user_prefix}
                            onChange={handleChange('user_prefix')}
                            error={!!errors.user_prefix}
                            helperText={errors.user_prefix}
                        >
                            <MenuItem value="นาย">นาย</MenuItem>
                            <MenuItem value="นาง">นาง</MenuItem>
                            <MenuItem value="นางสาว">นางสาว</MenuItem>
                        </TextField>
                    </Grid>
                    <Grid item xs={12} sm={5}>
                        <TextField
                            fullWidth
                            label="ชื่อภาษาไทย"
                            value={editUser.user_first_name_th}
                            onChange={handleChange('user_first_name_th')}
                            error={!!errors.user_first_name_th}
                            helperText={errors.user_first_name_th}
                        />
                    </Grid>
                    <Grid item xs={12} sm={5}>
                        <TextField
                            fullWidth
                            label="นามสกุลภาษาไทย"
                            value={editUser.user_last_name_th}
                            onChange={handleChange('user_last_name_th')}
                            error={!!errors.user_last_name_th}
                            helperText={errors.user_last_name_th}
                        />
                    </Grid>
                    <Grid item xs={12} sm={5}>
                        <TextField
                            fullWidth
                            label="ชื่อภาษาอังกฤษ"
                            value={editUser.user_first_name_en}
                            onChange={handleChange('user_first_name_en')}
                            error={!!errors.user_first_name_en}
                            helperText={errors.user_first_name_en}
                        />
                    </Grid>
                    <Grid item xs={12} sm={5}>
                        <TextField
                            fullWidth
                            label="นามสกุลภาษาอังกฤษ"
                            value={editUser.user_last_name_en}
                            onChange={handleChange('user_last_name_en')}
                            error={!!errors.user_last_name_en}
                            helperText={errors.user_last_name_en}
                        />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <TextField
                            fullWidth
                            label="อายุ"
                            value={editUser.user_age}
                            onChange={handleChange('user_age')}
                            error={!!errors.user_age}
                            helperText={errors.user_age}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="เบอร์โทรศัพท์"
                            value={editUser.user_phone_number}
                            onChange={handleChange('user_phone_number')}
                            error={!!errors.user_phone_number}
                            helperText={errors.user_phone_number}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="อีเมลล์"
                            value={editUser.user_email}
                            onChange={handleChange('user_email')}
                            error={!!errors.user_email}
                            helperText={errors.user_email}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="บ้านเลขที่"
                            value={editUser.user_addr_house_num}
                            onChange={handleChange('user_addr_house_num')}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="ชั้น"
                            value={editUser.user_addr_floor}
                            onChange={handleChange('user_addr_floor')}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="หมู่บ้าน"
                            value={editUser.user_addr_village}
                            onChange={handleChange('user_addr_village')}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="บ้านเลขที่"
                            value={editUser.user_addr_village_num}
                            onChange={handleChange('user_addr_village_num')}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="ตรอก"
                            value={editUser.user_addr_alley}
                            onChange={handleChange('user_addr_alley')}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="ซอย"
                            value={editUser.user_addr_soi}
                            onChange={handleChange('user_addr_soi')}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="ถนน"
                            value={editUser.user_addr_road}
                            onChange={handleChange('user_addr_road')}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="ตำบล"
                            value={editUser.user_addr_subdistrict}
                            onChange={handleChange('user_addr_subdistrict')}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="เขต/อำเภอ"
                            value={editUser.user_addr_district}
                            onChange={handleChange('user_addr_district')}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="จังหวัด"
                            value={editUser.user_addr_province}
                            onChange={handleChange('user_addr_province')}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="ไปรษณีย์"
                            value={editUser.user_addr_postcode}
                            onChange={handleChange('user_addr_postcode')}
                        />
                    </Grid>
                </Grid>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                    <Button onClick={onClose} sx={{ mr: 2 }}>
                        ยกเลิก
                    </Button>
                    <Button variant="contained" onClick={handleSave}>
                        บันทึก
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    maxHeight: '80vh',
    bgcolor: 'background.paper',
    borderRadius: 2,
    boxShadow: 24,
    p: 4,
    overflowY: 'auto',
};

export default UserEditModal;
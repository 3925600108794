import { configureStore } from "@reduxjs/toolkit";
import { useSelector, useDispatch, TypedUseSelectorHook } from "react-redux";

import loginReducer from "./slices/LoginSlice";
import floodGateInfoReducer from "./slices/FloodGateInfoSlice";
import userRolesReducer from "./slices/UserRolesSlice";
import userInfoReducer from "./slices/UserInfoSlice";
import manageUserInfoReducer from "./slices/ManageUserInfoSlice";
import floodgateDetailReducer from "./slices/FloodgateDetailSlice";
import manageFloodgateInfoReducer from "./slices/ManageFloodgateInfoSlice";
import manageFloodgateSchemaReducer from "./slices/ManageFloodgateSchemaSlice";
import manageFloodgateTypeReducer from "./slices/ManageFloodgateTypeSlice";
import manageFloodgateNode from "./slices/ManageFloodgateNodeSlice";
import manageFloodgateNodeTypeDisplayReducer from "./slices/ManageFloodgateNodeTypeDisplaySlice";
import floodgateRelationReducer from "./slices/FloodgateRelationSlice";
import manageFloodgateImageReducer from "./slices/ManageFloodgateImageSlice";
import monitorDetailReducer from "./slices/MonitorDetailSlice";
import controlFloodgateReducer from "./slices/ControlFloodgateSlice";
import transationMonitorReducer from "./slices/FloodgateTransationMonitorSlice";
import transationReportSelector from "./slices/FloodgateTransationReportSlice";
import transationReportSelector1 from "./slices/FloodgateTransationReportSlice1";
import monitorLogsReportSelector from "./slices/MonutorLogsReportSlice";
import monitorLogsReportSelector1 from "./slices/MonutorLogsReportSlice1";

const loggerMiddleware = (store: any) => (next: any) => (action: any) => {
    //console.log('Dispatching action:', action);
    const result = next(action);
    //console.log('Next state:', store.getState());
    return result;
};

const store = configureStore({
    reducer: {
        login: loginReducer,
        floodGateInfo: floodGateInfoReducer,
        userRoles: userRolesReducer,
        userInfo: userInfoReducer,
        manageUserInfo: manageUserInfoReducer,
        floodgateDetail: floodgateDetailReducer,
        manageFloodgateInfo: manageFloodgateInfoReducer,
        manageFloodgateSchema: manageFloodgateSchemaReducer,
        manageFloodgateType: manageFloodgateTypeReducer,
        manageFloodgateNode: manageFloodgateNode,
        manageFloodgateNodeTypeDisplay: manageFloodgateNodeTypeDisplayReducer,
        floodgateRelation: floodgateRelationReducer,
        manageFloodgateImage: manageFloodgateImageReducer,
        monitorDetail: monitorDetailReducer,
        controlFloodgate: controlFloodgateReducer,
        transationMonitor: transationMonitorReducer,
        transationReport: transationReportSelector,
        transationReport1: transationReportSelector1,
        monitorLogsReport: monitorLogsReportSelector,
        monitorLogsReport1: monitorLogsReportSelector1,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(loggerMiddleware),
    devTools: process.env.NODE_ENV !== 'production',
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispath = typeof store.dispatch;

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useAppDispatch = () => useDispatch<AppDispath>();

export default store;

import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';

import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import BarChart from "@mui/icons-material/BarChart";
import Person from "@mui/icons-material/Person";
import FloodIcon from '@mui/icons-material/Flood';

import { NavLink } from 'react-router-dom';
import { Stack, useMediaQuery } from '@mui/material';
import { eUserRole } from '../../../services/Constants/enum/enum.model';
import { UserRole } from '../../../models/userRole/UserRole.model';

const drawerWidth = 240;

const DrawerHeader = styled('div')(({ theme }) => ({
  height: 235,
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  justifyContent: 'flex-end',
}));

type MenuProp = {
  userRole: UserRole[] | UserRole | null;
  open: boolean;
  onDrawerClose: () => void;
  setOpen: (open: boolean) => void;
};

export default function Menu({ open, setOpen, onDrawerClose, userRole }: MenuProp) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleDrawerClose = () => {
    onDrawerClose();
  };

  const handleClose = () => {
    if (isMobile) {
      setOpen(false)
    }
  };

  const MyNavLink = React.forwardRef<any, any>((props, ref) => (
    <NavLink
      ref={ref}
      to={props.to}
      onClick={handleClose}
      className={({ isActive }) =>
        `${props.className} ${isActive ? props.activeClassName : ""}`
      }
    >
      {props.children}
    </NavLink>
  ));

  // ฟังก์ชันเช็คว่า userRole มีสิทธิ์ใน role ที่กำหนดหรือไม่
  const hasRole = (roles: eUserRole[]): boolean => {
    if (!userRole) return false;
    if (Array.isArray(userRole)) {
      return userRole.some((role) => roles.includes(role.role_account_id as eUserRole));
    }
    return roles.includes(userRole.role_account_id as eUserRole);
  };

  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
        },
      }}
      variant="persistent"
      anchor="left"
      open={open}
    >
      <DrawerHeader>
        <Stack direction="row" alignItems="center">
          <img
            src={`${process.env.PUBLIC_URL}/images/logo.webp`}
            style={{ height: 200 }}
          />
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </Stack>
      </DrawerHeader>
      <Divider />
      <List>
        {hasRole([eUserRole.Admin, eUserRole.Management, eUserRole.Operator]) && (
          <ListItemButton
            to="/floodgate/report"
            component={MyNavLink}
            activeClassName="Mui-selected"
            exact
          >
            <ListItemIcon>
              <BarChart />
            </ListItemIcon>
            <ListItemText primary="โครงการทั้งหมด" />
          </ListItemButton>
        )}

        {hasRole([eUserRole.Admin, eUserRole.Management, eUserRole.Operator]) && (
          <ListItemButton
            to="/floodgate/monitor"
            component={MyNavLink}
            activeClassName="Mui-selected"
            exact
          >
            <ListItemIcon>
              <AnalyticsIcon />
            </ListItemIcon>
            <ListItemText primary="Monitor And Control" />
          </ListItemButton>
        )}

        {hasRole([eUserRole.Admin]) && (
          <ListItemButton
            to="/UserInfo"
            component={MyNavLink}
            activeClassName="Mui-selected"
            exact
          >
            <ListItemIcon>
              <Person />
            </ListItemIcon>
            <ListItemText primary="ข้อมูลผู้ใช้งาน" />
          </ListItemButton>
        )}

        {hasRole([eUserRole.Admin]) && (
          <ListItemButton
            to="/ManageFloodGate"
            component={MyNavLink}
            activeClassName="Mui-selected"
            exact
          >
            <ListItemIcon>
              <FloodIcon />
            </ListItemIcon>
            <ListItemText primary="ข้อมูลโครงการ" />
          </ListItemButton>
        )}
      </List>
    </Drawer>
  );
}

import React from "react";
import LineChartComponent from "../../layouts/Charts/LineChartComponent";
import FloodGateListMonitor from "./FloodGateListMonitor";
import { useParams } from "react-router-dom";

const MonitorPage: React.FC = () => {
  return (
    <div>
      <FloodGateListMonitor />
    </div>
  );
};

export default MonitorPage;

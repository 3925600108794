import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { TOKEN } from "../../services/Constants/systems";
import config from "../../config";
import { RootState } from "../store";
import { UserRole } from "../../models/userRole/UserRole.model";
import { httpClient } from "../../services/Connection/httpclient";

// Interface สำหรับ State
interface UserRolesState {
    data: UserRole[] | UserRole | null; // รองรับทั้ง array และ object
    dataAll: UserRole[] | null; // สำหรับเก็บข้อมูลกรณี getAllStatus = true
    isLoading: boolean;
    isError: boolean;
    errorMessage: string | null;
}

// Initial state
const initialState: UserRolesState = {
    data: null,
    dataAll: null,
    isLoading: false,
    isError: false,
    errorMessage: null,
};

// Async thunk สำหรับการเรียก API
export const fetchUserRoles = createAsyncThunk<
    UserRole[] | UserRole,
    boolean,  // getAllStatus ที่เป็น boolean
    { rejectValue: string }
>("userRoles/fetchUserRoles", async (getAllStatus, { rejectWithValue }) => {
    try {
        const token = localStorage.getItem(TOKEN);
        if (token) {
            const response = await httpClient.post(
                config.user_info.user_url + config.user_info.user_roles,
                null,
                {
                    headers: {
                        'authorization': token,
                        'getallstatus': getAllStatus.toString(), // แปลงค่าเป็น string
                    },
                }
            );

            if (response.data.status) {
                return response.data.data.response; // คืนค่าข้อมูลที่ได้จาก API
            } else {
                return rejectWithValue(response.data.message);
            }
        } else {
            return rejectWithValue("Token is missing");
        }
    } catch (error: any) {
        return rejectWithValue(
            error.message || "เกิดข้อผิดพลาด หรือ session หมดอายุ กรุณาเข้าสู่ระบบใหม่อีกครั้ง"
        );
    }
});

// สร้าง Slice
const userRolesSlice = createSlice({
    name: "userRoles",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchUserRoles.pending, (state) => {
                state.isLoading = true;
                state.isError = false;
                state.errorMessage = null;
            })
            .addCase(
                fetchUserRoles.fulfilled,
                (state, action: PayloadAction<UserRole[] | UserRole, string, { arg: boolean }>) => {
                    state.isLoading = false;

                    // แยกการทำงานตามค่า getAllStatus จาก action.meta.arg
                    if (action.meta.arg) {
                        // ถ้า getAllStatus = true ให้เก็บข้อมูลใน dataAll
                        state.dataAll = action.payload as UserRole[];
                    } else {
                        // ถ้า getAllStatus = false ให้เก็บข้อมูลใน data
                        state.data = action.payload as UserRole;
                    }
                }
            )
            .addCase(
                fetchUserRoles.rejected,
                (state, action: PayloadAction<string | undefined>) => {
                    state.isLoading = false;
                    state.isError = true;
                    state.errorMessage = action.payload || "เกิดข้อผิดพลาด หรือ session หมดอายุ กรุณาเข้าสู่ระบบใหม่อีกครั้ง";
                }
            );
    },
});

// Selector สำหรับดึงข้อมูลจาก state
export const userRolesSelector = (store: RootState) => store.userRoles;
export default userRolesSlice.reducer;

import React, { useEffect, useState } from 'react';
import { Box, Button, Modal, Fade, Backdrop, Typography, TextField, Grid } from '@mui/material';
import { useAppDispatch } from '../../../stores/store';
import { manageFloodgateSchema } from '../../../stores/slices/ManageFloodgateSchemaSlice';
import { UpdateFloodgateSchemaRequest } from '../../../models/manageFloodgateGroupSchema/ManageFloodgateSchemaRequest.model';
import { floodgateManageAction } from '../../../services/Constants/enum/enum.model';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

interface ModalProps {
    open: boolean;
    onClose: () => void;
    floodgate: {
        floodgate_schema_type_id: string;
        floodgate_schema_type_name: string;
    } | null; // ข้อมูล floodgate ที่จะแก้ไข
}

const ManageFloodGateSchemaEditModal: React.FC<ModalProps> = ({ open, onClose, floodgate }) => {
    const dispatch = useAppDispatch();

    // กำหนดค่าเริ่มต้นของข้อมูลที่จะแก้ไข
    const [editData, setEditData] = useState<UpdateFloodgateSchemaRequest>({
        action: floodgateManageAction.UPDATE,
        floodgate_schema_type_id: '',
        floodgate_schema_type_name: '',
    });

    const [errors, setErrors] = useState<{ [key: string]: string }>({});

    // เมื่อ modal เปิด ให้ตั้งค่าเริ่มต้นของข้อมูลที่จะแก้ไข
    useEffect(() => {
        if (floodgate && open) {
            setEditData({
                action: floodgateManageAction.UPDATE,
                floodgate_schema_type_id: floodgate.floodgate_schema_type_id,
                floodgate_schema_type_name: floodgate.floodgate_schema_type_name,
            });
        }
    }, [floodgate, open]);

    const resetForm = () => {
        setEditData({
            action: floodgateManageAction.UPDATE,
            floodgate_schema_type_id: '',
            floodgate_schema_type_name: '',
        });
        setErrors({});
    };

    const validate = () => {
        let tempErrors: { [key: string]: string } = {};

        // Validate floodgate_schema_type_name
        if (!editData.floodgate_schema_type_name) {
            tempErrors.floodgate_schema_type_name = 'กรุณาใส่ชื่อกลุ่มโครงการ';
        } else if (!/^[ก-๙a-zA-Z0-9\s]+$/.test(editData.floodgate_schema_type_name)) {
            tempErrors.floodgate_schema_type_name = 'ชื่อกลุ่มโครงการต้องเป็นภาษาไทย ภาษาอังกฤษ หรือตัวเลขเท่านั้น';
        }

        setErrors(tempErrors);
        return Object.keys(tempErrors).length === 0;
    };

    const handleChange = (field: keyof UpdateFloodgateSchemaRequest) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setEditData({ ...editData, [field]: event.target.value });
        setErrors((prev) => ({ ...prev, [field]: '' }));
    };

    const handleSave = async () => {
        if (validate()) {
            try {
                await dispatch(manageFloodgateSchema(editData)).unwrap();
                toast.success('แก้ไขข้อมูลสำเร็จ');
                resetForm();
                onClose();
            } catch (error) {
                toast.error('แก้ไขข้อมูลไม่สำเร็จ');
            }
        }
    };

    const handleClose = () => {
        resetForm();
        onClose();
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}
            closeAfterTransition
        >
            <Fade in={open}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '30%',
                        maxHeight: '80vh',
                        bgcolor: 'background.paper',
                        borderRadius: 2,
                        boxShadow: 24,
                        p: 4,
                        overflowY: 'auto',
                    }}
                >
                    <Typography variant="h6" component="h2" gutterBottom>
                        แก้ไขข้อมูลกลุ่มโครงการ
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="ชื่อกลุ่มโครงการ"
                                value={editData.floodgate_schema_type_name}
                                onChange={handleChange('floodgate_schema_type_name')}
                                required
                                error={!!errors.floodgate_schema_type_name}
                                helperText={errors.floodgate_schema_type_name}
                            />
                        </Grid>
                    </Grid>

                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                        <Button onClick={handleClose} sx={{ mr: 2 }}>
                            ยกเลิก
                        </Button>
                        <Button variant="contained" onClick={handleSave}>
                            บันทึก
                        </Button>
                    </Box>
                </Box>
            </Fade>
        </Modal>
    );
};

export default ManageFloodGateSchemaEditModal;
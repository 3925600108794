import { DisplayGroup, DisplayGroupSettingNode } from "../../../../models/monitor/MonitorDetail.model"
import { Box, Card, CardContent, Grid, Typography } from "@mui/material";

interface StatusLightItemProps {
    display_group_item: DisplayGroup;
    index: number;
    socketData: { [key: string]: { [key: string]: string } };
    floodgateId: string;
}

const handleArr = (setting: DisplayGroupSettingNode, data: string) => {
    if (data != "0") {
        if (setting.setting_node_data_type == "1") {
            // 1 = Array
            const dataArray = data.split(",");
            const index = setting.setting_node_sequence;
            if (index >= 0 && index < dataArray.length) {
                const inputValue = parseFloat(dataArray[index]);
                if (!isNaN(inputValue)) {
                    return inputValue.toFixed(2);
                }
                return dataArray[index];
            } else {
                return "0";
            }
        } else {
            return data;
        }
    }
    return "0";
};

export default function StatusLightItem({ display_group_item, index, socketData, floodgateId }: StatusLightItemProps) {
    const light_color = "#888888";
    const CircleComponent = ({ color = "black", headColor = "yellow" }) => {
        return (
            <Box
                sx={{
                    width: 20,
                    height: 20,
                    backgroundColor: `${headColor}`,
                    border: `1px solid ${color}`,
                    borderRadius: "50%",
                    flexShrink: 0,
                }}
            />
        );
    };

    return (
        <Grid item xs={12} sm={6} md={6} lg={6} sx={{
            borderRadius: "0.6rem",
            padding: 1,
        }}>
            <Card className="shadow-lg rounded-lg" key={`control-${index}`} sx={{
                textAlign: "center",
                borderRadius: "20px",
                backgroundColor: "rgba(255, 255, 255, 1)",
                boxShadow: "none",
            }}>
                <CardContent>
                    <Box display="flex" flexWrap="wrap" alignItems="center" justifyContent="flex-start">

                        {/************* content */}
                        <Grid item
                            sx={{
                                backgroundColor: "#FFF",
                                borderRadius: "0.6rem",
                                padding: 2,
                            }}>
                            <Typography variant="h6" component="div">{display_group_item.setting_nodes.setting_node_data_name}</Typography>
                            <Box display="flex" flexWrap="wrap" alignItems="center" justifyContent="flex-start">
                                <Box display="flex" alignItems="center" justifyContent="flex-start" marginRight={2} marginBottom={2}>
                                    <CircleComponent color="black" headColor={
                                        (display_group_item.setting_nodes.setting_node_data_type == "1" || display_group_item.setting_nodes.setting_node_data_type != "0") ?
                                            (parseFloat(display_group_item.status_light_value) ===
                                                parseFloat(handleArr(
                                                    display_group_item.setting_nodes!,
                                                    socketData[floodgateId]?.[
                                                    display_group_item.setting_nodes?.setting_node_data_key || ""
                                                    ] || "0"))
                                                ? display_group_item.status_light_color
                                                : light_color)
                                            : (display_group_item.status_light_value ===
                                                handleArr(
                                                    display_group_item.setting_nodes!,
                                                    socketData[floodgateId]?.[
                                                    display_group_item.setting_nodes?.setting_node_data_key || ""
                                                    ] || "0")
                                                ? display_group_item.status_light_color
                                                : light_color)
                                    } />
                                    <Typography variant="body1" marginLeft={1}>
                                        {display_group_item.status_light_name}
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>
                        {/************* content */}

                    </Box>
                </CardContent>
            </Card>
        </Grid>
    );
}
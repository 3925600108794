import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import UserTable from './UserTable';
import UserModal from './UserCreateModal';
import { HandleTransparentAccordion } from '../../layouts/HandleStyleComponents/HandleTransparentAccordion'; // Import the styled component
import { Typography } from '@mui/material';

export default function UserInfoPage() {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Box sx={{ width: '100%', p: 2 }}>
      <HandleTransparentAccordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6" fontWeight="bold" fontSize={18}>
            จัดการข้อมูลผู้ใช้งาน
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={handleOpen} sx={{ marginBottom: 2 }}>
            เพิ่มข้อมูลผู้ใช้งาน
          </Button>
          <Box sx={{ height: 500, width: '100%' }}>
            <UserTable />
          </Box>
        </AccordionDetails>
      </HandleTransparentAccordion>

      <UserModal open={open} onClose={handleClose} />
    </Box>
  );
}
import { useNavigate } from 'react-router-dom';

import {
  useAppDispatch
} from "../../../stores/store";
import { userLogout } from "../../../stores/slices/LoginSlice";

import { TOKEN } from '../../../services/Constants/systems';

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import MailIcon from '@mui/icons-material/Mail';
import { Badge } from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import AccountCircle from "@mui/icons-material/AccountCircle";

const drawerWidth = 240;
interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

type HeaderProp = {
  open: boolean;
  onDrawerOpen: () => void;
};

export default function PublicHeader() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleLoginPage = () => {
    localStorage.removeItem(TOKEN);
    dispatch(userLogout());
    navigate("/login");
  };

  const handleMainPage = () => {
    localStorage.removeItem(TOKEN);
    dispatch(userLogout());
    navigate("/");
  };

  return (
    <AppBar position="fixed">
      <Toolbar>
        <Typography padding={0.7} noWrap component="div" onClick={handleMainPage}>
          <img
            src={`${process.env.PUBLIC_URL}/images/logo.webp`}
            style={{ height: 80 }}
          />
        </Typography>

        <Typography variant="h6" padding={0.7} noWrap component="div" onClick={handleMainPage} sx={{ display: { xs: "none",sm: "flex", md: "flex" } }}>
          ระบบแสดงผลและควบคุมประตูระบายน้ำ<br />
          (Flood Gates Monitoring and Control)
        </Typography>

        <Box sx={{ flexGrow: 1 }} /> {/*sx คือ style*/}
        <Box sx={{ display: { md: "flex" } }}>
          <IconButton
            size="large"
            edge="end"
            aria-label="account of current user"
            aria-haspopup="true"
            onClick={handleLoginPage}
            color="inherit"
          >
            <AccountCircle />
            <Typography padding={0.7} noWrap component="div" sx={{ 'textAlign': 'left' }}>
              เข้าสู่ระบบ
              <Typography fontSize={8} noWrap component="div">เพื่อแสดงผลและการควบคุม</Typography>
            </Typography>
          </IconButton>
        </Box>
      </Toolbar>
    </AppBar>
  );
}
import React, { useState } from 'react';
import { Modal, Box, Button, Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, useAppDispatch, useAppSelector } from '../../../stores/store';
import { manageFloodgateImage, manageFloodgateImageSelector } from '../../../stores/slices/ManageFloodgateImageSlice';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';



interface ImageUploadModalProps {
    open: boolean;
    onClose: () => void;
    floodgateId: string; // รับ floodgateId เป็น props เพื่อส่งไปพร้อมกับรูปภาพ
}

const ManageFloodGateImageUploadModal: React.FC<ImageUploadModalProps> = ({ open, onClose, floodgateId }) => {
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [previewImage, setPreviewImage] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);

  const dispatch = useAppDispatch();
  const { isLoading, isError, errorMessage } = useSelector((state: RootState) => state.manageFloodgateImage);
  const {status} = useAppSelector(manageFloodgateImageSelector);

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setSelectedImage(file);
      setPreviewImage(URL.createObjectURL(file));
      setError(null);
    } else {
      setError('Please select an image');
    }
  };

  const handleUpload = async () => {
    if (!selectedImage) {
      setError('No image selected');
      return;
    }

    // dispatch action manageFloodgateImage พร้อมกับข้อมูลไฟล์และ floodgateId
    
    try {
        dispatch(
            manageFloodgateImage({
              imgfloodgate: selectedImage,
              floodgateid: floodgateId,
            })
          );
        if(status){
            toast.success('อัพโหลดรูปสำเร็จ');
        }
    } catch (error:any) {
        toast.error('อัพโหลดรูปไม่สำเร็จ');
    }



  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={modalStyles}>
        <IconButton
          sx={{ position: 'absolute', top: 10, right: 10 }}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
        <Typography variant="h6" mb={2}>
          อัพโหลดรูป
        </Typography>
        <input type="file" accept="image/*" onChange={handleImageChange} />
        {previewImage && (
          <Box mt={2} mb={2}>
            <img
              src={previewImage}
              alt="Preview"
              style={{ width: '100%', maxHeight: '200px' }}
            />
          </Box>
        )}
        {error && (
          <Typography color="error" variant="body2">
            {error}
          </Typography>
        )}
        {isError && (
          <Typography color="error" variant="body2">
            {errorMessage}
          </Typography>
        )}
        <Button
          variant="contained"
          color="primary"
          onClick={handleUpload}
          disabled={isLoading}
          sx={{ mt: 2 }}
        >
          {isLoading ? 'อัพโหลดรูป...' : 'อัพโหลดรูป'}
        </Button>
      </Box>
    </Modal>
  );
};

const modalStyles = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
};

export default ManageFloodGateImageUploadModal;

import React, { useEffect, useState } from 'react';
import { Box, Button, Modal, Fade, Backdrop, Typography, TextField, Grid, MenuItem } from '@mui/material';
import { useAppDispatch } from '../../../stores/store';
import { manageUserInfo } from '../../../stores/slices/ManageUserInfoSlice';
import { ManageUserInfoRequest } from '../../../models/userInfo/ManageUserInfo.model';
import { fetchUserRoles } from '../../../stores/slices/UserRolesSlice';
import { fetchUserInfo } from '../../../stores/slices/UserInfoSlice'; // เพิ่มการ import
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

interface UserModalProps {
    open: boolean;
    onClose: () => void;
}

const UserModal: React.FC<UserModalProps> = ({ open, onClose }) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [newUser, setNewUser] = useState<ManageUserInfoRequest>({
        action: 'register',
        user_prefix: '',
        account_username: '',
        account_password: '',
        user_first_name_th: '',
        user_last_name_th: '',
        user_first_name_en: '',
        user_last_name_en: '',
        user_phone_number: '',
        user_age: '',
        user_email: '',
        user_addr_house_num: '',
        user_addr_floor: '',
        user_addr_village: '',
        user_addr_village_num: '',
        user_addr_alley: '',
        user_addr_soi: '',
        user_addr_road: '',
        user_addr_subdistrict: '',
        user_addr_district: '',
        user_addr_province: '',
        user_addr_postcode: '',
    });

    const [userRoles, setUserRoles] = useState<{ role_account_id: string; role_account_name: string }[]>([]);
    const [errors, setErrors] = useState<{ [key: string]: string }>({});

    const resetForm = () => {
        setNewUser({
            action: 'register',
            user_prefix: '',
            account_username: '',
            account_password: '',
            user_first_name_th: '',
            user_last_name_th: '',
            user_first_name_en: '',
            user_last_name_en: '',
            user_phone_number: '',
            user_age: '',
            user_email: '',
            user_addr_house_num: '',
            user_addr_floor: '',
            user_addr_village: '',
            user_addr_village_num: '',
            user_addr_alley: '',
            user_addr_soi: '',
            user_addr_road: '',
            user_addr_subdistrict: '',
            user_addr_district: '',
            user_addr_province: '',
            user_addr_postcode: '',
        });
        setErrors({});
    };

    const validate = () => {
        let tempErrors: { [key: string]: string } = {};

        if (!newUser.role_account_id) tempErrors.role_account_id = 'กรุณาเลือกกลุ่มผู้ใช้งาน';
        if (!newUser.account_username) tempErrors.account_username = 'กรุณาใส่ username';
        if (!newUser.account_password) tempErrors.account_password = 'กรุณาใส่ password';
        if (!newUser.user_prefix) tempErrors.user_prefix = 'กรุณาเลือกคำนำหน้า';
        if (!newUser.user_first_name_th || !/^[ก-๙]+$/.test(newUser.user_first_name_th)) tempErrors.user_first_name_th = 'กรุณาใส่ชื่อภาษาไทยที่ถูกต้อง';
        if (!newUser.user_last_name_th || !/^[ก-๙]+$/.test(newUser.user_last_name_th)) tempErrors.user_last_name_th = 'กรุณาใส่นามสกุลภาษาไทยที่ถูกต้อง';
        if (!newUser.user_first_name_en || !/^[a-zA-Z]+$/.test(newUser.user_first_name_en)) tempErrors.user_first_name_en = 'กรุณาใส่ชื่อภาษาอังกฤษที่ถูกต้อง';
        if (!newUser.user_last_name_en || !/^[a-zA-Z]+$/.test(newUser.user_last_name_en)) tempErrors.user_last_name_en = 'กรุณาใส่นามสกุลภาษาอังกฤษที่ถูกต้อง';
        if (!newUser.user_phone_number || !/^\d{10}$/.test(newUser.user_phone_number)) tempErrors.user_phone_number = 'กรุณาใส่เบอร์โทรศัพท์ที่ถูกต้อง';
        if (!newUser.user_age || isNaN(Number(newUser.user_age))) tempErrors.user_age = 'กรุณาใส่อายุที่ถูกต้อง';
        if (!newUser.user_email || !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(newUser.user_email)) tempErrors.user_email = 'กรุณาใส่อีเมลล์ที่ถูกต้อง';

        setErrors(tempErrors);
        return Object.keys(tempErrors).length === 0;
    };

    const handleChange = (field: keyof ManageUserInfoRequest) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setNewUser({ ...newUser, [field]: event.target.value });
        setErrors((prev) => ({ ...prev, [field]: '' })); // เคลียร์ error message เมื่อพิมพ์ข้อมูลใหม่
    };

    const handleSave = async () => {
        if (validate()) {
            try {
                await dispatch(manageUserInfo(newUser)).unwrap();
                toast.success('บันทึกข้อมูลสำเร็จ');
                await dispatch(fetchUserInfo(true)); // รีโหลดข้อมูลในตารางใหม่
                resetForm(); // รีเซ็ตฟอร์ม
                onClose(); // ปิด modal
            } catch (error) {
                toast.error('บันทึกข้อมูลไม่สำเร็จข้อมูลอาจซ้ำในระบบ');
            }
        }
    };

    useEffect(() => {
        const fetchRoles = async () => {
            const roles = await dispatch(fetchUserRoles(true)).unwrap();
            setUserRoles(Array.isArray(roles) ? roles : [roles]);
        };
        fetchRoles();
    }, [dispatch]);

    const handleClose = () => {
        resetForm(); // รีเซ็ตฟอร์มเมื่อปิด modal
        onClose();
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '80%',
                        maxHeight: '80vh',
                        bgcolor: 'background.paper',
                        borderRadius: 2,
                        boxShadow: 24,
                        p: 4,
                        overflowY: 'auto',
                    }}
                >
                    <Typography variant="h6" component="h2" gutterBottom>
                        เพิ่มข้อมูลผู้ใช้งาน
                    </Typography>
                    <Grid container spacing={2}>

                        {/* Username */}
                        <Grid item xs={12} sm={3}>
                            <TextField
                                fullWidth
                                label="username"
                                value={newUser.account_username}
                                onChange={handleChange('account_username')}
                                required
                                error={!!errors.account_username}
                                helperText={errors.account_username}
                            />
                        </Grid>

                        {/* Password */}
                        <Grid item xs={12} sm={3}>
                            <TextField
                                fullWidth
                                label="password"
                                type="password"
                                value={newUser.account_password}
                                onChange={handleChange('account_password')}
                                required
                                error={!!errors.account_password}
                                helperText={errors.account_password}
                            />
                        </Grid>

                        {/* Role Account */}
                        <Grid item xs={12} sm={6}>
                            <TextField
                                select
                                fullWidth
                                label="กลุ่มผู้ใช้งาน"
                                value={newUser.role_account_id}
                                onChange={handleChange('role_account_id')}
                                required
                                error={!!errors.role_account_id}
                                helperText={errors.role_account_id}
                            >
                                {userRoles.map((role) => (
                                    <MenuItem key={role.role_account_id} value={role.role_account_id}>
                                        {role.role_account_name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>

                        {/* User Prefix */}
                        <Grid item xs={12} sm={2}>
                            <TextField
                                select
                                fullWidth
                                label="คำนำหน้า"
                                value={newUser.user_prefix}
                                onChange={handleChange('user_prefix')}
                                required
                                error={!!errors.user_prefix}
                                helperText={errors.user_prefix}
                            >
                                <MenuItem value="นาย">นาย</MenuItem>
                                <MenuItem value="นาง">นาง</MenuItem>
                                <MenuItem value="นางสาว">นางสาว</MenuItem>
                            </TextField>
                        </Grid>

                        {/* Other fields */}
                        <Grid item xs={12} sm={5}>
                            <TextField
                                fullWidth
                                label="ชื่อภาษาไทย"
                                value={newUser.user_first_name_th}
                                onChange={handleChange('user_first_name_th')}
                                required
                                error={!!errors.user_first_name_th}
                                helperText={errors.user_first_name_th}
                            />
                        </Grid>

                        <Grid item xs={12} sm={5}>
                            <TextField
                                fullWidth
                                label="นามสกุลภาษาไทย"
                                value={newUser.user_last_name_th}
                                onChange={handleChange('user_last_name_th')}
                                required
                                error={!!errors.user_last_name_th}
                                helperText={errors.user_last_name_th}
                            />
                        </Grid>

                        <Grid item xs={12} sm={5}>
                            <TextField
                                fullWidth
                                label="ชื่อภาษาอังกฤษ"
                                value={newUser.user_first_name_en}
                                onChange={handleChange('user_first_name_en')}
                                required
                                error={!!errors.user_first_name_en}
                                helperText={errors.user_first_name_en}
                            />
                        </Grid>

                        <Grid item xs={12} sm={5}>
                            <TextField
                                fullWidth
                                label="นามสกุลภาษาอังกฤษ"
                                value={newUser.user_last_name_en}
                                onChange={handleChange('user_last_name_en')}
                                required
                                error={!!errors.user_last_name_en}
                                helperText={errors.user_last_name_en}
                            />
                        </Grid>

                        <Grid item xs={12} sm={2}>
                            <TextField
                                fullWidth
                                label="อายุ"
                                value={newUser.user_age}
                                onChange={handleChange('user_age')}
                                required
                                error={!!errors.user_age}
                                helperText={errors.user_age}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label="เบอร์โทรศัพท์"
                                value={newUser.user_phone_number}
                                onChange={handleChange('user_phone_number')}
                                required
                                error={!!errors.user_phone_number}
                                helperText={errors.user_phone_number}
                            />
                        </Grid>



                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label="อีเมลล์"
                                value={newUser.user_email}
                                onChange={handleChange('user_email')}
                                required
                                error={!!errors.user_email}
                                helperText={errors.user_email}
                            />
                        </Grid>

                        {/* Address fields */}
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label="บ้านเลขที่"
                                value={newUser.user_addr_house_num}
                                onChange={handleChange('user_addr_house_num')}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label="ชั้น"
                                value={newUser.user_addr_floor}
                                onChange={handleChange('user_addr_floor')}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label="หมู่บ้าน"
                                value={newUser.user_addr_village}
                                onChange={handleChange('user_addr_village')}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label="บ้านเลขที่"
                                value={newUser.user_addr_village_num}
                                onChange={handleChange('user_addr_village_num')}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label="ตรอก"
                                value={newUser.user_addr_alley}
                                onChange={handleChange('user_addr_alley')}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label="ซอย"
                                value={newUser.user_addr_soi}
                                onChange={handleChange('user_addr_soi')}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label="ถนน"
                                value={newUser.user_addr_road}
                                onChange={handleChange('user_addr_road')}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label="ตำบล"
                                value={newUser.user_addr_subdistrict}
                                onChange={handleChange('user_addr_subdistrict')}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label="เขต/อำเภอ"
                                value={newUser.user_addr_district}
                                onChange={handleChange('user_addr_district')}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label="จังหวัด"
                                value={newUser.user_addr_province}
                                onChange={handleChange('user_addr_province')}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label="ไปรษณีย์"
                                value={newUser.user_addr_postcode}
                                onChange={handleChange('user_addr_postcode')}
                            />
                        </Grid>
                    </Grid>

                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                        <Button onClick={handleClose} sx={{ mr: 2 }}>
                            ยกเลิก
                        </Button>
                        <Button variant="contained" onClick={handleSave}>
                            บันทึก
                        </Button>
                    </Box>
                </Box>
            </Fade>
        </Modal>
    );
};

export default UserModal;

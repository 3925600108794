// Modal สำหรับเพิ่มข้อมูล
import React, { useEffect, useState } from 'react';
import { Box, Button, Modal, Fade, Backdrop, Typography, TextField, Grid, MenuItem } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../stores/store';
import { manageFloodgateInfo } from '../../../stores/slices/ManageFloodgateInfoSlice';
import { manageFloodgateSchema, manageFloodgateSchemaSelector } from '../../../stores/slices/ManageFloodgateSchemaSlice';
import { AddFloodgateRequest } from '../../../models/manageFloodgateInfo/ManageFloodgateInfoReq.model';
import { floodgateAction, floodgateManageAction } from '../../../services/Constants/enum/enum.model';
import { fetchFloodGateInfo } from '../../../stores/slices/FloodGateInfoSlice'; // Import action เพื่อ reload ข้อมูล
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

interface ModalProps {
    open: boolean;
    onClose: () => void;
}

const ManageFloodGateCreateModal: React.FC<ModalProps> = ({ open, onClose }) => {
    const dispatch = useAppDispatch();
    const schemaTypes = useAppSelector(manageFloodgateSchemaSelector).data || [];

    const [newFloodgate, setNewFloodgate] = useState<AddFloodgateRequest>({
        action: floodgateManageAction.ADD,
        floodgate_name: '',
        floodgate_attachment_file: 'NA',
        floodgate_ip: '',
        floodgate_port: '',
        floodgate_username: '',
        floodgate_password: '',
        floodgate_schema_type_id: '',
        floodgate_ns: '0',
        floodgate_s: '',
        floodgate_obj: '',
        floodgate_project: '',
        floodgate_map: '',
    });

    const [errors, setErrors] = useState<{ [key: string]: string }>({});

    useEffect(() => {
        if (open) {
            dispatch(manageFloodgateSchema({ action: floodgateManageAction.GET }));
        }
    }, [dispatch, open]);

    const resetForm = () => {
        setNewFloodgate({
            action: floodgateManageAction.ADD,
            floodgate_name: '',
            floodgate_attachment_file: 'NA',
            floodgate_ip: '',
            floodgate_port: '',
            floodgate_username: '',
            floodgate_password: '',
            floodgate_schema_type_id: '',
            floodgate_ns: '0',
            floodgate_s: '',
            floodgate_obj: '',
            floodgate_project: '',
            floodgate_map: '',
        });
        setErrors({});
    };

    const validate = () => {
        let tempErrors: { [key: string]: string } = {};

        // Validate floodgate_name (Thai, English, or numbers only)
        if (!newFloodgate.floodgate_name) {
            tempErrors.floodgate_name = 'กรุณาใส่ชื่อโครงการ';
        } else if (!/^[ก-๙a-zA-Z0-9\s]+$/.test(newFloodgate.floodgate_name)) {
            tempErrors.floodgate_name = 'ชื่อโครงการต้องเป็นภาษาไทย ภาษาอังกฤษ หรือตัวเลขเท่านั้น';
        }

        // Validate floodgate_ip (must be a valid IP address)
        if (!newFloodgate.floodgate_ip) {
            tempErrors.floodgate_ip = 'กรุณาใส่ IP Address';
        } else if (!/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(newFloodgate.floodgate_ip)) {
            tempErrors.floodgate_ip = 'กรุณาใส่ IP Address ที่ถูกต้อง';
        }

        // Validate floodgate_port (must be a valid port number)
        if (!newFloodgate.floodgate_port) {
            tempErrors.floodgate_port = 'กรุณาใส่ Port';
        } else if (!/^\d+$/.test(newFloodgate.floodgate_port)) {
            tempErrors.floodgate_port = 'Port ต้องเป็นตัวเลขเท่านั้น';
        } else if (parseInt(newFloodgate.floodgate_port, 10) < 1 || parseInt(newFloodgate.floodgate_port, 10) > 65535) {
            tempErrors.floodgate_port = 'Port ต้องอยู่ระหว่าง 1 ถึง 65535';
        }

        // Validate floodgate_schema_type_id (required)
        if (!newFloodgate.floodgate_schema_type_id) {
            tempErrors.floodgate_schema_type_id = 'กรุณาเลือก Schema Type';
        }

        // Validate floodgate_ns (required)
        if (!newFloodgate.floodgate_ns) {
            tempErrors.floodgate_ns = 'กรุณาใส่ Namespace';
        }

        // Validate floodgate_s (required)
        if (!newFloodgate.floodgate_s) {
            tempErrors.floodgate_s = 'กรุณาใส่ Root';
        }

        // Validate floodgate_obj (required)
        if (!newFloodgate.floodgate_obj) {
            tempErrors.floodgate_obj = 'กรุณาใส่ Objects';
        }

        // Validate floodgate_project (required)
        if (!newFloodgate.floodgate_project) {
            tempErrors.floodgate_project = 'กรุณาใส่ Project';
        }

        setErrors(tempErrors);
        return Object.keys(tempErrors).length === 0;
    };

    const handleChange = (field: keyof AddFloodgateRequest) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setNewFloodgate({ ...newFloodgate, [field]: event.target.value });
        setErrors((prev) => ({ ...prev, [field]: '' }));
    };

    const handleSave = async () => {
        if (validate()) {
            // ตรวจสอบว่า username และ password มีค่าหรือไม่ ถ้าไม่มีให้ตั้งค่าเป็น 'NA'
            if (!newFloodgate.floodgate_username) {
                newFloodgate.floodgate_username = 'NA';
            }
            if (!newFloodgate.floodgate_password) {
                newFloodgate.floodgate_password = 'NA';
            }

            try {
                await dispatch(manageFloodgateInfo(newFloodgate)).unwrap();
                toast.success('บันทึกข้อมูลสำเร็จ');

                // รีโหลดข้อมูลใหม่หลังจากที่บันทึกสำเร็จ
                dispatch(fetchFloodGateInfo(floodgateAction.getAllByAdmin));

                resetForm();
                onClose();
            } catch (error) {
                toast.error('บันทึกข้อมูลไม่สำเร็จ');
            }
        }
    };

    const handleClose = () => {
        resetForm();
        onClose();
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}
            closeAfterTransition
        >
            <Fade in={open}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '60%',
                        maxHeight: '80vh',
                        bgcolor: 'background.paper',
                        borderRadius: 2,
                        boxShadow: 24,
                        p: 4,
                        overflowY: 'auto',
                    }}
                >
                    <Typography variant="h6" component="h2" gutterBottom>
                        เพิ่มข้อมูลโครงการ
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label="ชื่อโครงการ"
                                value={newFloodgate.floodgate_name}
                                onChange={handleChange('floodgate_name')}
                                required
                                error={!!errors.floodgate_name}
                                helperText={errors.floodgate_name}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                select
                                fullWidth
                                label="กลุ่มของโครงการ"
                                value={newFloodgate.floodgate_schema_type_id}
                                onChange={handleChange('floodgate_schema_type_id')}
                                required
                                error={!!errors.floodgate_schema_type_id}
                                helperText={errors.floodgate_schema_type_id}
                            >
                                {Array.isArray(schemaTypes) && schemaTypes.map((type: { floodgate_schema_type_id: string, floodgate_schema_type_name: string }) => (
                                    <MenuItem key={type.floodgate_schema_type_id} value={type.floodgate_schema_type_id}>
                                        {type.floodgate_schema_type_name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label="IP Address"
                                value={newFloodgate.floodgate_ip}
                                onChange={handleChange('floodgate_ip')}
                                required
                                error={!!errors.floodgate_ip}
                                helperText={errors.floodgate_ip}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label="Port"
                                value={newFloodgate.floodgate_port}
                                onChange={handleChange('floodgate_port')}
                                required
                                error={!!errors.floodgate_port}
                                helperText={errors.floodgate_port}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label="Username"
                                value={newFloodgate.floodgate_username}
                                onChange={handleChange('floodgate_username')}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label="Password"
                                value={newFloodgate.floodgate_password}
                                onChange={handleChange('floodgate_password')}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label="Namespace"
                                value={newFloodgate.floodgate_ns}
                                onChange={handleChange('floodgate_ns')}
                                required
                                type="number"
                                error={!!errors.floodgate_ns}
                                helperText={errors.floodgate_ns}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label="Root"
                                value={newFloodgate.floodgate_s}
                                onChange={handleChange('floodgate_s')}
                                required
                                error={!!errors.floodgate_s}
                                helperText={errors.floodgate_s}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label="Objects"
                                value={newFloodgate.floodgate_obj}
                                onChange={handleChange('floodgate_obj')}
                                required
                                error={!!errors.floodgate_obj}
                                helperText={errors.floodgate_obj}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label="Project"
                                value={newFloodgate.floodgate_project}
                                onChange={handleChange('floodgate_project')}
                                required
                                error={!!errors.floodgate_project}
                                helperText={errors.floodgate_project}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                fullWidth
                                label="Map Script"
                                value={newFloodgate.floodgate_map}
                                onChange={handleChange('floodgate_map')}
                            />
                        </Grid>
                    </Grid>

                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                        <Button onClick={handleClose} sx={{ mr: 2 }}>
                            ยกเลิก
                        </Button>
                        <Button variant="contained" onClick={handleSave}>
                            บันทึก
                        </Button>
                    </Box>
                </Box>
            </Fade>
        </Modal>
    );
};

export default ManageFloodGateCreateModal;
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import config from '../../config';
import { ManageFloodgateNodeTypeDisplayRequest } from "../../models/manageFloodgateNodeTypeDisplay/ManageFloodgateNodeTypeDisplayRequest.model";
import { ManageFloodgateNodeTypeDisplayResponse } from "../../models/manageFloodgateNodeTypeDisplay/ManageFloodgateNodeTypeDisplayResponse.model";
import { httpClient } from '../../services/Connection/httpclient';
import { floodgateManageAction } from '../../services/Constants/enum/enum.model';
import { TOKEN } from '../../services/Constants/systems';

// State Interface
interface FloodGateNodeDisplayState {
    dataByAdd: ManageFloodgateNodeTypeDisplayResponse['data']['response'] | null;
    dataByUpdate: ManageFloodgateNodeTypeDisplayResponse['data']['response'] | null;
    dataByDelete: ManageFloodgateNodeTypeDisplayResponse['data']['response'] | null;
    dataByGet: ManageFloodgateNodeTypeDisplayResponse['data']['response'][] | null;
    dataByGetById: ManageFloodgateNodeTypeDisplayResponse['data']['response'] | null;
    isLoadingByAdd: boolean;
    isLoadingByUpdate: boolean;
    isLoadingByDelete: boolean;
    isLoadingByGet: boolean;
    isLoadingByGetById: boolean;
    isErrorByAdd: boolean;
    isErrorByUpdate: boolean;
    isErrorByDelete: boolean;
    isErrorByGet: boolean;
    isErrorByGetById: boolean;
    errorMessageByAdd: string | null;
    errorMessageByUpdate: string | null;
    errorMessageByDelete: string | null;
    errorMessageByGet: string | null;
    errorMessageByGetById: string | null;
}

// Initial State
const initialState: FloodGateNodeDisplayState = {
    dataByAdd: null,
    dataByUpdate: null,
    dataByDelete: null,
    dataByGet: null,
    dataByGetById: null,
    isLoadingByAdd: false,
    isLoadingByUpdate: false,
    isLoadingByDelete: false,
    isLoadingByGet: false,
    isLoadingByGetById: false,
    isErrorByAdd: false,
    isErrorByUpdate: false,
    isErrorByDelete: false,
    isErrorByGet: false,
    isErrorByGetById: false,
    errorMessageByAdd: null,
    errorMessageByUpdate: null,
    errorMessageByDelete: null,
    errorMessageByGet: null,
    errorMessageByGetById: null,
};

// Async Thunk for API Calls
export const manageFloodGateNodeDisplay = createAsyncThunk<
    ManageFloodgateNodeTypeDisplayResponse['data']['response'],
    { action: floodgateManageAction; body?: ManageFloodgateNodeTypeDisplayRequest },
    { rejectValue: string }
>(
    'floodGateNodeDisplay/manageFloodGateNodeDisplay',
    async ({ action, body }, { rejectWithValue }) => {
        try {
            const token = localStorage.getItem(TOKEN);
            if (!token) return rejectWithValue("Token is missing");

            let actionHeader = '';
            switch (action) {
                case floodgateManageAction.ADD:
                    actionHeader = 'ADD';
                    break;
                case floodgateManageAction.UPDATE:
                    actionHeader = 'UPDATE';
                    break;
                case floodgateManageAction.DELETE:
                    actionHeader = 'DELETE';
                    break;
                case floodgateManageAction.GET:
                    actionHeader = 'GET';
                    break;
                case floodgateManageAction.GETBYID:
                    actionHeader = 'GETBYID';
                    break;
                default:
                    return rejectWithValue("Invalid action");
            }

            let headers = {
                'authorization': token,
                'Content-Type': 'application/json',
                'action': actionHeader,
            };

            let response;
            switch (action) {
                case floodgateManageAction.ADD:
                case floodgateManageAction.UPDATE:
                case floodgateManageAction.DELETE:
                case floodgateManageAction.GETBYID:
                    response = await httpClient.post(
                        config.floodgate.floodgate_url + config.floodgate.floodgate_manage_node_type_display,
                        body,
                        { headers }
                    );
                    break;
                case floodgateManageAction.GET:
                    response = await httpClient.post(
                        config.floodgate.floodgate_url + config.floodgate.floodgate_manage_node_type_display,
                        {},
                        { headers }
                    );
                    break;
                default:
                    return rejectWithValue("Invalid action");
            }

            if (response.data.status) {
                return response.data.data.response;
            } else {
                return rejectWithValue(response.data.message);
            }
        } catch (error: any) {
            return rejectWithValue(error.response?.data?.message || "Error occurred, please try again.");
        }
    }
);

// Slice Definition
const floodGateNodeDisplaySlice = createSlice({
    name: 'floodGateNodeDisplay',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            // ADD case
            .addCase(manageFloodGateNodeDisplay.pending, (state, action) => {
                switch (action.meta.arg.action) {
                    case floodgateManageAction.ADD:
                        state.isLoadingByAdd = true;
                        state.isErrorByAdd = false;
                        state.errorMessageByAdd = null;
                        break;
                    case floodgateManageAction.UPDATE:
                        state.isLoadingByUpdate = true;
                        state.isErrorByUpdate = false;
                        state.errorMessageByUpdate = null;
                        break;
                    case floodgateManageAction.DELETE:
                        state.isLoadingByDelete = true;
                        state.isErrorByDelete = false;
                        state.errorMessageByDelete = null;
                        break;
                    case floodgateManageAction.GET:
                        state.isLoadingByGet = true;
                        state.isErrorByGet = false;
                        state.errorMessageByGet = null;
                        break;
                    case floodgateManageAction.GETBYID:
                        state.isLoadingByGetById = true;
                        state.isErrorByGetById = false;
                        state.errorMessageByGetById = null;
                        break;
                }
            })
            // Fulfilled case
            .addCase(manageFloodGateNodeDisplay.fulfilled, (state, action) => {
                switch (action.meta.arg.action) {
                    case floodgateManageAction.ADD:
                        state.isLoadingByAdd = false;
                        state.dataByAdd = action.payload;
                        break;
                    case floodgateManageAction.UPDATE:
                        state.isLoadingByUpdate = false;
                        state.dataByUpdate = action.payload;
                        break;
                    case floodgateManageAction.DELETE:
                        state.isLoadingByDelete = false;
                        state.dataByDelete = action.payload;
                        break;
                    case floodgateManageAction.GET:
                        state.isLoadingByGet = false;
                        state.dataByGet = Array.isArray(action.payload) ? action.payload : [action.payload];
                        break;
                    case floodgateManageAction.GETBYID:
                        state.isLoadingByGetById = false;
                        state.dataByGetById = action.payload;
                        break;
                }
            })
            // Rejected case
            .addCase(manageFloodGateNodeDisplay.rejected, (state, action) => {
                switch (action.meta.arg.action) {
                    case floodgateManageAction.ADD:
                        state.isLoadingByAdd = false;
                        state.isErrorByAdd = true;
                        state.errorMessageByAdd = action.payload || "เกิดข้อผิดพลาด กรุณาลองใหม่";
                        break;
                    case floodgateManageAction.UPDATE:
                        state.isLoadingByUpdate = false;
                        state.isErrorByUpdate = true;
                        state.errorMessageByUpdate = action.payload || "เกิดข้อผิดพลาด กรุณาลองใหม่";
                        break;
                    case floodgateManageAction.DELETE:
                        state.isLoadingByDelete = false;
                        state.isErrorByDelete = true;
                        state.errorMessageByDelete = action.payload || "เกิดข้อผิดพลาด กรุณาลองใหม่";
                        break;
                    case floodgateManageAction.GET:
                        state.isLoadingByGet = false;
                        state.isErrorByGet = true;
                        state.errorMessageByGet = action.payload || "เกิดข้อผิดพลาด กรุณาลองใหม่";
                        break;
                    case floodgateManageAction.GETBYID:
                        state.isLoadingByGetById = false;
                        state.isErrorByGetById = true;
                        state.errorMessageByGetById = action.payload || "เกิดข้อผิดพลาด กรุณาลองใหม่";
                        break;
                }
            });
    },
});

export const floodGateNodeDisplaySelector = (store: RootState) => store.manageFloodgateNodeTypeDisplay;
export default floodGateNodeDisplaySlice.reducer;

import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { TOKEN } from "../../services/Constants/systems";
import { httpClient } from "../../services/Connection/httpclient";
import config from "../../config";

// Interface สำหรับ State
interface MonitorLogsReportState {
    data: Array<Record<string, {
        account_id: string;
        account_name: string;
        action: string;
        value: string;
        log_create: string;
    }>> | null;
    isLoading: boolean;
    isError: boolean;
    errorMessage: string | null;
}

const initialState: MonitorLogsReportState = {
    data: null,
    isLoading: false,
    isError: false,
    errorMessage: null,
};

export const fetchMonitorLogsReport = createAsyncThunk<
    Array<Record<string, {
        account_id: string;
        account_name: string;
        action: string;
        value: string;
        log_create: string;
    }>>,
    {
        floodgate_id: string,
        number_of_rows: string,
        page: string,
        startdate: string,
        enddate: string,
    },
    { rejectValue: string }
>(
    "monitorLogsReport/fetchMonitorLogsReport",
    async ({ floodgate_id, number_of_rows, page, startdate, enddate }, { rejectWithValue }) => {
        try {
            const token = localStorage.getItem(TOKEN);
            if (!token) {
                return rejectWithValue("Token is missing");
            }

            const req = {
                floodgateId: floodgate_id,
                numberofrows: number_of_rows,
                page: page,
                log_startdate: startdate,
                log_enddate: enddate,
            };

            const response = await httpClient.post(
                `${config.control.floodgate_control_url}/action/log`, req,
                {
                    headers: {
                        'authorization': token,
                        'Content-Type': 'application/json',
                    },
                }
            );

            if (response.data.status) {
                return response.data.data;
            } else {
                return rejectWithValue(response.data.message || "ไม่สามารถดึงข้อมูลได้");
            }

        } catch (error: any) {
            return rejectWithValue(error.message || "เกิดข้อผิดพลาด");
        }
    }
);

// สร้าง Slice
const monitorLogsReportSlice = createSlice({
    name: "monitorLogsReport",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchMonitorLogsReport.pending, (state) => {
                state.isLoading = true;
                state.isError = false;
                state.errorMessage = null;
            })
            .addCase(
                fetchMonitorLogsReport.fulfilled,
                (state, action: PayloadAction<Array<Record<string, {
                    account_id: string;
                    account_name: string;
                    action: string;
                    value: string;
                    log_create: string;
                }>>>) => {
                    state.isLoading = false;
                    state.data = action.payload;
                }
            )
            .addCase(
                fetchMonitorLogsReport.rejected,
                (state, action: PayloadAction<string | undefined>) => {
                    state.isLoading = false;
                    state.isError = true;
                    state.errorMessage = action.payload || "เกิดข้อผิดพลาด";
                }
            );
    },
});

// Selector สำหรับดึงข้อมูล monitor logs report จาก store
export const monitorLogsReportSelector = (store: RootState) => store.monitorLogsReport;

// Export reducer
export default monitorLogsReportSlice.reducer;
import React, { useState } from 'react';
import { Box, Modal, Fade, Backdrop, Typography, Button, Checkbox } from '@mui/material';
import { DataGrid, GridColDef, GridRowId, GridRowSelectionModel, GridToolbarContainer, GridToolbarContainerProps } from '@mui/x-data-grid';
import { useAppDispatch } from '../../../stores/store';
import { manageFloodGateNodeDisplay } from '../../../stores/slices/ManageFloodgateNodeTypeDisplaySlice'; // ใช้ action DELETE
import { floodgateManageAction } from '../../../services/Constants/enum/enum.model'; // Action DELETE
import { NodetypeInputAndDisplay } from '../../../models/monitor/MonitorDetailResponse.model';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import BuildIcon from '@mui/icons-material/Build';
import Swal from 'sweetalert2';
import ManageProgramTemplateDisplayUpdateModal from './ManageProgramTemplateDisplayUpdateModal';
interface ModalProps {
    open: boolean;
    nodetypeInputAndDisplay: NodetypeInputAndDisplay[];
    nodetypeInputAndDisplayShowDroupDown: NodetypeInputAndDisplay[];
    onClose: () => void;
    onClose1: () => void;
}

const ManageProgramTemplateDisplayModal: React.FC<ModalProps> = ({ open, nodetypeInputAndDisplay, nodetypeInputAndDisplayShowDroupDown, onClose, onClose1 }) => {
    const dispatch = useAppDispatch();
    const [selectedRows, setSelectedRows] = useState<GridRowId[]>([]); // เก็บแถวที่ถูกเลือก
    const [addDisplayModalOpen, setAddDisplayModalOpen] = useState(false);
    const [dataDisplay, setDataDisplay] = useState<NodetypeInputAndDisplay>();

    const handleRowSelection = (rowSelectionModel: GridRowSelectionModel) => {
        setSelectedRows([...rowSelectionModel]);
    };

    const handleDeleteClick = async () => {
        const selectedNodes = nodetypeInputAndDisplay.filter((row) => selectedRows.includes(row.type_input_and_display_id));
        if (selectedNodes.length > 0) {
            onClose();
            onClose1();

            const result = await Swal.fire({
                title: 'คุณแน่ใจหรือไม่?',
                text: 'คุณต้องการลบข้อมูลนี้หรือไม่?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'ใช่, ลบเลย!',
                cancelButtonText: 'ยกเลิก',
            });

            if (result.isConfirmed) {
                try {
                    const deletePromises = selectedNodes.map(async (node) => {
                        await dispatch(manageFloodGateNodeDisplay({
                            action: floodgateManageAction.DELETE,
                            body: {
                                setting_node_id: node.setting_node_id,
                                type_input_and_display_id: node.type_input_and_display_id,
                                is_control: node.is_control,
                                is_btn: node.is_btn,
                                btn_value: node.btn_value,
                                btn_name: node.btn_name,
                                btn_color: node.btn_color,
                                is_text_input: node.is_text_input,
                                text_input_name: node.text_input_name,
                                is_monitor: node.is_monitor,
                                is_status_light: node.is_status_light,
                                status_light_value: node.status_light_value,
                                status_light_name: node.status_light_name,
                                status_light_color: node.status_light_color,
                                is_graph: node.is_graph,
                                graph_color: node.graph_color,
                                is_img: node.is_img,
                                img_group_name: node.img_group_name,
                                display_group: node.display_group,
                            }
                        })).unwrap();
                    });
                    await Promise.all(deletePromises);
                    Swal.fire('ลบข้อมูลสำเร็จ!', '', 'success');
                    setSelectedRows([]);
                } catch (error) {
                    Swal.fire('เกิดข้อผิดพลาด!', 'ไม่สามารถลบข้อมูลได้', 'error');
                }
            } else {
                onClose();
            }
        }
    };

    const handleOpenUpDisplayClick = async () => {
        const selectedNodes = nodetypeInputAndDisplay.filter((row) => selectedRows.includes(row.type_input_and_display_id));

        if (selectedNodes.length > 0) {
            const updatePromises = selectedNodes.map(async (node) => {
                setDataDisplay({
                    setting_node_id: node.setting_node_id,
                    type_input_and_display_id: node.type_input_and_display_id,
                    is_control: node.is_control,
                    is_btn: node.is_btn,
                    btn_value: node.btn_value,
                    btn_name: node.btn_name,
                    btn_color: node.btn_color,
                    is_text_input: node.is_text_input,
                    text_input_name: node.text_input_name,
                    is_monitor: node.is_monitor,
                    is_status_light: node.is_status_light,
                    status_light_value: node.status_light_value,
                    status_light_name: node.status_light_name,
                    status_light_color: node.status_light_color,
                    is_graph: node.is_graph,
                    graph_color: node.graph_color,
                    is_img: node.is_img,
                    img_group_name: node.img_group_name,
                    display_group: node.display_group,
                });
            });
            await Promise.all(updatePromises);

            setAddDisplayModalOpen(true);
        }
    };

    const columns: GridColDef[] = [
        { field: 'is_control', headerName: 'ควบคุมระบบ', width: 150, renderCell: (params) => <Checkbox checked={params.value} disabled /> },
        { field: 'is_btn', headerName: 'มีปุ่มควบคุม', width: 150, renderCell: (params) => <Checkbox checked={params.value} disabled /> },
        { field: 'btn_name', headerName: 'ชื่อปุ่ม', width: 150 },
        { field: 'btn_value', headerName: 'ค่าที่จะส่ง', width: 150 },
        { field: 'btn_color', headerName: 'สีปุ่ม', width: 100 },
        { field: 'is_text_input', headerName: 'ป้อนข้อมูล', width: 150, renderCell: (params) => <Checkbox checked={params.value} disabled /> },
        { field: 'text_input_name', headerName: 'ชื่อ Text Input', width: 150 },
        { field: 'is_status_light', headerName: 'มีไฟสถานะ', width: 100, renderCell: (params) => <Checkbox checked={params.value} disabled /> },
        { field: 'status_light_value', headerName: 'ค่าไฟสถานะ', width: 150 },
        { field: 'status_light_name', headerName: 'ชื่อไฟสถานะ', width: 150 },
        { field: 'status_light_color', headerName: 'สีไฟสถานะ', width: 150 },
        { field: 'is_graph', headerName: 'แสดงกราฟ', width: 100, renderCell: (params) => <Checkbox checked={params.value} disabled /> },
        { field: 'graph_color', headerName: 'สีกราฟ', width: 100 },
        { field: 'is_img', headerName: 'แสดงรูปภาพ', width: 100, renderCell: (params) => <Checkbox checked={params.value} disabled /> },
        { field: 'img_group_name', headerName: 'ชื่อกลุ่มรูปภาพ', width: 150 },
        { field: 'display_group', headerName: 'กลุ่มที่จะแสดง', width: 150 },
    ];

    const CustomToolbar = (props: GridToolbarContainerProps) => (
        <GridToolbarContainer {...props}>
            <Button startIcon={<DeleteIcon />} onClick={handleDeleteClick} disabled={selectedRows.length === 0 || selectedRows.length > 1} sx={{ mr: 2 }}>
                ลบ
            </Button>
            <Button startIcon={<BuildIcon />} onClick={handleOpenUpDisplayClick} disabled={selectedRows.length === 0 || selectedRows.length > 1} sx={{ mr: 2 }}>
                แก้ไขกลุ่มการแสดงผล
            </Button>
        </GridToolbarContainer>
    );

    return (
        <>
            <Modal open={open} onClose={onClose} closeAfterTransition BackdropComponent={Backdrop} BackdropProps={{ timeout: 500 }}>
                <Fade in={open}>
                    <Box
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: '80%',
                            maxHeight: '80vh',
                            bgcolor: 'background.paper',
                            borderRadius: 2,
                            boxShadow: 24,
                            p: 4,
                            overflowY: 'auto',
                        }}
                    >
                        <Typography variant="h6" gutterBottom>จัดการข้อมูลการแสดงผล</Typography>

                        <DataGrid
                            rows={nodetypeInputAndDisplay}
                            columns={columns}
                            checkboxSelection
                            onRowSelectionModelChange={handleRowSelection}
                            getRowId={(row) => row.type_input_and_display_id}
                            autoHeight
                            slots={{ toolbar: CustomToolbar }}
                        />
                    </Box>
                </Fade>
            </Modal>

            {
                addDisplayModalOpen && dataDisplay && (
                    <ManageProgramTemplateDisplayUpdateModal
                        open={addDisplayModalOpen}
                        onClose={() => setAddDisplayModalOpen(false)}
                        dataDisplay={dataDisplay}
                        nodetypeInputAndDisplay={nodetypeInputAndDisplay}
                        nodetypeInputAndDisplayShowDroupDown={nodetypeInputAndDisplayShowDroupDown}
                    />
                )
            }
        </>
    );
};

export default ManageProgramTemplateDisplayModal;
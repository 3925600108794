import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../store";
import config from "../../config";
import { FloodgateRelation } from "../../models/floodgateRelation/FloodgateRelation.model";
import { TOKEN } from "../../services/Constants/systems";
import { httpClient } from "../../services/Connection/httpclient";
import { floodgateManageAction } from '../../services/Constants/enum/enum.model';

// Interface สำหรับ State แยกตาม CRUD
interface FloodgateRelationState {
    dataForAdd: FloodgateRelation[] | null;
    dataForUpdate: FloodgateRelation[] | null;
    dataForDelete: FloodgateRelation[] | null;
    dataForFetch: FloodgateRelation[] | null;
    isFetching: boolean;
    isAdding: boolean;
    isUpdating: boolean;
    isDeleting: boolean;
    isError: boolean;
    errorMessage: string | null;
}

// Initial state
const initialState: FloodgateRelationState = {
    dataForAdd: null,
    dataForUpdate: null,
    dataForDelete: null,
    dataForFetch: null,
    isFetching: false,
    isAdding: false,
    isUpdating: false,
    isDeleting: false,
    isError: false,
    errorMessage: null,
};

// Interface สำหรับการส่งข้อมูลไปยัง API
interface ManageFloodgateRelationRequest {
    action: floodgateManageAction;
    account_id?: string;
    floodgate_id?: string;
    floodgate_relation_id?: string;
}

// Async thunk สำหรับการจัดการ floodgate relation
export const manageFloodgateRelation = createAsyncThunk<
    FloodgateRelation[], // ผลลัพธ์ที่ต้องการจาก API
    ManageFloodgateRelationRequest, // พารามิเตอร์สำหรับการเรียกใช้ thunk
    { rejectValue: string } // ค่าที่คืนถ้าการร้องขอล้มเหลว
>(
    "floodgateRelation/manageFloodgateRelation",
    async (requestData, { rejectWithValue }) => {
        try {
            const token = localStorage.getItem(TOKEN);
            if (!token) {
                return rejectWithValue("Token is missing");
            }

            let actionHeader = '';
            switch (requestData.action) {
                case floodgateManageAction.ADD:
                    actionHeader = 'ADD';
                    break;
                case floodgateManageAction.UPDATE:
                    actionHeader = 'UPDATE';
                    break;
                case floodgateManageAction.DELETE:
                    actionHeader = 'DELETE';
                    break;
                case floodgateManageAction.GET:
                    actionHeader = 'GET';
                    break;
                default:
                    return rejectWithValue("Invalid action");
            }

            // ลบ action ออกจาก body ก่อนทำการส่ง API
            const { action, ...body } = requestData;
            const response = await httpClient.post(
                config.floodgate.floodgate_url + config.floodgate.floodgate_manage_relation,
                requestData.action === floodgateManageAction.GET ? {} : body, // ส่ง body เฉพาะในกรณีที่ไม่ใช่ GET
                {
                    headers: {
                        'authorization': token,
                        'action': actionHeader,
                        'Content-Type': 'application/json',
                    },
                }
            );

            if (response.data.status) {
                return response.data.data.response; // คืนค่าข้อมูลจาก API
            } else {
                return rejectWithValue(response.data.message);
            }
        } catch (error: any) {
            return rejectWithValue(error.message || "เกิดข้อผิดพลาด หรือ session หมดอายุ กรุณาเข้าสู่ระบบใหม่อีกครั้ง");
        }
    }
);

// สร้าง Slice สำหรับจัดการ state ของ floodgate relations
const floodgateRelationSlice = createSlice({
    name: "floodgateRelation",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(manageFloodgateRelation.pending, (state, action) => {
                // แยก state สำหรับการดำเนินการ CRUD
                switch (action.meta.arg.action) {
                    case floodgateManageAction.ADD:
                        state.isAdding = true;
                        break;
                    case floodgateManageAction.UPDATE:
                        state.isUpdating = true;
                        break;
                    case floodgateManageAction.DELETE:
                        state.isDeleting = true;
                        break;
                    case floodgateManageAction.GET:
                        state.isFetching = true;
                        break;
                }
                state.isError = false;
                state.errorMessage = null;
            })
            .addCase(
                manageFloodgateRelation.fulfilled,
                (state, action: any) => {
                    // แยกจัดเก็บข้อมูลตาม CRUD
                    switch (action.meta.arg.action) {
                        case floodgateManageAction.ADD:
                            state.isAdding = false;
                            state.dataForAdd = action.payload;
                            break;
                        case floodgateManageAction.UPDATE:
                            state.isUpdating = false;
                            state.dataForUpdate = action.payload;
                            break;
                        case floodgateManageAction.DELETE:
                            state.isDeleting = false;
                            state.dataForDelete = action.payload;
                            break;
                        case floodgateManageAction.GET:
                            state.isFetching = false;
                            state.dataForFetch = action.payload;
                            break;
                    }
                }
            )
            .addCase(
                manageFloodgateRelation.rejected,
                (state, action: any) => {
                    state.isFetching = false;
                    state.isAdding = false;
                    state.isUpdating = false;
                    state.isDeleting = false;
                    state.isError = true;
                    state.errorMessage = action.payload || "เกิดข้อผิดพลาด หรือ session หมดอายุ กรุณาเข้าสู่ระบบใหม่อีกครั้ง";
                }
            );
    },
});

// Selectors
export const floodgateRelationSelector = (store: RootState) => store.floodgateRelation;

// Export reducer สำหรับใช้ใน store
export default floodgateRelationSlice.reducer;

import React, { useEffect, useState } from 'react';
import { Box, Modal, Fade, Backdrop, Typography, CircularProgress, Button, Checkbox } from '@mui/material';
import { DataGrid, GridColDef, GridRowId, GridRowSelectionModel, GridToolbarContainer, GridToolbarContainerProps } from '@mui/x-data-grid';
import { useAppDispatch, useAppSelector } from '../../../stores/store';
import { fetchFloodgateDetail, floodgateDetailSelector } from '../../../stores/slices/FloodgateDetailSlice';
import { FloodgateDetailResponse, SettingNode } from '../../../models/floodgateDetail/FloodgateDetail.model';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import AddIcon from '@mui/icons-material/Add';
import ScreenShareIcon from '@mui/icons-material/ScreenShare';
import AddToQueueOutlinedIcon from '@mui/icons-material/AddToQueueOutlined';
import Swal from 'sweetalert2';
import { floodgateDataTypes } from '../../../services/Constants/enum/enum.model';
import { manageFloodgateNode } from '../../../stores/slices/ManageFloodgateNodeSlice';
import { floodgateManageAction } from '../../../services/Constants/enum/enum.model';
import ManageProgramTemplateAddEditModal from './ManageProgramTemplateAddEditModal';
import ManageProgramTemplateDisplayAddModal from './ManageProgramTemplateDisplayAddModal';
import { fetchMonitorDetail, monitorDetailSelector } from '../../../stores/slices/MonitorDetailSlice';
import { MonitorDetailRequest } from '../../../models/monitor/MonitorDetailRequest.model';
import { NodetypeInputAndDisplay } from '../../../models/monitor/MonitorDetailResponse.model';
import ManageProgramTemplateDisplayModal from './ManageProgramTemplateDisplayModal';

interface ModalProps {
    open: boolean;
    floodgateId: string;
    onClose: () => void;
}

const ManageProgramTemplateDataModal: React.FC<ModalProps> = ({ open, floodgateId, onClose }) => {
    const dispatch = useAppDispatch();
    const { data, isLoading, isError, errorMessage } = useAppSelector(floodgateDetailSelector);
    const { data: monitorDetailData } = useAppSelector(monitorDetailSelector);
    const [rows, setRows] = useState<SettingNode[]>([]);
    const [selectedRows, setSelectedRows] = useState<GridRowId[]>([]);
    const [addEditModalOpen, setAddEditModalOpen] = useState(false);
    const [addDisplayModalOpen, setAddDisplayModalOpen] = useState(false);
    const [dataSelect, setDataSelect] = useState<SettingNode | null>(null);
    const [dataSelectId, setDataSelectId] = useState<string>();
    const [dataSatatusAdd, setDataSatatusAdd] = useState<string>();
    const [nodetypeInputAndDisplay, setNodetypeInputAndDisplay] = useState<NodetypeInputAndDisplay[]>([]);
    const [nodetypeInputAndDisplayShowDroupDown, setNodetypeInputAndDisplayShowDroupDown] = useState<NodetypeInputAndDisplay[]>([]);
    const [displayModalOpen, setDisplayModalOpen] = useState(false);

    useEffect(() => {
        if (open && floodgateId) {
            dispatch(fetchFloodgateDetail(floodgateId));

            const requestData: MonitorDetailRequest = {
                floodgate_id: floodgateId,
            };
            dispatch(fetchMonitorDetail(requestData));
        }


    }, [dispatch, open, floodgateId]);

    useEffect(() => {
        if (data?.setting_nodes) {
            setRows(data.setting_nodes);
        }
    }, [data]);

    const handleRowSelection = (rowSelectionModel: GridRowSelectionModel) => {
        if (rowSelectionModel.length == 1) {
            setSelectedRows([...rowSelectionModel]);
        } else {
            setSelectedRows([]);
        }
    };

    const handleEditClick = async () => {
        const selectedNode = rows.find((row) => selectedRows.includes(row.setting_node_id));
        if (selectedNode) {
            setDataSatatusAdd('edit');
            setDataSelect(selectedNode);
            setAddEditModalOpen(true); // เปิด modal หลังจากตั้งค่า selectedNode แล้ว
        }
    };

    const handleAddDisplayClick = async () => {
        const selectedNode = rows.find((row) => selectedRows.includes(row.setting_node_id));
        if (selectedNode) {
            setDataSelectId(selectedNode.setting_node_id);
            setAddDisplayModalOpen(true); // เปิด modal หลังจากตั้งค่า selectedNode แล้ว
        }
    };

    const handleDisplayClick = async () => {
        const selectedNode = rows.find((row) => selectedRows.includes(row.setting_node_id));
        if (monitorDetailData && selectedNode) {
            const filteredNodes = monitorDetailData.response.setting_nodes.filter(
                (floodgate) => floodgate.setting_node_id === selectedNode.setting_node_id
            );
            const allNodetypeInputAndDisplay = filteredNodes.flatMap((node) => node.nodetype_input_and_display);
            setNodetypeInputAndDisplay(allNodetypeInputAndDisplay);
            setNodetypeInputAndDisplayShowDroupDown(monitorDetailData.response.setting_nodes.flatMap((node) => node.nodetype_input_and_display));
            setDisplayModalOpen(true);
        }
    };

    const handleDeleteClick = async () => {
        const selectedNodes = rows.filter((row) => selectedRows.includes(row.setting_node_id));

        if (selectedNodes.length > 0) {
            // ปิด modal ก่อนที่จะแสดง Swal
            onClose();

            const result = await Swal.fire({
                title: 'คุณแน่ใจหรือไม่?',
                text: 'คุณต้องการลบข้อมูลนี้หรือไม่?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'ใช่, ลบเลย!',
                cancelButtonText: 'ยกเลิก',
            });

            if (result.isConfirmed) {
                try {
                    const deletePromises = selectedNodes.map(node =>
                        dispatch(manageFloodgateNode({
                            action: floodgateManageAction.DELETE,
                            setting_node_id: String(node.setting_node_id),
                            setting_type_id: String(node.setting_type_id),
                            floodgate_id: String(node.floodgate_id),
                            setting_node_data_display_main: node.setting_node_data_display_main,
                            setting_node_data_key: node.setting_node_data_key,
                            setting_node_data_name: node.setting_node_data_name,
                            setting_node_data_min: String(node.setting_node_data_min),
                            setting_node_data_max: String(node.setting_node_data_max),
                            setting_node_data_unit: node.setting_node_data_unit,
                            setting_node_type_communication: node.setting_node_type_communication,
                            setting_node_data_type: String(node.setting_node_data_type),
                            setting_node_sequence: String(node.setting_node_sequence),
                            setting_node_discription: node.setting_node_discription
                        })
                        ));

                    await Promise.all(deletePromises);

                    Swal.fire('ลบข้อมูลสำเร็จ!', '', 'success');

                    // รีโหลดข้อมูลใหม่
                    dispatch(fetchFloodgateDetail(floodgateId));
                    setSelectedRows([]);
                } catch (error) {
                    Swal.fire('เกิดข้อผิดพลาด!', 'ไม่สามารถลบข้อมูลได้', 'error');
                }
            }
        }
    };

    const handleAddClick = () => {
        setDataSatatusAdd('add');
        setDataSelect(null);
        setAddEditModalOpen(true);
    };

    const columns: GridColDef[] = [
        { field: 'setting_node_data_name', headerName: 'ชื่อการแสดงผลและเซ็นเซอร์', width: 200 },
        { field: 'setting_node_data_key', headerName: 'ค่า variable', width: 150 },
        { field: 'setting_node_data_min', headerName: 'ค่าต่ำสุด', width: 100 },
        { field: 'setting_node_data_max', headerName: 'ค่าสูงสุด', width: 100 },
        { field: 'setting_node_data_unit', headerName: 'หน่วยของข้อมูลที่ใช้แสดง', width: 150 },
        {
            field: 'setting_node_type_communication',
            headerName: 'T :: Request To OPC-UA(Monitor) / F :: Display',
            width: 150,
            renderCell: (params) => (
                <Checkbox checked={params.value} disabled />
            ),
        },
        {
            field: 'setting_node_data_display_main',
            headerName: 'โชว์ข้อมูลหน้าหลัก',
            width: 150,
            renderCell: (params) => (
                <Checkbox checked={params.value} disabled />
            ),
        },
        {
            field: 'setting_node_data_type',
            headerName: 'ชนิดข้อมูล',
            width: 150,
            renderCell: (params) => {
                switch (params.value) {
                    case floodgateDataTypes.int:
                        return 'Integer';
                    case floodgateDataTypes.arrayint:
                        return 'Array of Integers';
                    case floodgateDataTypes.string:
                        return 'String';
                    case floodgateDataTypes.boolean:
                        return 'Boolean';
                    default:
                        return 'Unknown';
                }
            },
        },
        { field: 'setting_node_sequence', headerName: 'ลำดับข้อมูล', width: 100 },
        { field: 'setting_node_discription', headerName: 'รายละเอียด', width: 200 },
    ];

    const CustomToolbar = (props: GridToolbarContainerProps) => (
        <GridToolbarContainer {...props}>
            <Button startIcon={<AddIcon />} onClick={handleAddClick} sx={{ mr: 2 }}>
                เพิ่มข้อมูล
            </Button>
            <Button startIcon={<EditIcon />} onClick={handleEditClick} disabled={selectedRows.length !== 1} sx={{ mr: 2 }}>
                แก้ไข
            </Button>
            <Button startIcon={<DeleteIcon />} onClick={handleDeleteClick} disabled={selectedRows.length !== 1} sx={{ mr: 2 }}>
                ลบ
            </Button>
            <Button startIcon={<AddToQueueOutlinedIcon />} onClick={handleAddDisplayClick} disabled={selectedRows.length !== 1} sx={{ mr: 2 }}>
                เพิ่มการแสดงผล
            </Button>
            <Button startIcon={<ScreenShareIcon />} onClick={handleDisplayClick} disabled={selectedRows.length !== 1} sx={{ mr: 2 }}>
                ข้อมูลการแสดงผล
            </Button>
        </GridToolbarContainer>
    );

    if (isLoading) return <CircularProgress />;
    if (isError) return <Typography>Error: {errorMessage}</Typography>;

    return (
        <>
            <Modal open={open} onClose={onClose} closeAfterTransition BackdropComponent={Backdrop} BackdropProps={{ timeout: 500 }}>
                <Fade in={open}>
                    <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '80%', maxHeight: '80vh', bgcolor: 'background.paper', borderRadius: 2, boxShadow: 24, p: 4, overflowY: 'auto' }}>
                        <Typography variant="h6" gutterBottom>จัดการข้อมูลการแสดงผลและเซ็นเซอร์ : {data?.floodgate_name}</Typography>

                        <DataGrid
                            rows={rows}
                            columns={columns}
                            checkboxSelection
                            onRowSelectionModelChange={handleRowSelection}
                            getRowId={(row) => row.setting_node_id}
                            autoHeight
                            slots={{ toolbar: CustomToolbar }}
                        />
                    </Box>
                </Fade>
            </Modal>

            {/* ตรวจสอบเฉพาะการเพิ่มข้อมูล */}
            {addEditModalOpen && dataSatatusAdd === "add" && (
                <ManageProgramTemplateAddEditModal
                    open={addEditModalOpen}
                    onClose={() => setAddEditModalOpen(false)}
                    floodgateId={floodgateId}
                    editData={null}
                />
            )}

            {/* ตรวจสอบเฉพาะการแก้ไขข้อมูล */}
            {addEditModalOpen && dataSatatusAdd === "edit" && dataSelect && (
                <ManageProgramTemplateAddEditModal
                    open={addEditModalOpen}
                    onClose={() => setAddEditModalOpen(false)}
                    floodgateId={floodgateId}
                    editData={dataSelect}
                />
            )}

            {addDisplayModalOpen && dataSelectId && (
                <ManageProgramTemplateDisplayAddModal
                    open={addDisplayModalOpen}
                    onClose={() => setAddDisplayModalOpen(false)}
                    settingNodeId={dataSelectId}
                    nodetypeInputAndDisplay={nodetypeInputAndDisplay}
                    nodetypeInputAndDisplayShowDroupDown={nodetypeInputAndDisplayShowDroupDown}
                />
            )}

            {displayModalOpen && nodetypeInputAndDisplay && (
                <ManageProgramTemplateDisplayModal
                    open={displayModalOpen}
                    onClose={() => setDisplayModalOpen(false)}
                    onClose1={onClose}
                    nodetypeInputAndDisplay={nodetypeInputAndDisplay}
                    nodetypeInputAndDisplayShowDroupDown={nodetypeInputAndDisplayShowDroupDown}
                />
            )}
        </>
    );

};

export default ManageProgramTemplateDataModal;
import { useState } from "react";
import { DisplayGroup } from "../../../../models/monitor/MonitorDetail.model"
import { Box, Button, Card, CardContent, Grid, TextField, Typography } from "@mui/material";
import Swal from "sweetalert2";
import { useAppDispatch, useAppSelector } from "../../../../stores/store";
import { controlFloodgate, controlFloodgateSelector } from "../../../../stores/slices/ControlFloodgateSlice";

interface BtnAndTextInputItemProps {
    display_group_item: DisplayGroup;
    index: number;
    floodgateId: string;
}

export default function BtnAndTextInputItem({ display_group_item, index, floodgateId }: BtnAndTextInputItemProps) {
    const dispatch = useAppDispatch();

    const [control, setControl] = useState<string>("");
    const handleChange = (event: any) => {
        setControl(event.target.value);
    };
    const { data, isLoading } = useAppSelector(controlFloodgateSelector);

    const isTextSubmit = async (param: string, btn_name?: string, setting_node_id?: string, node?: any) => {
        if (node?.setting_node_data_type === 0 || node?.setting_node_data_type === 1) {
            const value = parseFloat(param);
            if (!isNaN(value) && isFinite(value)) {
                if (!(value >= parseFloat(node?.setting_node_data_min) && value <= parseFloat(node?.setting_node_data_max))) {
                    Swal.fire(
                        "เกิดข้อผิดพลาด",
                        `ข้อมูลควรอยู่ระหว่าง ${node?.setting_node_data_min}  ถึง ${node?.setting_node_data_max}`,
                        "error"
                    );
                } else {
                    if (floodgateId && setting_node_id && param) {
                        const result = await Swal.fire({
                            title: "คุณแน่ใจหรือไม่?",
                            text: `คุณจะดำเนินการ ${btn_name} ใช่หรือไม่`,
                            icon: "warning",
                            showCancelButton: true,
                            confirmButtonText: "ใช่, ดำเนินการ!",
                            cancelButtonText: "ไม่, ยกเลิก",
                        });

                        if (result.isConfirmed) {
                            try {
                                const loadingSwal = Swal.fire({
                                    title: "กำลังดำเนินการ...",
                                    html: `<div style="display: flex; flex-direction: column; align-items: center;">
                                                <div class="swal2-loader" style="margin-bottom: 20px;"></div>
                                            </div>
                                            <div style="text-align: center; margin-top: 20px;">
                                                <button id="cancelButton" class="swal2-cancel swal2-styled">ยกเลิก</button>
                                            </div>`,
                                    didOpen: () => {
                                        Swal.showLoading();
                                        const cancelButton =
                                            document.getElementById("cancelButton");
                                        if (cancelButton) {
                                            cancelButton.addEventListener("click", () => {
                                                Swal.close();
                                                Swal.fire("ยกเลิกการดำเนินการ", "", "info");
                                            });
                                        }
                                    },
                                    showConfirmButton: false,
                                    allowOutsideClick: false,
                                });
                                await dispatch(
                                    controlFloodgate({
                                        floodgate_id: floodgateId,
                                        setting_node_id: setting_node_id,
                                        value: param,
                                    })
                                );
                                if (data?.data.response && data.data.response.floodgate_data) {
                                    const responseString = Object.entries(
                                        data.data.response.floodgate_data
                                    )
                                        .map(([key, value]) => `${key}: ${value}`)
                                        .join(", ");
                                    Swal.fire(
                                        "ดำเนินการเรียบร้อย.",
                                        `${responseString}`,
                                        "success"
                                    );
                                } else {
                                    Swal.fire(
                                        "เกิดข้อผิดพลาด",
                                        "ไม่มีการตอบกลับจาก Server ปลายทาง",
                                        "error"
                                    );
                                }
                            } catch (error: any) {
                                Swal.fire("ดำเนินการไม่สำเร็จ", error.message, "error");
                            }
                        }
                    } else {
                        console.error("Missing data to control floodgate");
                    }
                }
            } else {
                Swal.fire("เกิดข้อผิดพลาด", "ชนิดข้อมูลไม่ถูกต้อง", "error");
            }
        }
    };

    return (
        <Grid item xs={12} sm={display_group_item.is_btn ? 4 : 6} md={display_group_item.is_btn ? 4 : 6} lg={display_group_item.is_btn ? 4 : 6} sx={{ borderRadius: "0.6rem", padding: 1, }}>
            <Card className="shadow-lg rounded-lg" key={`control-${index}`} sx={{
                textAlign: "center",
                borderRadius: "12px",
                backgroundColor: "rgba(255, 255, 255, 1)",
                boxShadow: "none",
            }}>
                {display_group_item.is_text_input ? (
                    <div className="bg-gray-100 p-4 border-b border-gray-200">
                        <Typography variant="h6" component="div">
                            {display_group_item.setting_nodes.setting_node_data_name}
                        </Typography>
                    </div>
                ) : null}
                <CardContent>
                    <Box display="flex" flexWrap="wrap" alignItems="center" alignContent="center" justifyContent="flex-start">

                        {/************* content */}
                        <Grid>
                            {display_group_item.is_btn ? (
                                <Button key={index}
                                    onClick={() =>
                                        isTextSubmit(
                                            display_group_item.btn_value,
                                            display_group_item.btn_name,
                                            display_group_item.setting_nodes.setting_node_id,
                                            display_group_item.setting_nodes
                                        )
                                    }
                                    variant="contained"
                                    style={{
                                        backgroundColor: display_group_item.btn_color,
                                        marginRight: 10,
                                        marginBottom: 10,
                                    }}
                                    className="bg-blue-500">
                                    {display_group_item.btn_name}
                                </Button>
                            ) : display_group_item.is_text_input ? (
                                <Box
                                    key={`input-${index}`}
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                    gap={2}
                                    sx={{ width: "100%" }}
                                >
                                    <TextField
                                        fullWidth
                                        label={display_group_item.text_input_name}
                                        value={control}
                                        onChange={handleChange}
                                        sx={{ flexGrow: 1 }}
                                        title={display_group_item.text_input_name}
                                    />
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        className="bg-blue-500"
                                        sx={{
                                            width: "auto",
                                            height: "55px",
                                            whiteSpace: "nowrap",
                                        }}
                                        onClick={() => isTextSubmit(
                                            control,
                                            display_group_item.text_input_name,
                                            display_group_item.setting_nodes?.setting_node_id,
                                            display_group_item.setting_nodes
                                        )}
                                        title={display_group_item.text_input_name}
                                    >
                                        ดำเนินการ
                                    </Button>
                                </Box>
                            ) : null}
                        </Grid>
                        {/************* content */}

                    </Box>
                </CardContent>
            </Card>
        </Grid>
    );
}
import React, { useEffect, useState } from 'react';
import { Box, Button, Modal, Fade, Backdrop, Typography, TextField, Grid, MenuItem } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../stores/store';
import { manageFloodgateInfo } from '../../../stores/slices/ManageFloodgateInfoSlice';
import { manageFloodgateSchema, manageFloodgateSchemaSelector } from '../../../stores/slices/ManageFloodgateSchemaSlice';
import { FloodgateGroup as FloodgateGroupAdmin } from '../../../models/floodgateInfo/FloodGateInfoAdminRes.model';
import { UpdateFloodgateRequest } from '../../../models/manageFloodgateInfo/ManageFloodgateInfoReq.model';
import { floodgateManageAction } from '../../../services/Constants/enum/enum.model';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

interface ManageFloodGateEditModalProps {
    open: boolean;
    floodgate: FloodgateGroupAdmin | null;
    onClose: () => void;
}

const ManageFloodGateEditModal: React.FC<ManageFloodGateEditModalProps> = ({ open, floodgate, onClose }) => {
    const dispatch = useAppDispatch();
    const schemaTypes = useAppSelector(manageFloodgateSchemaSelector).data || [];

    const [updatedFloodgate, setUpdatedFloodgate] = useState<UpdateFloodgateRequest | null>(null);
    const [errors, setErrors] = useState<{ [key: string]: string }>({});

    useEffect(() => {
        if (floodgate) {
            setUpdatedFloodgate({
                action: floodgateManageAction.UPDATE,
                floodgate_id: floodgate.floodgate_id,
                floodgate_name: floodgate.floodgate_name,
                floodgate_attachment_file: floodgate.floodgate_attachment_file,
                floodgate_ip: floodgate.floodgate_ip,
                floodgate_port: floodgate.floodgate_port,
                floodgate_username: floodgate.floodgate_username || 'NA',
                floodgate_password: floodgate.floodgate_password || 'NA',
                floodgate_schema_type_id: floodgate.floodgate_schema_type_id,
                floodgate_ns: floodgate.floodgate_ns || "0",
                floodgate_s: floodgate.floodgate_s || "",
                floodgate_obj: floodgate.floodgate_obj || "",
                floodgate_project: floodgate.floodgate_project || "",
                floodgate_map: floodgate.floodgate_map || "",
            });
        }
    }, [floodgate]);

    useEffect(() => {
        if (open) {
            dispatch(manageFloodgateSchema({ action: floodgateManageAction.GET }));
        }
    }, [dispatch, open]);

    const validate = () => {
        if (!updatedFloodgate) return false;
        let tempErrors: { [key: string]: string } = {};

        if (!updatedFloodgate.floodgate_name) {
            tempErrors.floodgate_name = 'กรุณาใส่ชื่อโครงการ';
        } else if (!/^[ก-๙a-zA-Z0-9\s]+$/.test(updatedFloodgate.floodgate_name)) {
            tempErrors.floodgate_name = 'ชื่อโครงการต้องเป็นภาษาไทย ภาษาอังกฤษ หรือตัวเลขเท่านั้น';
        }

        if (!updatedFloodgate.floodgate_ip) {
            tempErrors.floodgate_ip = 'กรุณาใส่ IP Address';
        } else if (!/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(updatedFloodgate.floodgate_ip)) {
            tempErrors.floodgate_ip = 'กรุณาใส่ IP Address ที่ถูกต้อง';
        }

        if (!updatedFloodgate.floodgate_port) {
            tempErrors.floodgate_port = 'กรุณาใส่ Port';
        } else if (!/^\d+$/.test(updatedFloodgate.floodgate_port)) {
            tempErrors.floodgate_port = 'Port ต้องเป็นตัวเลขเท่านั้น';
        } else if (parseInt(updatedFloodgate.floodgate_port, 10) < 1 || parseInt(updatedFloodgate.floodgate_port, 10) > 65535) {
            tempErrors.floodgate_port = 'Port ต้องอยู่ระหว่าง 1 ถึง 65535';
        }

        if (!updatedFloodgate.floodgate_schema_type_id) {
            tempErrors.floodgate_schema_type_id = 'กรุณาเลือก Schema Type';
        }

        if (!updatedFloodgate.floodgate_ns) {
            tempErrors.floodgate_ns = 'กรุณาใส่ Namespace';
        }

        if (!updatedFloodgate.floodgate_s) {
            tempErrors.floodgate_s = 'กรุณาใส่ Root';
        }

        if (!updatedFloodgate.floodgate_obj) {
            tempErrors.floodgate_obj = 'กรุณาใส่ Objects';
        }

        if (!updatedFloodgate.floodgate_project) {
            tempErrors.floodgate_project = 'กรุณาใส่ Project';
        }

        setErrors(tempErrors);
        return Object.keys(tempErrors).length === 0;
    };

    const handleChange = (field: keyof UpdateFloodgateRequest) => (event: React.ChangeEvent<HTMLInputElement>) => {
        if (updatedFloodgate) {
            setUpdatedFloodgate({ ...updatedFloodgate, [field]: event.target.value });
            setErrors((prev) => ({ ...prev, [field]: '' }));
        }
    };

    const handleSave = async () => {
        if (validate() && updatedFloodgate) {
            if (!updatedFloodgate.floodgate_username) {
                updatedFloodgate.floodgate_username = 'NA';
            }
            if (!updatedFloodgate.floodgate_password) {
                updatedFloodgate.floodgate_password = 'NA';
            }

            try {
                await dispatch(manageFloodgateInfo(updatedFloodgate)).unwrap();
                toast.success('บันทึกข้อมูลสำเร็จ');
                onClose();
            } catch (error) {
                toast.error('บันทึกข้อมูลไม่สำเร็จ');
            }
        }
    };

    const handleClose = () => {
        onClose();
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}
            closeAfterTransition
        >
            <Fade in={open}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '60%',
                        maxHeight: '80vh',
                        bgcolor: 'background.paper',
                        borderRadius: 2,
                        boxShadow: 24,
                        p: 4,
                        overflowY: 'auto',
                    }}
                >
                    <Typography variant="h6" component="h2" gutterBottom>
                        แก้ไขข้อมูลโครงการ
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label="ชื่อโครงการ"
                                value={updatedFloodgate?.floodgate_name || ''}
                                onChange={handleChange('floodgate_name')}
                                required
                                error={!!errors.floodgate_name}
                                helperText={errors.floodgate_name}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                select
                                fullWidth
                                label="กลุ่มของโครงการ"
                                value={updatedFloodgate?.floodgate_schema_type_id || ''}
                                onChange={handleChange('floodgate_schema_type_id')}
                                required
                                error={!!errors.floodgate_schema_type_id}
                                helperText={errors.floodgate_schema_type_id}
                            >
                                {Array.isArray(schemaTypes) && schemaTypes.map((type: { floodgate_schema_type_id: string, floodgate_schema_type_name: string }) => (
                                    <MenuItem key={type.floodgate_schema_type_id} value={type.floodgate_schema_type_id}>
                                        {type.floodgate_schema_type_name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label="IP Address"
                                value={updatedFloodgate?.floodgate_ip || ''}
                                onChange={handleChange('floodgate_ip')}
                                required
                                error={!!errors.floodgate_ip}
                                helperText={errors.floodgate_ip}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label="Port"
                                value={updatedFloodgate?.floodgate_port || ''}
                                onChange={handleChange('floodgate_port')}
                                required
                                error={!!errors.floodgate_port}
                                helperText={errors.floodgate_port}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label="Username"
                                value={updatedFloodgate?.floodgate_username || ''}
                                onChange={handleChange('floodgate_username')}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label="Password"
                                value={updatedFloodgate?.floodgate_password || ''}
                                onChange={handleChange('floodgate_password')}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label="Namespace"
                                value={updatedFloodgate?.floodgate_ns || ''}
                                onChange={handleChange('floodgate_ns')}
                                required
                                type="number"
                                error={!!errors.floodgate_ns}
                                helperText={errors.floodgate_ns}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label="Root"
                                value={updatedFloodgate?.floodgate_s || ''}
                                onChange={handleChange('floodgate_s')}
                                required
                                error={!!errors.floodgate_s}
                                helperText={errors.floodgate_s}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label="Objects"
                                value={updatedFloodgate?.floodgate_obj || ''}
                                onChange={handleChange('floodgate_obj')}
                                required
                                error={!!errors.floodgate_obj}
                                helperText={errors.floodgate_obj}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label="Project"
                                value={updatedFloodgate?.floodgate_project || ''}
                                onChange={handleChange('floodgate_project')}
                                required
                                error={!!errors.floodgate_project}
                                helperText={errors.floodgate_project}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                fullWidth
                                label="Map Script"
                                value={updatedFloodgate?.floodgate_map || ''}
                                onChange={handleChange('floodgate_map')}
                            />
                        </Grid>
                    </Grid>

                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                        <Button onClick={handleClose} sx={{ mr: 2 }}>
                            ยกเลิก
                        </Button>
                        <Button variant="contained" onClick={handleSave}>
                            บันทึก
                        </Button>
                    </Box>
                </Box>
            </Fade>
        </Modal>
    );
};

export default ManageFloodGateEditModal;
import * as React from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { styled, ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';

import { useAppDispatch, useAppSelector } from "./stores/store";
import { loginSelector, userLogin, userLogout } from "./stores/slices/LoginSlice";
import { userRolesSelector, fetchUserRoles } from './stores/slices/UserRolesSlice';

import themesOption from './options/themes/themes';
import PublicRoutes from "./options/routers/public.routes";
import ProtectedRoutes from "./options/routers/protected.routes";

import PublicHeader from './components/layouts/PublicHeader';
import Header from './components/layouts/Header';
import Menu from './components/layouts/Menu';
import PageContainer from './components/layouts/PageContainer';

import NotFound from './components/pages/Notfound';
import LoginPage from './components/pages/LoginPage';
import GuestPage from './components/pages/GuestPage';
import FloodGateReportPage from './components/pages/FloodGateReportPage';
import FloodGateMonitorPage from './components/pages/MonitorPage';
import MonitorDetail from './components/pages/MonitorPage/MonitorDetail/MonitorDetail';
import UserInfo from './components/pages/UserInfoPage';
import ManageFloodGatePage from './components/pages/ManageFloodGatePage';

const drawerWidth = 240;
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

export default function App() {
  const [open, setOpen] = React.useState(true);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();  // ใช้ในการนำทางเมื่อมีปัญหา login

  const loginReducer = useAppSelector(loginSelector);
  const { data: userRoleLogin } = useAppSelector(userRolesSelector);


  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    if (!loginReducer.isLoggedIn) {
      const checkLogin = async () => {
        try {
          await dispatch(userLogin(null)).unwrap();
          await dispatch(fetchUserRoles(false)).unwrap();
        } catch (error) {
          dispatch(userLogout());
        }
      };

      checkLogin();
    }
  }, [dispatch, navigate, loginReducer.isLoggedIn]);

  return (
    <ThemeProvider theme={themesOption}>
      <Box sx={{ display: "flex" }}>
        <ToastContainer />
        <CssBaseline />
        {!loginReducer.isLoggedIn && (
          <PublicHeader />
        )}
        {loginReducer.isLoggedIn && (
          <>
            <Header open={open} setOpen={setOpen} onDrawerOpen={handleDrawerOpen} />
            <Menu open={open} setOpen={setOpen} onDrawerClose={handleDrawerClose} userRole={userRoleLogin} />
          </>
        )}
        <Main open={open}>
          <DrawerHeader />
          <Routes>
            {/* Public routes */}
            <Route path="/" element={<PublicRoutes />}>
              <Route path="/guest" element={<PageContainer><GuestPage /></PageContainer>} />
              <Route path="/login" element={<PageContainer><LoginPage setOpen={setOpen} /></PageContainer>} />
              <Route path="/" element={<Navigate to="/guest" />} />
              <Route path="*" element={<PageContainer><NotFound /></PageContainer>} />
            </Route>

            {/* Protected routes */}
            <Route path="/" element={<ProtectedRoutes />}>
              <Route path="/floodgate/report" element={<PageContainer><FloodGateReportPage /></PageContainer>} />
              <Route path="/floodgate/monitor" element={<PageContainer><FloodGateMonitorPage /></PageContainer>} />
              <Route path="/floodgate/monitor/:id" element={<PageContainer><MonitorDetail /></PageContainer>} />
              <Route path="/UserInfo" element={<PageContainer><UserInfo /></PageContainer>} />
              <Route path="/ManageFloodGate" element={<PageContainer><ManageFloodGatePage /></PageContainer>} />
              <Route path="*" element={<Navigate to="/floodgate/report" />} />
            </Route>
          </Routes>
        </Main>
      </Box>
    </ThemeProvider>
  );
}
import * as React from 'react';
import { Container } from '@mui/material';

interface PageContainerProps {
  children: React.ReactNode; // กำหนดประเภทของ props ที่คาดหวัง
}

const PageContainer: React.FC<PageContainerProps> = ({ children }) => {
  return (
    // <Container sx={{ marginTop: '2px' }}>
    <div>
      {children}
    </div>
    // </Container>
  );
};

export default PageContainer;
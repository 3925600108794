import React, { useEffect, useState } from 'react';
import { Box, Button, Modal, Fade, Backdrop, Typography, CircularProgress } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import { DataGrid, GridColDef, GridRowId, GridRowSelectionModel, GridToolbarContainer } from '@mui/x-data-grid';
import Swal from 'sweetalert2';
import { useAppDispatch, useAppSelector } from '../../../stores/store';
import { manageFloodgateSchema, manageFloodgateSchemaSelector } from '../../../stores/slices/ManageFloodgateSchemaSlice';
import { ManageFloodgateSchemaRequest, DeleteFloodgateSchemaRequest } from '../../../models/manageFloodgateGroupSchema/ManageFloodgateSchemaRequest.model';
import { ManageFloodgateSchemaResponse } from '../../../models/manageFloodgateGroupSchema/ManageFloodgateSchemaResponse.model';
import { floodgateManageAction } from '../../../services/Constants/enum/enum.model';
import ManageFloodGateSchemaEditModal from './ManageFloodGateSchemaEditModal';
import ManageFloodGateSchemaCreateModal from './ManageFloodGateSchemaCreateModal';

interface ModalProps {
    open: boolean;
    onClose: () => void;
}

const ManageFloodGateSchemaDataModal: React.FC<ModalProps> = ({ open, onClose }) => {
    const dispatch = useAppDispatch();
    const { data, isLoading, isError, errorMessage } = useAppSelector(manageFloodgateSchemaSelector);
    const [rows, setRows] = useState<ManageFloodgateSchemaResponse['data']['response'][]>([]);
    const [selectedRowId, setSelectedRowId] = useState<GridRowId | null>(null);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [floodgateToEdit, setFloodgateToEdit] = useState<ManageFloodgateSchemaResponse['data']['response'] | null>(null);

    useEffect(() => {
        if (open) {
            const request: ManageFloodgateSchemaRequest = { action: floodgateManageAction.GET };
            dispatch(manageFloodgateSchema(request));
        }
    }, [dispatch, open]);

    useEffect(() => {
        if (data) {
            setRows(Array.isArray(data) ? data : [data]);
        }
    }, [data]);

    const [modalState, setModalState] = useState({
        floodgateCreate: false,
        floodgateSchemaCreate: false,
        floodgateSchemaData: false,
        floodgatetypeData: false, // แก้ไขจาก ProgramTemplateTypeData
    });

    // const handleModalOpen = (modalName: keyof typeof modalState) => () =>
    //     setModalState((prev) => ({ ...prev, [modalName]: true }));

    // const handleModalClose = (modalName: keyof typeof modalState) => () =>
    //     setModalState((prev) => ({ ...prev, [modalName]: false }));

    const handleCreateClick = () => {
        setCreateModalOpen(true);
    };

    const handleCreateModalClose = () => {
        setCreateModalOpen(false);
        dispatch(manageFloodgateSchema({ action: floodgateManageAction.GET }));
    };

    const handleEditClick = () => {
        const selectedFloodgate = rows.find((row) => row.floodgate_schema_type_id === selectedRowId);
        if (selectedFloodgate) {
            setFloodgateToEdit(selectedFloodgate);
            setEditModalOpen(true);
        }
    };

    const handleDeleteClick = async () => {
        const floodgateToDelete = rows.find((row) => row.floodgate_schema_type_id === selectedRowId);
        if (floodgateToDelete) {
            onClose(); // ปิด Modal ก่อนแสดง SweetAlert

            const result = await Swal.fire({
                title: 'คุณแน่ใจหรือไม่?',
                text: 'คุณต้องการลบข้อมูลนี้หรือไม่?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'ใช่, ลบเลย!',
                cancelButtonText: 'ยกเลิก',
            });

            if (result.isConfirmed) {
                try {
                    const deleteRequest: DeleteFloodgateSchemaRequest = {
                        action: floodgateManageAction.DELETE,
                        floodgate_schema_type_id: floodgateToDelete.floodgate_schema_type_id,
                        floodgate_schema_type_name: floodgateToDelete.floodgate_schema_type_name,
                    };
                    await dispatch(manageFloodgateSchema(deleteRequest)).unwrap();
                    Swal.fire('ลบแล้ว!', 'ข้อมูลได้ถูกลบแล้ว.', 'success');
                    dispatch(manageFloodgateSchema({ action: floodgateManageAction.GET }));
                } catch (error: any) {
                    Swal.fire('เกิดข้อผิดพลาด', error.message, 'error');
                }
            }

            // เปิด Modal กลับหลังจาก SweetAlert ถูกยืนยันหรือยกเลิก
            onClose();
        }
    };

    const handleRowSelection = (rowSelectionModel: GridRowSelectionModel) => {
        setSelectedRowId(rowSelectionModel.length === 1 ? rowSelectionModel[0] : null);
    };

    const handleEditModalClose = () => {
        setEditModalOpen(false);
        setFloodgateToEdit(null);
        dispatch(manageFloodgateSchema({ action: floodgateManageAction.GET }));
    };

    const CustomToolbar = () => (
        <GridToolbarContainer>
            <Button
                startIcon={<AddIcon />}
                onClick={handleCreateClick}
                sx={{ mr: 2 }}
            >
                เพิ่ม
            </Button>
            <Button
                startIcon={<EditIcon />}
                onClick={handleEditClick}
                disabled={!selectedRowId}
                sx={{ mr: 2 }}
            >
                แก้ไข
            </Button>
            <Button
                startIcon={<DeleteIcon />}
                onClick={handleDeleteClick}
                disabled={!selectedRowId}
            >
                ลบ
            </Button>
        </GridToolbarContainer>
    );

    if (isLoading) return <CircularProgress />;
    if (isError) return <Typography>Error: {errorMessage}</Typography>;

    const handleClose = () => {
        onClose();
    };

    return (
        <Modal
            open={open}
            onClose={handleClose} // ปิด Modal เมื่อคลิกที่ backdrop
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{ timeout: 500 }}
        >
            <Fade in={open}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '40%',
                        maxHeight: '80vh',
                        bgcolor: 'background.paper',
                        borderRadius: 2,
                        boxShadow: 24,
                        p: 4,
                        overflowY: 'auto',
                    }}
                >
                    <DataGrid
                        rows={rows}
                        columns={[{ field: 'floodgate_schema_type_name', headerName: 'กลุ่มโครงการ', width: 300 }]}
                        checkboxSelection
                        getRowId={(row) => row.floodgate_schema_type_id}
                        onRowSelectionModelChange={handleRowSelection}
                        slots={{ toolbar: CustomToolbar }}
                    />

                    <ManageFloodGateSchemaCreateModal
                        open={createModalOpen}
                        onClose={handleCreateModalClose}
                    />

                    <ManageFloodGateSchemaEditModal
                        open={editModalOpen}
                        floodgate={floodgateToEdit}
                        onClose={handleEditModalClose}
                    />
                </Box>
            </Fade>
        </Modal>
    );
};

export default ManageFloodGateSchemaDataModal;

import * as React from "react";
import Floodgatelist from '../FloodGateListPage';

const FloodGateReportPage: React.FC = () => {
  return (
    <div>
      <Floodgatelist />
    </div>
  );
};

export default FloodGateReportPage;

// PublicRoutes.tsx
import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAppSelector } from "../../stores/store";
import { loginSelector } from "../../stores/slices/LoginSlice";

const PublicRoutes = () => {
  const loginReducer = useAppSelector(loginSelector);
  const auth = loginReducer.result?.status;

  // ถ้า auth สำเร็จ ให้ไปที่ /floodgate/report ถ้าไม่สำเร็จ ให้แสดงผลเส้นทางภายใน PublicRoutes
  return auth ? <Navigate to="/floodgate/report" /> : <Outlet />;
};

export default PublicRoutes;
import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { DataGrid, GridColDef, GridRowId, GridToolbarContainer, GridToolbarContainerProps, GridRowSelectionModel, GridCallbackDetails } from '@mui/x-data-grid';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import BuildCircleIcon from '@mui/icons-material/BuildCircle';
import Swal from 'sweetalert2';
import { useAppDispatch, useAppSelector } from '../../../stores/store';
import { fetchFloodGateInfo, floodGateInfoSelector } from '../../../stores/slices/FloodGateInfoSlice';
import { floodgateAction, floodgateManageAction } from '../../../services/Constants/enum/enum.model';
import { FloodgateGroup as FloodgateGroupAdmin } from '../../../models/floodgateInfo/FloodGateInfoAdminRes.model'; // ใช้ Model ที่ถูกต้อง
import { manageFloodgateInfo } from '../../../stores/slices/ManageFloodgateInfoSlice';
import ManageFloodGateEditModal from './ManageFloodGateEditModal';
import ManageProgramTemplateDataModal from './ManageProgramTemplateDataModal';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import ManageFloodGateImageUploadModal from './ManageFloodGateImageUploadModal';

export default function FloodGateTable() {
    const dispatch = useAppDispatch();
    const { dataByAdmin, isLoadingByAdmin, isErrorByAdmin, errorMessageByAdmin } = useAppSelector(floodGateInfoSelector);
    const [rows, setRows] = useState<FloodgateGroupAdmin[]>([]); // ใช้ Model ที่ถูกต้องสำหรับ admin
    const [selectedRowId, setSelectedRowId] = useState<GridRowId | null>(null);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [floodgateToEdit, setFloodgateToEdit] = useState<FloodgateGroupAdmin | null>(null);
    const [upload, setUpload] = useState<String>();
    const [modalState, setModalState] = useState({
        ManageProgramTemplateDataModal: false,
    });

    const [modalUpload, setModalUpload] = useState(false);

    useEffect(() => {
        dispatch(fetchFloodGateInfo(floodgateAction.getAllByAdmin));
    }, [dispatch]);

    useEffect(() => {
        if (dataByAdmin) {
            const formattedData = dataByAdmin.map((item) => {
                if ('floodgate_id' in item) {
                    return item as FloodgateGroupAdmin;
                }
                return null;
            }).filter(Boolean); // ลบค่า null ออก

            setRows(formattedData as FloodgateGroupAdmin[]);
        }
    }, [dataByAdmin]);

    const handleModalClose = (modalName: keyof typeof modalState) => () => {
        setModalState((prev) => ({ ...prev, [modalName]: false }));
        setFloodgateToEdit(null);
    };

    const handleProgramTemplateClick = () => {
        const selectedFloodgate = rows.find((row) => row.floodgate_id === selectedRowId);
        if (selectedFloodgate) {
            setFloodgateToEdit(selectedFloodgate); // ตั้งค่า floodgate ที่เลือก
            setModalState((prev) => ({ ...prev, ManageProgramTemplateDataModal: true })); // เปิด modal
        }
    };

    const handleUploadFileClick = () => {
        const selectedFloodgate = rows.find((row) => row.floodgate_id === selectedRowId);
        if (selectedFloodgate) {
            setUpload(selectedFloodgate.floodgate_id);
            setModalUpload(true)
        }
    };

    const handleUploadModalClose = () => {
        setModalUpload(false);
    };

    const handleEditClick = () => {
        const selectedFloodgate = rows.find((row) => row.floodgate_id === selectedRowId);
        if (selectedFloodgate) {
            setFloodgateToEdit(selectedFloodgate);
            setEditModalOpen(true);
        }
    };

    const handleDeleteClick = async () => {
        const floodgateToDelete = rows.find((row) => row.floodgate_id === selectedRowId);
        if (floodgateToDelete) {
            const result = await Swal.fire({
                title: 'คุณแน่ใจหรือไม่?',
                text: "คุณจะลบข้อมูลนี้ ใช่หรือไม่",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'ใช่, ลบเลย!',
                cancelButtonText: 'ไม่, ยกเลิก'
            });

            if (result.isConfirmed) {
                try {
                    await dispatch(manageFloodgateInfo({
                        action: floodgateManageAction.DELETE,
                        floodgate_id: floodgateToDelete.floodgate_id,
                        floodgate_name: floodgateToDelete.floodgate_name,
                        floodgate_attachment_file: floodgateToDelete.floodgate_attachment_file,
                        floodgate_ip: floodgateToDelete.floodgate_ip,
                        floodgate_port: floodgateToDelete.floodgate_port,
                        floodgate_username: floodgateToDelete.floodgate_username,
                        floodgate_password: floodgateToDelete.floodgate_password,
                        floodgate_schema_type_id: floodgateToDelete.floodgate_schema_type_id,
                        floodgate_ns: "0",
                        floodgate_s: "NA",
                        floodgate_obj: "NA",
                        floodgate_project: "NA",
                        floodgate_map: "NA",
                    })).unwrap();

                    Swal.fire('ลบแล้ว!', 'ข้อมูลได้ถูกลบแล้ว.', 'success');
                    // รีโหลดข้อมูลในตารางใหม่หลังจากลบสำเร็จ
                    dispatch(fetchFloodGateInfo(floodgateAction.getAllByAdmin));
                } catch (error: any) {
                    Swal.fire('ลบข้อมูลไม่สำเร็จ', error.message, 'error');
                }
            }
        }
    };

    const handleRowSelection = (rowSelectionModel: GridRowSelectionModel) => {
        setSelectedRowId(rowSelectionModel.length === 1 ? rowSelectionModel[0] : null);
    };

    const handleEditModalClose = () => {
        setEditModalOpen(false);
        setFloodgateToEdit(null);
        dispatch(fetchFloodGateInfo(floodgateAction.getAllByAdmin)); // รีโหลดข้อมูลหลังจากปิด modal
    };

    const CustomToolbar = (props: GridToolbarContainerProps) => (
        <GridToolbarContainer {...props}>
            <Button
                startIcon={<CloudUploadIcon />}
                onClick={handleUploadFileClick}
                disabled={!selectedRowId}
            >
                อัพโหลดรูปภาพ
            </Button>
            <Button
                startIcon={<EditIcon />}
                onClick={handleEditClick}
                disabled={!selectedRowId}
            >
                แก้ไข
            </Button>
            <Button
                startIcon={<DeleteIcon />}
                onClick={handleDeleteClick}
                disabled={!selectedRowId}
            >
                ลบ
            </Button>
            <Button
                startIcon={<BuildCircleIcon />}
                onClick={handleProgramTemplateClick}
                disabled={!selectedRowId}
            >
                การแสดงผลและเซ็นเซอร์
            </Button>
        </GridToolbarContainer>
    );

    const columns: GridColDef[] = [
        { field: 'floodgate_name', headerName: 'Floodgate Name', width: 200 },
        { field: 'floodgate_attachment_file', headerName: 'Attachment File', width: 250 },
        { field: 'floodgate_ip', headerName: 'IP Address', width: 150 },
        { field: 'floodgate_port', headerName: 'Port', width: 100 },
        { field: 'floodgate_username', headerName: 'Username', width: 150 },
        { field: 'floodgate_password', headerName: 'Password', width: 150 },
        { field: 'floodgate_schema_type_id', headerName: 'Schema Type ID', width: 150 },
    ];

    if (isLoadingByAdmin) return <CircularProgress />;
    if (isErrorByAdmin) return <Typography>Error: {errorMessageByAdmin}</Typography>;

    return (
        <Box sx={{ height: 500, width: '100%' }}>
            <DataGrid
                rows={rows}
                columns={columns}
                checkboxSelection
                getRowId={(row) => row.floodgate_id} // ระบุว่า floodgate_id เป็น id
                onRowSelectionModelChange={(rowSelectionModel: GridRowSelectionModel, details: GridCallbackDetails) =>
                    handleRowSelection(rowSelectionModel)
                }
                slots={{
                    toolbar: CustomToolbar,
                }}
            />

            <ManageFloodGateEditModal
                open={editModalOpen}
                floodgate={floodgateToEdit}
                onClose={handleEditModalClose}
            />

            <ManageProgramTemplateDataModal
                open={modalState.ManageProgramTemplateDataModal}
                floodgateId={floodgateToEdit?.floodgate_id || '0'}
                onClose={handleModalClose('ManageProgramTemplateDataModal')}
            />

            <ManageFloodGateImageUploadModal
                open={modalUpload}
                floodgateId={upload?.toString() || ''}
                onClose={handleUploadModalClose}
            />
        </Box>
    );
}

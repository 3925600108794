import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { RootState } from "../store";
import { FloodgateDetailAPIResponse, FloodgateDetailResponse } from "../../models/floodgateDetail/FloodgateDetail.model";
import config from "../../config";
import { TOKEN } from "../../services/Constants/systems";

interface FloodgateDetailState {
    data: FloodgateDetailResponse | null;
    isLoading: boolean;
    isError: boolean;
    errorMessage: string | null;
}
const initialState: FloodgateDetailState = {
    data: null,
    isLoading: false,
    isError: false,
    errorMessage: null,
};
export const fetchFloodgateDetail = createAsyncThunk<
    FloodgateDetailResponse,
    string, // floodgate_id
    { rejectValue: string }
>(
    "floodgateDetail/fetchFloodgateDetail",
    async (floodgate_id, { rejectWithValue }) => {
        try {
            const token = localStorage.getItem(TOKEN);
            if (!token) throw new Error("Token is missing");

            const response = await axios.post<FloodgateDetailAPIResponse>(
                config.floodgate.floodgate_url + config.floodgate.floodgate_detail,
                { floodgate_id },
                {
                    headers: {
                        authorization: token,
                        "Content-Type": "application/json",
                    },
                }
            );

            if (response.data.status) {
                return response.data.data.response;
            } else {
                return rejectWithValue(response.data.message);
            }
        } catch (error: any) {
            return rejectWithValue(error.response?.data?.message || "เกิดข้อผิดพลาด หรือ session หมดอายุ กรุณาเข้าสู่ระบบใหม่อีกครั้ง");
        }
    }
);

const floodgateDetailSlice = createSlice({
    name: "floodgateDetail",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchFloodgateDetail.pending, (state) => {
                state.isLoading = true;
                state.isError = false;
                state.errorMessage = null;
            })
            .addCase(
                fetchFloodgateDetail.fulfilled,
                (state, action: PayloadAction<FloodgateDetailResponse>) => {
                    state.isLoading = false;
                    state.data = action.payload;
                }
            )
            .addCase(
                fetchFloodgateDetail.rejected,
                (state, action: PayloadAction<string | undefined>) => {
                    state.isLoading = false;
                    state.isError = true;
                    state.errorMessage = action.payload || "เกิดข้อผิดพลาด หรือ session หมดอายุ กรุณาเข้าสู่ระบบใหม่อีกครั้ง";
                }
            );
    },
});

export const floodgateDetailSelector = (store: RootState) => store.floodgateDetail;
export default floodgateDetailSlice.reducer;
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { createSelector } from 'reselect'; // Import createSelector จาก reselect
import { RootState } from '../store';
import config from '../../config';
import { FloodgateGroup as FloodgateGroupGuest } from '../../models/floodgateInfo/FloodGateInfoGuestRes.model';
import { FloodgateGroup as FloodgateGroupAdmin, FloodgateResponse as FloodgateAdminResponse } from '../../models/floodgateInfo/FloodGateInfoAdminRes.model';
import { FloodgateGroup as FloodgateGroupUser, FloodgateUserResponse } from '../../models/floodgateInfo/FloodGateInfoUserRes.model';
import { httpClient } from '../../services/Connection/httpclient';
import { floodgateAction } from '../../services/Constants/enum/enum.model';
import { TOKEN } from '../../services/Constants/systems';

// Type Definitions
type FloodGateData = FloodgateGroupGuest[] | FloodgateGroupAdmin[] | FloodgateGroupUser[];

// State Interface
interface FloodGateInfoState {
    dataByUser: FloodgateGroupUser[] | null;
    dataByGuest: FloodgateGroupGuest[] | null;
    dataByAdmin: FloodgateGroupAdmin[] | null;
    isLoadingByUser: boolean;
    isLoadingByGuest: boolean;
    isLoadingByAdmin: boolean;
    isErrorByUser: boolean;
    isErrorByGuest: boolean;
    isErrorByAdmin: boolean;
    errorMessageByUser: string | null;
    errorMessageByGuest: string | null;
    errorMessageByAdmin: string | null;
}

// Initial State
const initialState: FloodGateInfoState = {
    dataByUser: null,
    dataByGuest: null,
    dataByAdmin: null,
    isLoadingByUser: false,
    isLoadingByGuest: false,
    isLoadingByAdmin: false,
    isErrorByUser: false,
    isErrorByGuest: false,
    isErrorByAdmin: false,
    errorMessageByUser: null,
    errorMessageByGuest: null,
    errorMessageByAdmin: null,
};

// Async Thunk for API Calls
export const fetchFloodGateInfo = createAsyncThunk<
    FloodGateData,
    floodgateAction,
    { rejectValue: string }
>(
    'floodGateInfo/fetchFloodGateInfo',
    async (floodgate_action, { rejectWithValue }) => {
        try {
            const token = localStorage.getItem(TOKEN);
            let headers: Record<string, string> = {};
            let response;

            switch (floodgate_action) {
                case floodgateAction.getAllByUser:
                    if (!token) return rejectWithValue("Token is missing");
                    headers = {
                        'authorization': token,
                        'getallstatus': 'false',
                    };
                    response = await httpClient.post<FloodgateUserResponse>(
                        config.floodgate.floodgate_url + config.floodgate.floodgate_info,
                        null,
                        { headers }
                    );
                    if (response.data.status) {
                        return response.data.data.response;
                    } else {
                        return rejectWithValue(response.data.message);
                    }
                case floodgateAction.getAllByGuest:
                    headers = { 'getallstatus': 'true' };
                    response = await httpClient.post<FloodgateAdminResponse>(
                        config.floodgate.floodgate_url + config.floodgate.floodgate_info,
                        null,
                        { headers }
                    );
                    if (response.data.status) {
                        return response.data.data.response;
                    } else {
                        return rejectWithValue(response.data.message);
                    }
                case floodgateAction.getAllByAdmin:
                    if (!token) return rejectWithValue("Token is missing");
                    headers = {
                        'authorization': token,
                        'getallstatus': 'true',
                    };
                    response = await httpClient.post<FloodgateAdminResponse>(
                        config.floodgate.floodgate_url + config.floodgate.floodgate_info,
                        null,
                        { headers }
                    );
                    if (response.data.status) {
                        return response.data.data.response;
                    } else {
                        return rejectWithValue(response.data.message);
                    }
                default:
                    return rejectWithValue("Invalid action");
            }
        } catch (error: any) {
            return rejectWithValue(error.response?.data?.message || "เกิดข้อผิดพลาด หรือ session หมดอายุ กรุณาเข้าสู่ระบบใหม่อีกครั้ง");
        }
    }
);

// Slice Definition
const floodGateInfoSlice = createSlice({
    name: 'floodGateInfo',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            // Handle pending state for all actions
            .addCase(fetchFloodGateInfo.pending, (state, action) => {
                switch (action.meta.arg) {
                    case floodgateAction.getAllByUser:
                        state.isLoadingByUser = true;
                        state.isErrorByUser = false;
                        state.errorMessageByUser = null;
                        break;
                    case floodgateAction.getAllByGuest:
                        state.isLoadingByGuest = true;
                        state.isErrorByGuest = false;
                        state.errorMessageByGuest = null;
                        break;
                    case floodgateAction.getAllByAdmin:
                        state.isLoadingByAdmin = true;
                        state.isErrorByAdmin = false;
                        state.errorMessageByAdmin = null;
                        break;
                }
            })
            // Handle fulfilled state for all actions
            .addCase(fetchFloodGateInfo.fulfilled, (state, action) => {
                switch (action.meta.arg) {
                    case floodgateAction.getAllByUser:
                        state.isLoadingByUser = false;
                        state.dataByUser = action.payload as FloodgateGroupUser[];
                        break;
                    case floodgateAction.getAllByGuest:
                        state.isLoadingByGuest = false;
                        state.dataByGuest = action.payload as FloodgateGroupGuest[];
                        break;
                    case floodgateAction.getAllByAdmin:
                        state.isLoadingByAdmin = false;
                        state.dataByAdmin = action.payload as FloodgateGroupAdmin[];
                        break;
                }
            })
            // Handle rejected state for all actions
            .addCase(fetchFloodGateInfo.rejected, (state, action) => {
                switch (action.meta.arg) {
                    case floodgateAction.getAllByUser:
                        state.isLoadingByUser = false;
                        state.isErrorByUser = true;
                        state.errorMessageByUser = action.payload || "เกิดข้อผิดพลาด หรือ session หมดอายุ กรุณาเข้าสู่ระบบใหม่อีกครั้ง";
                        break;
                    case floodgateAction.getAllByGuest:
                        state.isLoadingByGuest = false;
                        state.isErrorByGuest = true;
                        state.errorMessageByGuest = action.payload || "เกิดข้อผิดพลาด หรือ session หมดอายุ กรุณาเข้าสู่ระบบใหม่อีกครั้ง";
                        break;
                    case floodgateAction.getAllByAdmin:
                        state.isLoadingByAdmin = false;
                        state.isErrorByAdmin = true;
                        state.errorMessageByAdmin = action.payload || "เกิดข้อผิดพลาด หรือ session หมดอายุ กรุณาเข้าสู่ระบบใหม่อีกครั้ง";
                        break;
                }
            });
    },
});

// Memoized Selectors with createSelector
export const floodGateInfoSelector = createSelector(
    (state: RootState) => state.floodGateInfo,
    (floodGateInfo) => ({
        dataByUser: floodGateInfo.dataByUser,
        dataByGuest: floodGateInfo.dataByGuest,
        dataByAdmin: floodGateInfo.dataByAdmin,
        isLoadingByUser: floodGateInfo.isLoadingByUser,
        isLoadingByGuest: floodGateInfo.isLoadingByGuest,
        isLoadingByAdmin: floodGateInfo.isLoadingByAdmin,
        isErrorByUser: floodGateInfo.isErrorByUser,
        isErrorByGuest: floodGateInfo.isErrorByGuest,
        isErrorByAdmin: floodGateInfo.isErrorByAdmin,
        errorMessageByUser: floodGateInfo.errorMessageByUser,
        errorMessageByGuest: floodGateInfo.errorMessageByGuest,
        errorMessageByAdmin: floodGateInfo.errorMessageByAdmin,
    })
);

export default floodGateInfoSlice.reducer;

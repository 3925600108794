export enum eUserRole {
  Guest = "1",
  Operator = "2",
  Management = "3",
  Admin = "4",
};
export enum errorCodes {
  responseError = 50001,
  noResponse = 5002,
  requestSetupError = 5003,
};
export enum floodgateDataTypes {
  int = 0,
  arrayint = 1,
  string = 2,
  boolean = 3,
};
export enum floodgateAction {
  getAllByUser = 1,
  getAllByGuest = 2,
  getAllByAdmin = 3,
};
export enum floodgateManageAction {
  ADD = 1,
  UPDATE = 2,
  DELETE = 3,
  GET = 4,
  GETBYID = 5,
}
export enum floodgateImage {
  FlapGateWeirCurved1 = "FlapGateWeirCurved1",
  FlapGateWeirStraight1 = "FlapGateWeirStraight1",
  FloodGateHandCrank = "FloodGateHandCrank",
  FloodGateLiftingMachine5 = "FloodGateLiftingMachine5",
  FloodGateSling5 = "FloodGateSling5",
  RubberWeir1 = "RubberWeir1"
}
export const FloodgateImageDisplayNames: { [key in floodgateImage]: string } = {
  [floodgateImage.FlapGateWeirCurved1]: "(หลังบานโค้ง) ทุก ๆ 1 องศา",
  [floodgateImage.FlapGateWeirStraight1]: "(หลังบานตรง) ทุก ๆ 1 องศา",
  [floodgateImage.FloodGateHandCrank]: "(เครื่องยก) ทุก ๆ 5 cm",
  [floodgateImage.FloodGateLiftingMachine5]: "มือหมุน",
  [floodgateImage.FloodGateSling5]: "(สลิง) ทุก ๆ 5 cm",
  [floodgateImage.RubberWeir1]: "Rubber Weir ทุก ๆ 1 องศา"
};

// Example usage
// const displayName = FloodgateImageDisplayNames[FloodgateImage.FlapGateWeirCurved1];
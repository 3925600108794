import React, { useEffect, useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { SettingNode } from "../../../models/monitor/MonitorDetailResponse.model";
import { DisplayGroupSettingNode } from "../../../models/monitor/MonitorDetail.model";

interface ChartProps {
  socketData: any;
  settingNodes: any;
  setting_node_data_name: string;
  height: number | string;
  graph_color?: string;
  initialData: any;
}

const handleArr = (setting: DisplayGroupSettingNode, data: string) => {
  if (data != "0") {
    if (setting.setting_node_data_type == "1") {
      // 1 = Array
      const dataArray = data.split(",");
      const index = setting.setting_node_sequence;
      if (index >= 0 && index < dataArray.length) {
        const inputValue = parseFloat(dataArray[index]);
        if (!isNaN(inputValue)) {
          return inputValue.toFixed(2);
        }
        return dataArray[index];
      } else {
        return "0";
      }
    } else {
      return data;
    }
  }
  return "0";
};

const LineChartComponent: React.FC<ChartProps> = ({
  socketData,
  settingNodes,
  setting_node_data_name,
  height = 200,
  graph_color = "#00b0f0",
  initialData,
}) => {
  const [datachart, setDatachart] = useState<Array<{ name: string;[key: string]: string | number }>>([]);

  useEffect(() => {
    // ตั้งค่าข้อมูลเริ่มต้นจาก initialData
    if (initialData && initialData.length > 0) {
      const initialChartData = initialData.map((data: any) => {
        const sensorData = data[settingNodes.floodgate_id]?.[settingNodes.setting_node_data_key];
        const transactionDate = data[settingNodes.floodgate_id]?.floodgate_transaction_createdate;
        if (!sensorData || !transactionDate) return null;

        const transactionTime = new Date(transactionDate).toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
        });

        const sensorValue = parseFloat(handleArr(settingNodes, sensorData));
        return {
          name: transactionTime,
          [setting_node_data_name]: sensorValue,
        };
      }).filter(Boolean); // กรองค่า null ออก

      setDatachart(initialChartData);
    }
  }, [initialData, settingNodes, setting_node_data_name]);

  useEffect(() => {
    // อัพเดตข้อมูลเมื่อมี socketData ใหม่เข้ามา
    if (socketData && Object.keys(socketData).length > 0) {
      const sensorData = socketData[settingNodes.floodgate_id]?.[settingNodes.setting_node_data_key];
      const transactionDate = socketData[settingNodes.floodgate_id]?.floodgate_transaction_createdate;
      if (!sensorData || !transactionDate) return;

      const transactionTime = new Date(transactionDate).toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      });

      const sensorValue = parseFloat(handleArr(settingNodes, sensorData));

      setDatachart((prevDataChart) => {
        const updatedData = [
          ...prevDataChart,
          {
            name: transactionTime,
            [setting_node_data_name]: sensorValue,
          },
        ];
        if (updatedData.length > 10) {
          updatedData.shift(); // ลบข้อมูลที่เก่าที่สุดถ้ามีมากกว่า 10 จุด
        }
        return updatedData;
      });
    }
  }, [socketData, settingNodes, setting_node_data_name]);

  return (
    <ResponsiveContainer width="100%" height={height}>
      <LineChart
        data={datachart}
        margin={{
          top: 5,
          right: 30,
          left: 0,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Line
          type="monotone"
          dataKey={setting_node_data_name}
          stroke={graph_color}
          strokeWidth={2}
          dot={{ r: 4 }}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default LineChartComponent;

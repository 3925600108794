import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { TOKEN } from "../../services/Constants/systems";
import { httpClient } from "../../services/Connection/httpclient";
import config from "../../config";

// Interface สำหรับ State
interface FloodgateTransationReportState {
    data: Array<Record<string, Record<string, string>>> | null;
    optional: {
        totalRowsPerPage: number;
        totalCount: number;
    };
    isLoading: boolean;
    isError: boolean;
    errorMessage: string | null;
}

const initialState: FloodgateTransationReportState = {
    data: null,
    optional: {
        totalRowsPerPage: 0,
        totalCount: 0,
    },
    isLoading: false,
    isError: false,
    errorMessage: null,
};

export const fetchTransationReport1 = createAsyncThunk<
    {
        data: Array<Record<string, Record<string, string>>>;
        optional: { totalRowsPerPage: number; totalCount: number };
    },
    {
        floodgate_id: string;
        number_of_rows: string;
        page: string;
        startdate: string;
        enddate: string;
    },
    { rejectValue: string }
>(
    "transationReport/fetchTransationReport1",
    async ({ floodgate_id, number_of_rows, page, startdate, enddate }, { rejectWithValue }) => {
        try {
            const token = localStorage.getItem(TOKEN);
            if (!token) {
                return rejectWithValue("Token is missing");
            }

            const req = {
                floodgateId: floodgate_id,
                numberofrows: number_of_rows,
                page: page,
                transaction_startdate: startdate,
                transaction_enddate: enddate,
            };

            const response = await httpClient.post(
                `${config.floodgate.floodgate_url}/floodgate/transation/monitor`,
                req,
                {
                    headers: {
                        authorization: token,
                        "Content-Type": "application/json",
                    },
                }
            );

            if (response.data.status) {
                const { data, optional } = response.data;
                return { data, optional };
            } else {
                return rejectWithValue(response.data.message || "ไม่สามารถดึงข้อมูลได้");
            }
        } catch (error: any) {
            return rejectWithValue(error.message || "เกิดข้อผิดพลาด");
        }
    }
);

// สร้าง Slice
const transationReportSlice1 = createSlice({
    name: "transationReport",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchTransationReport1.pending, (state) => {
                state.isLoading = true;
                state.isError = false;
                state.errorMessage = null;
            })
            .addCase(
                fetchTransationReport1.fulfilled,
                (
                    state,
                    action: PayloadAction<{
                        data: Array<Record<string, Record<string, string>>>;
                        optional: { totalRowsPerPage: number; totalCount: number };
                    }>
                ) => {
                    state.isLoading = false;
                    state.data = action.payload.data;
                    state.optional = action.payload.optional;
                }
            )
            .addCase(
                fetchTransationReport1.rejected,
                (state, action: PayloadAction<string | undefined>) => {
                    state.isLoading = false;
                    state.isError = true;
                    state.errorMessage = action.payload || "เกิดข้อผิดพลาด";
                }
            );
    },
});

// Selector สำหรับดึงข้อมูล transation report จาก store
export const transationReportSelector1 = (store: RootState) => store.transationReport1;

// Export reducer
export default transationReportSlice1.reducer;
import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { HandleTransparentAccordion } from '../../layouts/HandleStyleComponents/HandleTransparentAccordion'; // Import the styled component
import { Typography } from '@mui/material';
import ManageFloodGateTable from './ManageFloodGateTable';
import ManageFloodGateCreateModal from './ManageFloodGateCreateModal';
import ManageFloodGateSchemaCreateModal from './ManageFloodGateSchemaCreateModal'; // Correct the import name
import ManageFloodGateSchemaDataModal from './ManageFloodGateSchemaDataModal';
import ManageFloodGateTypeDataModal from './ManageFloodGateTypeDataModal'; // Correct the import name for
import { GridSearchIcon } from '@mui/x-data-grid';

export default function ManageFloodGatePage() {
  // Manage modal open/close states with dynamic key names
  const [modalState, setModalState] = useState({
    floodgateCreate: false,
    floodgateSchemaCreate: false,
    floodgateSchemaData: false,
    floodgatetypeData: false, // แก้ไขจาก ProgramTemplateTypeData
  });

  // Utility function to handle opening/closing modals
  const handleModalOpen = (modalName: keyof typeof modalState) => () =>
    setModalState((prev) => ({ ...prev, [modalName]: true }));

  const handleModalClose = (modalName: keyof typeof modalState) => () =>
    setModalState((prev) => ({ ...prev, [modalName]: false }));

  return (
    <Box sx={{ width: '100%', p: 2 }}>
      <HandleTransparentAccordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6" fontWeight="bold" fontSize={18}>
            จัดการข้อมูลโครงการ
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box mb={2} display="flex" gap={2}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              onClick={handleModalOpen('floodgateCreate')}
            >
              เพิ่มข้อมูลโครงการ
            </Button>
            {/* <Button
              variant="contained"
              color="secondary"
              startIcon={<AddIcon />}
              onClick={handleModalOpen('floodgateSchemaCreate')}
            >
              เพิ่มข้อมูลกลุ่มโครงการ
            </Button> */}
            <Button
              variant="contained"
              color="secondary"
              startIcon={<GridSearchIcon />}
              onClick={handleModalOpen('floodgateSchemaData')}
            >
              ข้อมูลกลุ่มโครงการ
            </Button>
            <Button
              variant="contained"
              color="warning"
              startIcon={<GridSearchIcon />}
              onClick={handleModalOpen('floodgatetypeData')} // แก้ไข state ที่ใช้
            >
              ข้อมูลกลุ่มการแสดงผลและเซ็นเซอร์
            </Button>
          </Box>

          <Box sx={{ height: 500, width: '100%' }}>
            <ManageFloodGateTable />
          </Box>
        </AccordionDetails>
      </HandleTransparentAccordion>

      {/* Manage FloodGate Modals */}
      <ManageFloodGateCreateModal
        open={modalState.floodgateCreate}
        onClose={handleModalClose('floodgateCreate')}
      />
      <ManageFloodGateSchemaCreateModal
        open={modalState.floodgateSchemaCreate}
        onClose={handleModalClose('floodgateSchemaCreate')}
      />
      <ManageFloodGateSchemaDataModal
        open={modalState.floodgateSchemaData}
        onClose={handleModalClose('floodgateSchemaData')}
      />
      <ManageFloodGateTypeDataModal
        open={modalState.floodgatetypeData} // แก้ไขจาก 'floodgateSchemaData' เป็น 'programTemplateTypeData'
        onClose={handleModalClose('floodgatetypeData')}
      />
    </Box>
  );
}
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import config from "../../config";
import { httpClient } from "../../services/Connection/httpclient";
import { TOKEN } from "../../services/Constants/systems";

// Interface สำหรับ State
interface ControlFloodgateState {
    data: ApiResponse | null;
    isLoading: boolean;
    isError: boolean;
    errorMessage: string | null;
}

// Initial state
const initialState: ControlFloodgateState = {
    data: null,
    isLoading: false,
    isError: false,
    errorMessage: null,
};

// Interface สำหรับ floodgate_data
interface FloodgateData {
    "Data Return|EnterLockUnlock": string;
}

// Interface สำหรับ response ภายใน data
interface ResponseData {
    status: boolean;
    floodgate_id: string;
    floodgate_data: FloodgateData;
}

// Interface สำหรับ data ที่อยู่ใน root ของ response
interface Data {
    response: ResponseData;
}

// Interface สำหรับ root ของ response
interface ApiResponse {
    status: boolean;
    message: string;
    data: Data;
}

// Async thunk สำหรับการควบคุม floodgate
export const controlFloodgate = createAsyncThunk<
    ApiResponse, // เปลี่ยนเป็นชนิดข้อมูลที่ถูกต้อง
    { floodgate_id: string, setting_node_id: string, value: string }, // Input type ของ action
    { rejectValue: string }
>(
    "controlFloodgate/controlFloodgate",
    async ({ floodgate_id, setting_node_id, value }, { rejectWithValue }) => {
        try {
            const token = localStorage.getItem(TOKEN);
            if (!token) {
                return rejectWithValue("Token is missing");
            }

            const response = await httpClient.post(
                config.control.floodgate_control_url + config.control.floodgate_control,
                {
                    floodgate_id,
                    setting_node_id,
                    value
                },
                {
                    headers: {
                        'authorization': token,
                        'Content-Type': 'application/json',
                    },
                }
            );

            if (response.data.status) {
                return response.data as ApiResponse; // Return ค่าจาก API response โดยใช้ interface ApiResponse
            } else {
                return rejectWithValue(response.data.message);
            }
        } catch (error: any) {
            return rejectWithValue(error.message || "Unexpected error occurred");
        }
    }
);

// สร้าง Slice
const controlFloodgateSlice = createSlice({
    name: "controlFloodgate",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(controlFloodgate.pending, (state) => {
                state.isLoading = true;
                state.isError = false;
                state.errorMessage = null;
            })
            .addCase(
                controlFloodgate.fulfilled,
                (state, action: PayloadAction<ApiResponse>) => {
                    state.isLoading = false;
                    state.data = action.payload; // ใช้ข้อมูลจาก ApiResponse
                }
            )
            .addCase(
                controlFloodgate.rejected,
                (state, action: PayloadAction<string | undefined>) => {
                    state.isLoading = false;
                    state.isError = true;
                    state.errorMessage = action.payload || "An error occurred";
                }
            );
    },
});

// Selector สำหรับดึงข้อมูลจาก store
export const controlFloodgateSelector = (store: RootState) => store.controlFloodgate;

// Export reducer
export default controlFloodgateSlice.reducer;

import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import config from "../../config";
import { ManageFloodgateImageRequest } from "../../models/manageFloodgateImg/ManageFloodgateImageRequest.model";
import { ManageFloodgateImageResponse } from "../../models/manageFloodgateImg/ManageFloodgateImageResponse.model";
import { TOKEN } from "../../services/Constants/systems";
import { httpClient } from "../../services/Connection/httpclient";

// Interface สำหรับ State
interface ManageFloodgateImageState {
    status: boolean | null;
    message: string | null;
    isLoading: boolean;
    isError: boolean;
    errorMessage: string | null;
}

// Initial state
const initialState: ManageFloodgateImageState = {
    status: null,
    message: null,
    isLoading: false,
    isError: false,
    errorMessage: null,
};

// Async thunk สำหรับการเรียก API
export const manageFloodgateImage = createAsyncThunk<
    ManageFloodgateImageResponse,
    ManageFloodgateImageRequest,
    { rejectValue: string }
>("manageFloodgateImage/manageFloodgateImage", async (imageData, { rejectWithValue }) => {
    try {
        const token = localStorage.getItem(TOKEN);
        if (token) {
            const formData = new FormData();
            formData.append('imgfloodgate', imageData.imgfloodgate);
            formData.append('floodgateid', imageData.floodgateid);

            const response = await httpClient.post(
                config.floodgate.floodgate_url + config.floodgate.floodgate_manage_info_image,
                formData,
                {
                    headers: {
                        'authorization': token,
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );

            if (response.data.status) {
                return response.data;
            } else {
                return rejectWithValue(response.data.message);
            }
        } else {
            return rejectWithValue("Token is missing");
        }
    } catch (error: any) {
        return rejectWithValue(error.message || "เกิดข้อผิดพลาด หรือ session หมดอายุ กรุณาเข้าสู่ระบบใหม่อีกครั้ง");
    }
});

// สร้าง Slice
const manageFloodgateImageSlice = createSlice({
    name: "manageFloodgateImage",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(manageFloodgateImage.pending, (state) => {
                state.isLoading = true;
                state.isError = false;
                state.errorMessage = null;
            })
            .addCase(
                manageFloodgateImage.fulfilled,
                (state, action: PayloadAction<ManageFloodgateImageResponse>) => {
                    state.isLoading = false;
                    state.status = action.payload.status;
                    state.message = action.payload.message;
                }
            )
            .addCase(
                manageFloodgateImage.rejected,
                (state, action: PayloadAction<string | undefined>) => {
                    state.isLoading = false;
                    state.isError = true;
                    state.errorMessage = action.payload || "เกิดข้อผิดพลาด หรือ session หมดอายุ กรุณาเข้าสู่ระบบใหม่อีกครั้ง";
                }
            );
    },
});

export const manageFloodgateImageSelector = (store: RootState) => store.manageFloodgateImage;
export default manageFloodgateImageSlice.reducer;
import React, { useEffect, useState } from 'react';
import { Box, Button, Modal, Fade, Backdrop, Typography, CircularProgress } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import { DataGrid, GridColDef, GridRowId, GridRowSelectionModel, GridToolbarContainer } from '@mui/x-data-grid';
import Swal from 'sweetalert2';
import { useAppDispatch, useAppSelector } from '../../../stores/store';
import { manageFloodgateType, manageFloodgateTypeSelector } from '../../../stores/slices/ManageFloodgateTypeSlice';
import { ManageFloodgateTypeRequest, DeleteFloodgateTypeRequest } from '../../../models/manageFloodgateNodeType/ManageFloodgateTypeRequest.model';
import { ManageFloodgateTypeResponse } from '../../../models/manageFloodgateNodeType/ManageFloodgateTypeResponse.model';
import { floodgateManageAction } from '../../../services/Constants/enum/enum.model';
import ManageFloodGateTypeEditModal from './ManageFloodGateTypeEditModal';
import ManageFloodGateTypeCreateModal from './ManageFloodGateTypeCreateModal';

interface ModalProps {
    open: boolean;
    onClose: () => void;
}

const ManageFloodGateTypeDataModal: React.FC<ModalProps> = ({ open, onClose }) => {
    const dispatch = useAppDispatch();
    const { data, isLoading, isError, errorMessage } = useAppSelector(manageFloodgateTypeSelector);
    const [rows, setRows] = useState<ManageFloodgateTypeResponse['data']['response'][]>([]);
    const [selectedRowId, setSelectedRowId] = useState<GridRowId | null>(null);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [floodgateToEdit, setFloodgateToEdit] = useState<ManageFloodgateTypeResponse['data']['response'] | null>(null);

    useEffect(() => {
        if (open) {
            const request: ManageFloodgateTypeRequest = { action: floodgateManageAction.GET };
            dispatch(manageFloodgateType(request));
        }
    }, [dispatch, open]);

    useEffect(() => {
        if (data) {
            setRows(Array.isArray(data) ? data : [data]);
        }
    }, [data]);

    // Open the create modal
    const handleCreateClick = () => {
        setCreateModalOpen(true);
    };

    // Open the edit modal based on the selected row
    const handleEditClick = () => {
        const selectedFloodgate = rows.find((row) => row.setting_type_id === selectedRowId);
        if (selectedFloodgate) {
            setFloodgateToEdit(selectedFloodgate);
            setEditModalOpen(true);
        }
    };

    const handleDeleteClick = async () => {
        const floodgateToDelete = rows.find((row) => row.setting_type_id === selectedRowId);
        if (floodgateToDelete) {
            onClose(); // ปิด Modal ก่อนแสดง SweetAlert

            const result = await Swal.fire({
                title: 'คุณแน่ใจหรือไม่?',
                text: 'คุณต้องการลบข้อมูลนี้หรือไม่?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'ใช่, ลบเลย!',
                cancelButtonText: 'ยกเลิก',
            });

            if (result.isConfirmed) {
                try {
                    const deleteRequest: DeleteFloodgateTypeRequest = {
                        action: floodgateManageAction.DELETE,
                        setting_type_id: floodgateToDelete.setting_type_id,
                        setting_type_group: floodgateToDelete.setting_type_group,
                        setting_type_name: floodgateToDelete.setting_type_name,
                    };
                    await dispatch(manageFloodgateType(deleteRequest)).unwrap();
                    Swal.fire('ลบแล้ว!', 'ข้อมูลได้ถูกลบแล้ว.', 'success');
                    dispatch(manageFloodgateType({ action: floodgateManageAction.GET }));
                } catch (error: any) {
                    Swal.fire('เกิดข้อผิดพลาด', error.message, 'error');
                }
            }

            // เปิด Modal กลับหลังจาก SweetAlert ถูกยืนยันหรือยกเลิก
            onClose();
        }
    };

    const handleRowSelection = (rowSelectionModel: GridRowSelectionModel) => {
        setSelectedRowId(rowSelectionModel.length === 1 ? rowSelectionModel[0] : null);
    };

    // Close the create modal
    const handleCreateModalClose = () => {
        setCreateModalOpen(false);
        dispatch(manageFloodgateType({ action: floodgateManageAction.GET }));
    };

    // Close the edit modal
    const handleEditModalClose = () => {
        setEditModalOpen(false);
        setFloodgateToEdit(null);
        dispatch(manageFloodgateType({ action: floodgateManageAction.GET }));
    };

    // Toolbar with Add/Edit/Delete buttons
    const CustomToolbar = () => (
        <GridToolbarContainer>
            <Button
                startIcon={<AddIcon />}
                onClick={handleCreateClick}
                sx={{ mr: 2 }}
            >
                เพิ่ม
            </Button>
            <Button
                startIcon={<EditIcon />}
                onClick={handleEditClick}
                disabled={!selectedRowId}
                sx={{ mr: 2 }}
            >
                แก้ไข
            </Button>
            <Button
                startIcon={<DeleteIcon />}
                onClick={handleDeleteClick}
                disabled={!selectedRowId}
            >
                ลบ
            </Button>
        </GridToolbarContainer>
    );

    if (isLoading) return <CircularProgress />;
    if (isError) return <Typography>Error: {errorMessage}</Typography>;

    const handleClose = () => {
        onClose();
    };

    return (
        <Modal
            open={open}
            onClose={handleClose} // ปิด Modal เมื่อคลิกที่ backdrop
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{ timeout: 500 }}
        >
            <Fade in={open}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '50%',
                        maxHeight: '80vh',
                        bgcolor: 'background.paper',
                        borderRadius: 2,
                        boxShadow: 24,
                        p: 4,
                        overflowY: 'auto',
                    }}
                >
                    <DataGrid
                        rows={rows}
                        columns={[
                            { field: 'setting_type_group', headerName: 'กลุ่มการแสดงผลและเซ็นเซอร์', width: 300 },
                            { field: 'setting_type_name', headerName: 'ชื่อกลุ่มการแสดงผลและเซ็นเซอร์', width: 300 },
                        ]}
                        checkboxSelection
                        getRowId={(row) => row.setting_type_id}
                        onRowSelectionModelChange={handleRowSelection}
                        slots={{ toolbar: CustomToolbar }}
                    />

                    {/* Create Modal for adding new floodgate */}
                    <ManageFloodGateTypeCreateModal
                        open={createModalOpen}
                        onClose={handleCreateModalClose}
                    />

                    {/* Edit Modal for editing floodgate */}
                    <ManageFloodGateTypeEditModal
                        open={editModalOpen}
                        floodgate={floodgateToEdit}
                        onClose={handleEditModalClose}
                    />
                </Box>
            </Fade>
        </Modal>
    );
};

export default ManageFloodGateTypeDataModal;

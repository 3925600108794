import axios, { AxiosError, AxiosResponse, InternalAxiosRequestConfig } from "axios";
import { errorCodes } from "../Constants/enum/enum.model";
import store from "../../stores/store";
import { userLogout } from "../../stores/slices/LoginSlice";
import { toast } from "react-toastify";

const httpClient = axios.create();

const handleError = (error: AxiosError): Promise<any> => {
  let customError = {
    status: false,
    message: "",
    error: {
      code: 0,
      details: "",
    },
  };

  if (error.response) {
    if (process.env.NODE_ENV === 'development') {
      //console.log("dev mode response error", error);
    }

    customError = {
      status: false,
      message: "เกิดข้อผิดพลาด หรือ session หมดอายุ กรุณาเข้าสู่ระบบใหม่อีกครั้ง",
      error: {
        code: errorCodes.responseError,
        details: error.response?.statusText || "Unknown error",
      },
    };
  } else if (error.request) {
    if (process.env.NODE_ENV === 'development') {
      //console.log("dev mode error not response", error);
    }

    customError = {
      status: false,
      message: "error not response",
      error: {
        code: errorCodes.noResponse,
        details: "No response received from server",
      },
    };
  } else {
    if (process.env.NODE_ENV === 'development') {
      //console.log("dev mode request setup error", error);
    }

    customError = {
      status: false,
      message: "Request setup error",
      error: {
        code: errorCodes.requestSetupError,
        details: error.message || "Unknown setup error",
      },
    };
  }
  store.dispatch(userLogout());  // ทำการ logout โดย dispatch action
  toast.error('เกิดข้อผิดพลาด หรือ session หมดอายุ กรุณาเข้าสู่ระบบใหม่อีกครั้ง');

  // ใช้ setTimeout เพื่อให้ toast แสดงก่อนการ redirect
  setTimeout(() => {
    window.location.href = '/login';  // นำผู้ใช้ไปที่หน้า login
  }, 3000); // รอ 3 วินาที (3000 มิลลิวินาที) ก่อนเปลี่ยนเส้นทาง


  return Promise.reject(customError);
};

httpClient.interceptors.request.use(
  (config: InternalAxiosRequestConfig<any>) => {
    return config;
  },
  (error: AxiosError) => {
    return Promise.reject(error);
  }
);

httpClient.interceptors.response.use(
  (response: AxiosResponse) => {
    return response;
  },
  (error: AxiosError) => {
    return handleError(error);
  }
);

export { httpClient };
// ProtectedRoutes.tsx
import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAppSelector } from "../../stores/store";
import { loginSelector } from "../../stores/slices/LoginSlice";

const ProtectedRoutes = () => {
  const loginReducer = useAppSelector(loginSelector);
  const auth = loginReducer.result?.status;

  // ถ้า auth สำเร็จ ให้แสดงผลเส้นทางภายใน ProtectedRoutes ถ้าไม่สำเร็จ ให้ไปที่ /guest
  return auth ? <Outlet /> : <Navigate to="/guest" />;
};

export default ProtectedRoutes;

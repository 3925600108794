import React, { useState, useEffect } from 'react';
import { Box, Button, TextField, Typography, Modal, Fade, Backdrop, Checkbox, FormControlLabel, MenuItem, Grid } from '@mui/material';
import { Autocomplete } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../stores/store';
import { AddFloodgateNodeRequest, UpdateFloodgateNodeRequest } from '../../../models/manageFloodgateNode/ManageFloodgateNodeRequest.model';
import { manageFloodgateNode } from '../../../stores/slices/ManageFloodgateNodeSlice';
import { SettingNode } from '../../../models/floodgateDetail/FloodgateDetail.model';
import { floodgateManageAction } from '../../../services/Constants/enum/enum.model';
import { manageFloodgateType, manageFloodgateTypeSelector } from '../../../stores/slices/ManageFloodgateTypeSlice';
import { floodgateDetailSelector, fetchFloodgateDetail } from '../../../stores/slices/FloodgateDetailSlice'; // นำเข้า fetchFloodgateDetail
import { floodgateDataTypes } from '../../../services/Constants/enum/enum.model';
import { toast } from 'react-toastify'; // Import react-toastify
import 'react-toastify/dist/ReactToastify.css';

interface AddEditFloodgateNodeModalProps {
    open: boolean;
    onClose: () => void;
    floodgateId: string;
    editData?: SettingNode | null;
}

const ManageProgramTemplateAddEditModal: React.FC<AddEditFloodgateNodeModalProps> = ({ open, onClose, floodgateId, editData }) => {
    const dispatch = useAppDispatch();
    const { data: floodgateSettingTypeData, isLoading: isFloodgateSettingTypeLoading } = useAppSelector(manageFloodgateTypeSelector);
    const { data: floodgateDetailData } = useAppSelector(floodgateDetailSelector);

    const [formData, setFormData] = useState<AddFloodgateNodeRequest | UpdateFloodgateNodeRequest>({
        setting_type_id: '',
        floodgate_id: floodgateId,
        setting_node_data_display_main: false,
        setting_node_data_key: '',
        setting_node_data_name: '',
        setting_node_data_min: '',
        setting_node_data_max: '',
        setting_node_data_unit: '',
        setting_node_type_communication: false,
        setting_node_data_type: '',
        setting_node_sequence: '',
        setting_node_discription: '',
        action: floodgateManageAction.ADD,
    });

    const [errors, setErrors] = useState<{ [key: string]: string }>({});

    useEffect(() => {
        if (editData) {
            setFormData({
                action: floodgateManageAction.UPDATE,
                setting_node_id: editData.setting_node_id.toString(),
                setting_type_id: editData.setting_type_id.toString(),
                floodgate_id: floodgateId.toString(),
                setting_node_data_display_main: editData.setting_node_data_display_main,
                setting_node_data_key: editData.setting_node_data_key.toString(),
                setting_node_data_name: editData.setting_node_data_name.toString(),
                setting_node_data_min: editData.setting_node_data_min.toString(),
                setting_node_data_max: editData.setting_node_data_max.toString(),
                setting_node_data_unit: editData.setting_node_data_unit.toString(),
                setting_node_type_communication: editData.setting_node_type_communication,
                setting_node_data_type: editData.setting_node_data_type.toString(),
                setting_node_sequence: editData.setting_node_sequence.toString(),
                setting_node_discription: editData.setting_node_discription.toString(),
            });
        }
        dispatch(manageFloodgateType({ action: floodgateManageAction.GET }));
    }, [editData, floodgateId, dispatch]);

    const uniqueKeys = Array.from(
        new Set(floodgateDetailData?.setting_nodes.map((node) => node.setting_node_data_key))
    ).filter(Boolean);

    const getDataTypeLabel = (value: number) => {
        switch (value) {
            case floodgateDataTypes.int:
                return 'Integer';
            case floodgateDataTypes.arrayint:
                return 'Array of Integers';
            case floodgateDataTypes.string:
                return 'String';
            case floodgateDataTypes.boolean:
                return 'Boolean';
            default:
                return 'Unknown';
        }
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
        setErrors((prev) => ({ ...prev, [name]: '' }));

        if (name === 'setting_node_data_type' && value !== floodgateDataTypes.arrayint.toString()) {
            setFormData((prev) => ({ ...prev, setting_node_sequence: '0' }));
        }
    };

    const handleAutocompleteChange = (event: any, newValue: string | SettingNode | null) => {
        if (typeof newValue === 'string') {
            setFormData((prev) => ({ ...prev, setting_node_data_key: newValue }));
        } else if (newValue && 'setting_node_data_key' in newValue) {
            setFormData((prev) => ({ ...prev, setting_node_data_key: newValue.setting_node_data_key }));
        } else {
            setFormData((prev) => ({ ...prev, setting_node_data_key: '' }));
        }
    };

    const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = e.target;
        setFormData((prev) => ({ ...prev, [name]: checked }));
    };

    const validate = () => {
        let tempErrors: { [key: string]: string } = {};

        if (!formData.setting_type_id) tempErrors.setting_type_id = 'กรุณาเลือกประเภทการตั้งค่า';
        if (!formData.setting_node_data_key) tempErrors.setting_node_data_key = 'กรุณากรอกค่า Variable';
        if (!formData.setting_node_data_name) tempErrors.setting_node_data_name = 'กรุณากรอกชื่อการแสดงผลและเซ็นเซอร์';
        if (!formData.setting_node_data_min) tempErrors.setting_node_data_min = 'กรุณากรอกค่าต่ำสุด';
        if (!formData.setting_node_data_max) tempErrors.setting_node_data_max = 'กรุณากรอกค่าสูงสุด';
        if (!formData.setting_node_data_unit) tempErrors.setting_node_data_unit = 'กรุณากรอกหน่วย';
        if (
            Number(formData.setting_node_data_type) !== 0 &&
            Number(formData.setting_node_data_type) !== 1 &&
            Number(formData.setting_node_data_type) !== 2 &&
            Number(formData.setting_node_data_type) !== 3
        ) tempErrors.setting_node_data_type = 'กรุณาเลือกชนิดข้อมูล';
        if (!formData.setting_node_sequence) tempErrors.setting_node_sequence = 'กรุณากรอกลำดับข้อมูล';
        if (!formData.setting_node_discription) tempErrors.setting_node_discription = 'กรุณากรอกรายละเอียด';

        if (editData) {
            if (!(String(editData.setting_node_data_key) === String(formData.setting_node_data_key) &&
                Number(editData.setting_node_data_type) === Number(formData.setting_node_data_type) &&
                Number(editData.setting_node_sequence) === Number(formData.setting_node_sequence))
            ) {
                if (Number(formData.setting_node_data_type) === Number(floodgateDataTypes.arrayint)) {
                    const existingNodes = floodgateDetailData?.setting_nodes.filter(
                        (node) =>
                            String(node.setting_node_data_key) === String(formData.setting_node_data_key) &&
                            Number(node.setting_node_data_type) === Number(floodgateDataTypes.arrayint) &&
                            Number(node.setting_node_sequence) === Number(formData.setting_node_sequence)
                    );
                    if (existingNodes && existingNodes.length > 0) {
                        tempErrors.setting_node_sequence = 'ลำดับข้อมูลไม่สามารถซ้ำกันได้';
                    }
                }

                if (Number(formData.setting_node_data_type) !== Number(floodgateDataTypes.arrayint) && Number(formData.setting_node_sequence) !== 0) {
                    tempErrors.setting_node_sequence = 'ลำดับข้อมูลต้องเป็น 0 สำหรับชนิดข้อมูลนี้';
                }
            }
        } else {
            if (Number(formData.setting_node_data_type) === Number(floodgateDataTypes.arrayint)) {
                const existingNodes = floodgateDetailData?.setting_nodes.filter(
                    (node) =>
                        String(node.setting_node_data_key) === String(formData.setting_node_data_key) &&
                        Number(node.setting_node_data_type) === Number(floodgateDataTypes.arrayint) &&
                        Number(node.setting_node_sequence) === Number(formData.setting_node_sequence)
                );
                if (existingNodes && existingNodes.length > 0) {
                    tempErrors.setting_node_sequence = 'ลำดับข้อมูลไม่สามารถซ้ำกันได้';
                }
            }

            if (Number(formData.setting_node_data_type) !== Number(floodgateDataTypes.arrayint) && Number(formData.setting_node_sequence) !== 0) {
                tempErrors.setting_node_sequence = 'ลำดับข้อมูลต้องเป็น 0 สำหรับชนิดข้อมูลนี้';
            }
        }

        setErrors(tempErrors);
        return Object.keys(tempErrors).length === 0;
    };

    const handleSubmit = async () => {
        if (validate()) {
            try {
                if (formData.action === floodgateManageAction.ADD) {
                    await dispatch(manageFloodgateNode(formData as AddFloodgateNodeRequest)).unwrap();
                    toast.success('เพิ่มข้อมูลสำเร็จ');
                } else {
                    await dispatch(manageFloodgateNode(formData as UpdateFloodgateNodeRequest)).unwrap();
                    toast.success('แก้ไขข้อมูลสำเร็จ');
                }
                // Reload data only after a successful submit
                dispatch(fetchFloodgateDetail(floodgateId));
                // Clear the form and close modal
                handleClose();
            } catch (error) {
                toast.error('เกิดข้อผิดพลาดในการบันทึกข้อมูล');
            }
        }
    };

    const handleClose = () => {
        setFormData({
            setting_type_id: '',
            floodgate_id: floodgateId,
            setting_node_data_display_main: false,
            setting_node_data_key: '',
            setting_node_data_name: '',
            setting_node_data_min: '',
            setting_node_data_max: '',
            setting_node_data_unit: '',
            setting_node_type_communication: false,
            setting_node_data_type: '',
            setting_node_sequence: '',
            setting_node_discription: '',
            action: floodgateManageAction.ADD,
        });
        setErrors({});
        onClose();
    };

    return (
        <Modal open={open} onClose={handleClose} closeAfterTransition BackdropComponent={Backdrop} BackdropProps={{ timeout: 500 }}>
            <Fade in={open}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '80%',
                        maxHeight: '80vh',
                        bgcolor: 'background.paper',
                        borderRadius: 2,
                        boxShadow: 24,
                        p: 4,
                        overflowY: 'auto',
                    }}
                >
                    <Typography variant="h6" gutterBottom>
                        {editData ? 'แก้ไขข้อมูลการแสดงผลและเซ็นเซอร์' : 'เพิ่มข้อมูลการแสดงผลและเซ็นเซอร์'}
                    </Typography>

                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={formData.setting_node_data_display_main}
                                        onChange={handleCheckboxChange}
                                        name="setting_node_data_display_main"
                                    />
                                }
                                label="แสดงผลหน้าหลัก"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={formData.setting_node_type_communication}
                                        onChange={handleCheckboxChange}
                                        name="setting_node_type_communication"
                                    />
                                }
                                label="T :: Request To OPC-UA(Monitor) / F :: Display"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                select
                                fullWidth
                                label="ประเภทการตั้งค่า"
                                name="setting_type_id"
                                value={formData.setting_type_id}
                                onChange={handleChange}
                                margin="normal"
                                error={!!errors.setting_type_id}
                                helperText={errors.setting_type_id}
                                disabled={isFloodgateSettingTypeLoading}
                            >
                                {Array.isArray(floodgateSettingTypeData) && floodgateSettingTypeData.map((fSettingTypeData) => (
                                    <MenuItem key={fSettingTypeData.setting_type_id} value={fSettingTypeData.setting_type_id}>
                                        {fSettingTypeData.setting_type_name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Autocomplete
                                freeSolo
                                options={uniqueKeys || []}
                                getOptionLabel={(option) => option}
                                value={formData.setting_node_data_key}
                                onChange={handleAutocompleteChange}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        fullWidth
                                        label="ค่า Variable"
                                        name="setting_node_data_key"
                                        onChange={(e) => handleAutocompleteChange(null, e.target.value)}
                                        margin="normal"
                                        error={!!errors.setting_node_data_key}
                                        helperText={errors.setting_node_data_key}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                fullWidth
                                label="ชื่อการแสดงผลและเซ็นเซอร์"
                                name="setting_node_data_name"
                                value={formData.setting_node_data_name}
                                onChange={handleChange}
                                margin="normal"
                                error={!!errors.setting_node_data_name}
                                helperText={errors.setting_node_data_name}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label="ค่าต่ำสุด"
                                name="setting_node_data_min"
                                type="number"
                                value={formData.setting_node_data_min}
                                onChange={handleChange}
                                margin="normal"
                                error={!!errors.setting_node_data_min}
                                helperText={errors.setting_node_data_min}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label="ค่าสูงสุด"
                                name="setting_node_data_max"
                                type="number"
                                value={formData.setting_node_data_max}
                                onChange={handleChange}
                                margin="normal"
                                error={!!errors.setting_node_data_max}
                                helperText={errors.setting_node_data_max}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                fullWidth
                                label="หน่วย"
                                name="setting_node_data_unit"
                                value={formData.setting_node_data_unit}
                                onChange={handleChange}
                                margin="normal"
                                error={!!errors.setting_node_data_unit}
                                helperText={errors.setting_node_data_unit}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                select
                                fullWidth
                                label="ชนิดข้อมูล"
                                name="setting_node_data_type"
                                value={formData.setting_node_data_type}
                                onChange={handleChange}
                                margin="normal"
                                error={!!errors.setting_node_data_type}
                                helperText={errors.setting_node_data_type}
                            >
                                {Object.values(floodgateDataTypes).filter(value => typeof value === 'number').map((value) => (
                                    <MenuItem key={value} value={value}>
                                        {getDataTypeLabel(value as number)}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                fullWidth
                                label="ลำดับข้อมูล"
                                name="setting_node_sequence"
                                type="number"
                                value={formData.setting_node_sequence}
                                onChange={handleChange}
                                margin="normal"
                                error={!!errors.setting_node_sequence}
                                helperText={errors.setting_node_sequence}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                fullWidth
                                label="รายละเอียด"
                                name="setting_node_discription"
                                value={formData.setting_node_discription}
                                onChange={handleChange}
                                margin="normal"
                                error={!!errors.setting_node_discription}
                                helperText={errors.setting_node_discription}
                            />
                        </Grid>
                    </Grid>
                    <Button variant="contained" color="primary" onClick={handleSubmit} sx={{ mt: 2 }}>
                        {editData ? 'บันทึกการเปลี่ยนแปลง' : 'เพิ่มข้อมูล'}
                    </Button>
                </Box>
            </Fade>
        </Modal>
    );
};

export default ManageProgramTemplateAddEditModal;
import React, { useEffect, useState } from 'react';
import { Box, Modal, Fade, Backdrop, Typography, Button, TablePagination, TextField } from '@mui/material';
import { DataGrid, GridColDef, GridToolbarContainer, GridToolbarContainerProps } from '@mui/x-data-grid';
import { useAppDispatch, useAppSelector } from '../../../../stores/store';
import { fetchTransationReport, transationReportSelector } from '../../../../stores/slices/FloodgateTransationReportSlice';
import { fetchTransationReport1, transationReportSelector1 } from '../../../../stores/slices/FloodgateTransationReportSlice1';
import { DateTimePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { FloodgateDetail } from '../../../../models/monitor/MonitorDetailResponse.model';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { toast } from 'react-toastify';

interface ModalProps {
    open: boolean;
    floodgateID: string;
    onClose: () => void;
    dataMonitorDetail: FloodgateDetail | null;
}

const MonitorTransactionModal: React.FC<ModalProps> = ({ open, floodgateID, onClose, dataMonitorDetail }) => {
    const dispatch = useAppDispatch();
    const { data: monitorTxnData, optional, isLoading, isError, errorMessage } = useAppSelector(transationReportSelector);
    const { optional: optional1 } = useAppSelector(transationReportSelector1);
    const [columns, setColumns] = useState<GridColDef[]>([]);
    const [rows, setRows] = useState<any[]>([]);
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 100,
    });

    // State สำหรับช่วงเวลาที่เลือก
    const [startDate, setStartDate] = useState<Date | null>(new Date());
    const [endDate, setEndDate] = useState<Date | null>(new Date());

    useEffect(() => {
        if (startDate && endDate) {
            const formatDate = (date: Date) => {
                const year = date.getFullYear();
                const month = String(date.getMonth() + 1).padStart(2, '0');
                const day = String(date.getDate()).padStart(2, '0');
                const hours = String(date.getHours()).padStart(2, '0');
                const minutes = String(date.getMinutes()).padStart(2, '0');
                const seconds = String(date.getSeconds()).padStart(2, '0');
                return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
            };

            dispatch(fetchTransationReport({
                floodgate_id: floodgateID || "",
                number_of_rows: `${paginationModel.pageSize}`,
                page: `${paginationModel.page + 1}`,
                startdate: formatDate(startDate),
                enddate: formatDate(endDate),
            }));
        }
    }, [floodgateID, paginationModel, dispatch, startDate, endDate]);

    useEffect(() => {
        if (monitorTxnData && monitorTxnData.length > 0) {
            const dynamicColumns: GridColDef[] = [
                {
                    field: 'floodgate_transaction_createdate',
                    headerName: 'Transaction Date',
                    width: 200,
                }
            ];
            const rowData: any[] = [];

            monitorTxnData.forEach((item, index) => {
                const floodgateKey = Object.keys(item)[0];
                const floodgateData = item[floodgateKey];

                Object.keys(floodgateData).forEach((key) => {
                    if (key !== 'floodgate_transaction_createdate') {
                        if (!dynamicColumns.some((col) => col.field === key)) {
                            dynamicColumns.push({
                                field: key,
                                headerName: key,
                                width: 200,
                                renderCell: (params) => {
                                    if (typeof params.value === 'string' && params.value.includes(',')) {
                                        const values = params.value.split(',');
                                        return values.map((val, index) => <div key={index}>{val}</div>);
                                    }
                                    return params.value;
                                }
                            });
                        }
                    }
                });

                const row = { id: `${floodgateKey}-${index}`, ...floodgateData };
                rowData.push(row);
            });

            setColumns(dynamicColumns);
            setRows(rowData);
        }
    }, [monitorTxnData, dataMonitorDetail]);

    const formatFileName = (startDate: Date | null, endDate: Date | null): string => {
        const formatDate = (date: Date | null) => {
            if (!date) return 'unknown';
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            const hours = String(date.getHours()).padStart(2, '0');
            const minutes = String(date.getMinutes()).padStart(2, '0');
            const seconds = String(date.getSeconds()).padStart(2, '0');
            return `${year}${month}${day}_${hours}${minutes}${seconds}`;
        };

        return `transaction_report_${formatDate(startDate)}_to_${formatDate(endDate)}.xlsx`;
    };

    const handleDownloadExcel = () => {
        const ws = XLSX.utils.json_to_sheet(rows);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Data');
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: 'application/octet-stream' });

        const fileName = formatFileName(startDate, endDate);
        saveAs(data, fileName);
    };

    const handleDownloadExcelAll = async () => {
        if (!optional?.totalCount) {
            toast.error("No data available to export.");
            return;
        }

        const totalRows = optional.totalCount;
        const rowsPerPage = 100;
        const totalPages = Math.ceil(totalRows / rowsPerPage);
        const allData: any[] = [];

        toast.info("Starting to generate the file...");

        for (let page = 1; page <= totalPages; page++) {
            try {
                if (startDate && endDate) {
                    const formatDate = (date: Date) => {
                        const year = date.getFullYear();
                        const month = String(date.getMonth() + 1).padStart(2, '0');
                        const day = String(date.getDate()).padStart(2, '0');
                        const hours = String(date.getHours()).padStart(2, '0');
                        const minutes = String(date.getMinutes()).padStart(2, '0');
                        const seconds = String(date.getSeconds()).padStart(2, '0');
                        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
                    };

                    const result = await dispatch(fetchTransationReport1({
                        floodgate_id: floodgateID || "",
                        number_of_rows: `${rowsPerPage}`,
                        page: `${page}`,
                        startdate: formatDate(startDate),
                        enddate: formatDate(endDate),
                    })).unwrap();

                    if (result.data) {
                        allData.push(...result.data);
                    }
                }

                toast.info(`Loaded page ${page} of ${totalPages}`);
            } catch (error) {
                console.error(error);
                break;
            }
        }

        if (allData.length > 0) {
            const dynamicColumns: GridColDef[] = [
                {
                    field: 'floodgate_transaction_createdate',
                    headerName: 'Transaction Date',
                    width: 200,
                }
            ];
            const rowData: any[] = [];

            // ปั่นข้อมูล allData ให้เหมือนกับ monitorTxnData
            allData.forEach((item, index) => {
                const floodgateKey = Object.keys(item)[0];
                const floodgateData = item[floodgateKey];

                Object.keys(floodgateData).forEach((key) => {
                    if (key !== 'floodgate_transaction_createdate') {
                        if (!dynamicColumns.some((col) => col.field === key)) {
                            dynamicColumns.push({
                                field: key,
                                headerName: key,
                                width: 200,
                            });
                        }
                    }
                });

                const row = { id: `${floodgateKey}-${index}`, ...floodgateData };
                rowData.push(row);
            });

            // สร้างไฟล์ Excel จาก rowData
            const ws = XLSX.utils.json_to_sheet(rowData);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, 'All Data');
            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            const data = new Blob([excelBuffer], { type: 'application/octet-stream' });

            const fileName = `all_data_${formatFileName(startDate, endDate)}`;
            saveAs(data, fileName);
            toast.success("File generated successfully!");
        }
    };


    const CustomToolbar = (props: GridToolbarContainerProps) => (
        <GridToolbarContainer {...props}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateTimePicker
                    label="Start Date"
                    value={startDate}
                    onChange={(newValue) => setStartDate(newValue)}
                    format='yyyy-MM-dd HH:mm:ss'
                    views={['year', 'month', 'day', 'hours', 'minutes', 'seconds']}
                    ampm={false}
                    sx={{ m: 2 }}
                />

                <DateTimePicker
                    label="End Date"
                    value={endDate}
                    onChange={(newValue) => setEndDate(newValue)}
                    format='yyyy-MM-dd HH:mm:ss'
                    views={['year', 'month', 'day', 'hours', 'minutes', 'seconds']}
                    ampm={false}
                    sx={{ m: 2 }}
                />
            </LocalizationProvider>

            <Button
                variant="contained"
                color="primary"
                onClick={handleDownloadExcel}
                sx={{ ml: 2 }}
            >
                Download Excel
            </Button>
            <Button
                variant="contained"
                color="secondary"
                onClick={handleDownloadExcelAll}
                sx={{ ml: 2 }}
            >
                Download Excel All
            </Button>
        </GridToolbarContainer>
    );


    const DataGridPagination = () => (
        <TablePagination
            component="div"
            count={optional.totalCount || 0}
            page={paginationModel.page}
            onPageChange={(_, newPage) =>
                setPaginationModel((prev) => ({
                    ...prev,
                    page: newPage,
                }))
            }
            rowsPerPage={paginationModel.pageSize}
            onRowsPerPageChange={(event) =>
                setPaginationModel((prev) => ({
                    ...prev,
                    pageSize: parseInt(event.target.value, 10),
                }))
            }
        />
    );

    if (isLoading) return <Typography>Loading...</Typography>;
    if (isError) return <Typography>Error: {errorMessage}</Typography>;

    return (
        <Modal
            open={open}
            onClose={onClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{ timeout: 500 }}
        >
            <Fade in={open}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '80%',
                        minHeight: '80vh',
                        maxHeight: '80vh',
                        bgcolor: 'background.paper',
                        borderRadius: 2,
                        boxShadow: 24,
                        p: 4,
                        overflowY: 'auto',
                    }}
                >
                    <Typography variant="h6" gutterBottom>
                        ข้อมูล transaction
                    </Typography>
                    <DataGrid
                        rows={rows}
                        columns={columns}
                        getRowId={(row) => row.id}
                        paginationModel={paginationModel}
                        onPaginationModelChange={(newModel) => setPaginationModel(newModel)}
                        pagination
                        paginationMode="server"
                        rowCount={optional.totalCount || 0}
                        slots={{ toolbar: CustomToolbar, pagination: DataGridPagination }}
                        autoHeight={false}
                        sx={{
                            maxHeight: '60vh',
                            overflowY: 'auto',
                        }}
                    />
                </Box>
            </Fade>
        </Modal>
    );
};

export default MonitorTransactionModal;
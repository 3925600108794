import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { ManageUserInfoRequest, ManageUserInfoResponse } from '../../models/userInfo/ManageUserInfo.model';
import { TOKEN } from '../../services/Constants/systems';
import config from '../../config';
import { httpClient } from '../../services/Connection/httpclient';

interface ManageUserInfoState {
    data: ManageUserInfoResponse | null;
    isLoading: boolean;
    isError: boolean;
    errorMessage: string | null;
}

const initialState: ManageUserInfoState = {
    data: null,
    isLoading: false,
    isError: false,
    errorMessage: null,
};

export const manageUserInfo = createAsyncThunk<
    ManageUserInfoResponse,
    ManageUserInfoRequest,
    { rejectValue: { status: boolean; message: string } }
>('manageUserInfo', async (requestData, { rejectWithValue }) => {
    try {
        const token = localStorage.getItem(TOKEN);
        if (token) {
            const response = await httpClient.post<ManageUserInfoResponse>(
                config.user_info.user_url + config.user_info.user_register,
                requestData,
                {
                    headers: {
                        authorization: token,
                    },
                }
            );

            const { status, data } = response.data;
            if (!status || !data.response.status) {
                return rejectWithValue({
                    status: false,
                    message: 'ทำรายการผิดพลาดกรุณาตรวจสอบข้อมูล',
                });
            }

            return response.data;
        } else {
            return rejectWithValue({
                status: false,
                message: 'ทำรายการผิดพลาดกรุณาตรวจสอบข้อมูล',
            });
        }
    } catch (error: any) {
        return rejectWithValue({
            status: false,
            message: error.message || 'ทำรายการผิดพลาดกรุณาตรวจสอบข้อมูล',
        });
    }
});

const manageUserInfoSlice = createSlice({
    name: 'manageUserInfo',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(manageUserInfo.pending, (state) => {
            state.isLoading = true;
            state.isError = false;
            state.errorMessage = null;
        });
        builder.addCase(manageUserInfo.fulfilled, (state, action) => {
            state.isLoading = false;
            state.data = action.payload;
        });
        builder.addCase(manageUserInfo.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.errorMessage =
                action.payload && typeof action.payload === 'object' && 'message' in action.payload
                    ? (action.payload as { message: string }).message
                    : 'เกิดข้อผิดพลาด หรือ session หมดอายุ กรุณาเข้าสู่ระบบใหม่อีกครั้ง';
        });
    },
});

export const manageUserInfoSelector = (state: RootState) => state.manageUserInfo;
export default manageUserInfoSlice.reducer;

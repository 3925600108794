import React, { useState } from 'react';
import { Box, Button, TextField, Typography, Modal, Fade, Backdrop, Grid, Autocomplete } from '@mui/material';
import { useAppDispatch } from '../../../stores/store';
import { manageFloodGateNodeDisplay } from '../../../stores/slices/ManageFloodgateNodeTypeDisplaySlice';
import { floodgateManageAction } from '../../../services/Constants/enum/enum.model';
import { ManageFloodgateNodeTypeDisplayRequest } from '../../../models/manageFloodgateNodeTypeDisplay/ManageFloodgateNodeTypeDisplayRequest.model';
import { NodetypeInputAndDisplay } from '../../../models/monitor/MonitorDetailResponse.model';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

interface AddModalProps {
    open: boolean;
    onClose: () => void;
    dataDisplay: NodetypeInputAndDisplay;
    nodetypeInputAndDisplay: NodetypeInputAndDisplay[];
    nodetypeInputAndDisplayShowDroupDown: NodetypeInputAndDisplay[];
}

const ManageProgramTemplateDisplayUpdateModal: React.FC<AddModalProps> = ({ open, onClose, dataDisplay, nodetypeInputAndDisplay, nodetypeInputAndDisplayShowDroupDown }) => {
    const dispatch = useAppDispatch();

    const [formData, setFormData] = useState<ManageFloodgateNodeTypeDisplayRequest>({
        type_input_and_display_id: dataDisplay.type_input_and_display_id,
        setting_node_id: dataDisplay.setting_node_id,
        is_control: dataDisplay.is_control,
        is_btn: dataDisplay.is_btn,
        btn_value: dataDisplay.btn_value,
        btn_name: dataDisplay.btn_name,
        btn_color: dataDisplay.btn_color,
        is_text_input: dataDisplay.is_text_input,
        text_input_name: dataDisplay.text_input_name,
        is_monitor: dataDisplay.is_monitor,
        is_status_light: dataDisplay.is_status_light,
        status_light_value: dataDisplay.status_light_value,
        status_light_name: dataDisplay.status_light_name,
        status_light_color: dataDisplay.status_light_color,
        is_graph: dataDisplay.is_graph,
        graph_color: dataDisplay.graph_color,
        is_img: dataDisplay.is_img,
        img_group_name: dataDisplay.img_group_name,
        display_group: dataDisplay.display_group,
    });

    const [errors, setErrors] = useState<{ [key: string]: string }>({});

    const validate = () => {
        let tempErrors: { [key: string]: string } = {};

        if (!formData.display_group) tempErrors.display_group = 'กรุณากรอกชื่อกลุ่มที่จะแสดง';

        setErrors(tempErrors);
        return Object.keys(tempErrors).length === 0;
    };

    const canSubmit = () => {
        return (formData.is_control && (formData.is_text_input || formData.is_btn)) ||
            (formData.is_monitor && (formData.is_img || formData.is_graph || formData.is_status_light));
    };

    const handleSubmit = async () => {
        if (validate()) {
            try {
                formData.display_group = formData.display_group == '' ? 'NA' : formData.display_group;

                await dispatch(manageFloodGateNodeDisplay({
                    action: floodgateManageAction.UPDATE,
                    body: formData
                })).unwrap();

                toast.success('แก้ไขข้อมูลสำเร็จ');
                onClose();
            } catch (error) {
                toast.error('เกิดข้อผิดพลาดในการบันทึกข้อมูล');
            }
        }
    };

    const uniqueKeys = Array.from(
        new Set(nodetypeInputAndDisplayShowDroupDown.map((nodetypeInputAndDisplayShowDroupDown) => nodetypeInputAndDisplayShowDroupDown.display_group))
    ).filter(Boolean);

    const handleAutocompleteChange = (event: any, newValue: string | ManageFloodgateNodeTypeDisplayRequest | null) => {
        if (typeof newValue === 'string') {
            setFormData((prev) => ({ ...prev, display_group: newValue }));
        } else if (newValue && 'display_group' in newValue) {
            setFormData((prev) => ({ ...prev, display_group: newValue.display_group }));
        } else {
            setFormData((prev) => ({ ...prev, display_group: '' }));
        }
    };

    return (
        <Modal open={open} onClose={onClose} closeAfterTransition BackdropComponent={Backdrop} BackdropProps={{ timeout: 500 }}>
            <Fade in={open}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '80%',
                        maxHeight: '80vh',
                        bgcolor: 'background.paper',
                        borderRadius: 2,
                        boxShadow: 24,
                        p: 4,
                        overflowY: 'auto',
                    }}
                >
                    <Typography variant="h6" gutterBottom>แก้ไขข้อมูลการแสดงผลเซ็นเซอร์และการควบคุม</Typography>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12}>
                            <Autocomplete
                                freeSolo
                                options={uniqueKeys || []}
                                getOptionLabel={(option) => typeof option === "string" ? option : ""}
                                value={formData.display_group}
                                onChange={handleAutocompleteChange}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        fullWidth
                                        label="ชื่อกลุ่มที่จะแสดง"
                                        name="display_group"
                                        onChange={(e) => handleAutocompleteChange(null, e.target.value)}
                                        margin="normal"
                                        error={!!errors.display_group}
                                        helperText={errors.display_group}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                    <Button variant="contained" color="primary" onClick={handleSubmit} sx={{ mt: 3 }} disabled={!canSubmit()}>
                        บันทึกข้อมูล
                    </Button>
                </Box >
            </Fade >
        </Modal >
    );
};

export default ManageProgramTemplateDisplayUpdateModal;
import { createSlice, createAsyncThunk, PayloadAction, AnyAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import config from "../../config";
import { UserInfo } from "../../models/userInfo/UserInfo.model";
import { TOKEN } from "../../services/Constants/systems";
import { httpClient } from "../../services/Connection/httpclient";

// Interface สำหรับ State
interface UserInfoState {
    data: UserInfo[] | UserInfo | null; // รองรับทั้ง array และ object
    dataUserLogin: UserInfo | null;  // เก็บข้อมูลผู้ใช้ที่ล็อกอิน
    isLoading: boolean;
    isError: boolean;
    errorMessage: string | null;
}

// Initial state
const initialState: UserInfoState = {
    data: null,
    dataUserLogin: null, // ข้อมูลผู้ใช้ที่ล็อกอิน
    isLoading: false,
    isError: false,
    errorMessage: null,
};

// Async thunk สำหรับการเรียก API
export const fetchUserInfo = createAsyncThunk<
    UserInfo[] | UserInfo,
    boolean,
    { rejectValue: string }
>("userInfo/fetchUserInfo", async (getAllStatus, { rejectWithValue }) => {
    try {
        const token = localStorage.getItem(TOKEN);
        if (token) {
            const response = await httpClient.post(
                config.user_info.user_url + config.user_info.user_userInfo,
                null,
                {
                    headers: {
                        'authorization': token,
                        'getallstatus': getAllStatus.toString(),
                    },
                }
            );

            if (response.data.status) {
                return response.data.data.response;
            } else {
                return rejectWithValue(response.data.message);
            }
        } else {
            return rejectWithValue("Token is missing");
        }
    } catch (error: any) {
        return rejectWithValue(
            error.message || "เกิดข้อผิดพลาด หรือ session หมดอายุ กรุณาเข้าสู่ระบบใหม่อีกครั้ง"
        );
    }
});

// สร้าง Slice
const userInfoSlice = createSlice({
    name: "userInfo",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchUserInfo.pending, (state) => {
                state.isLoading = true;
                state.isError = false;
                state.errorMessage = null;
            })
            .addCase(
                fetchUserInfo.fulfilled,
                (state, action: AnyAction) => {
                    state.isLoading = false;
                    if (action.meta.arg === true) {
                        state.data = action.payload as UserInfo[];  // ถ้า getAllStatus = true เก็บข้อมูลทั้งหมด
                    } else {
                        state.dataUserLogin = action.payload as UserInfo;    // ถ้า getAllStatus = false เก็บข้อมูลผู้ใช้ที่ล็อกอิน
                    }
                }
            )
            .addCase(
                fetchUserInfo.rejected,
                (state, action: PayloadAction<string | undefined>) => {
                    state.isLoading = false;
                    state.isError = true;
                    state.errorMessage = action.payload || "เกิดข้อผิดพลาด หรือ session หมดอายุ กรุณาเข้าสู่ระบบใหม่อีกครั้ง";
                }
            );
    },
});

export const userInfoSelector = (store: RootState) => store.userInfo;
export default userInfoSlice.reducer;

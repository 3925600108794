// src/components/pages/UserInfo/styledComponents.ts

import { styled } from '@mui/system';
import { Accordion } from '@mui/material';


export const HandleTransparentAccordion = styled(Accordion)(({ theme }) => ({
    backgroundColor: '#FFFFFF',
    borderRadius: '8px',
    boxShadow: 'none',
    marginBottom: theme.spacing(2),
}));

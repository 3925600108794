import { createTheme } from "@mui/material/styles";

const drawerWidth = 240;

const themes = createTheme({
  components: {
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundImage:
            "url(" +
            `${process.env.PUBLIC_URL}/images/background_menu.jpg` +
            ")",
          width: drawerWidth,
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        h6: {
          fontSize: "1rem",
          fontWeight: "bold",
        },
      },
    },
  },
  typography: {
    fontFamily: "Fredoka",
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 600,
  },
  spacing: 8,
  palette: {
    primary: {
      main: "#3490DC",
    },
    background: {
      default: "#f7f7f7",
    },
  },
});

export default themes;

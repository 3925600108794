// Modal สำหรับเพิ่มข้อมูล
import React, { useEffect, useState } from 'react';
import { Box, Button, Modal, Fade, Backdrop, Typography, TextField, Grid } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../stores/store';
import { manageFloodgateSchema, manageFloodgateSchemaSelector } from '../../../stores/slices/ManageFloodgateSchemaSlice';
import { AddFloodgateSchemaRequest } from '../../../models/manageFloodgateGroupSchema/ManageFloodgateSchemaRequest.model';
import { floodgateAction, floodgateManageAction } from '../../../services/Constants/enum/enum.model';
import { fetchFloodGateInfo } from '../../../stores/slices/FloodGateInfoSlice'; // Import action เพื่อ reload ข้อมูล
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

interface ModalProps {
    open: boolean;
    onClose: () => void;
}

const ManageFloodGateSchemaCreateModal: React.FC<ModalProps> = ({ open, onClose }) => {
    const dispatch = useAppDispatch();
    const schemaTypes = useAppSelector(manageFloodgateSchemaSelector).data || [];

    const [newData, setNewData] = useState<AddFloodgateSchemaRequest>({
        action: floodgateManageAction.ADD,
        floodgate_schema_type_name: '',
    });

    const [errors, setErrors] = useState<{ [key: string]: string }>({});

    // useEffect(() => {
    //     if (open) {
    //         dispatch(manageFloodgateSchema({ action: floodgateManageAction.GET }));
    //     }
    // }, [dispatch, open]);

    const resetForm = () => {
        setNewData({
            action: floodgateManageAction.ADD,
            floodgate_schema_type_name: '',
        });
        setErrors({});
    };

    const validate = () => {
        let tempErrors: { [key: string]: string } = {};

        // Validate floodgate_name (Thai, English, or numbers only)
        if (!newData.floodgate_schema_type_name) {
            tempErrors.floodgate_name = 'กรุณาใส่ชื่อกลุ่มโครงการ';
        } else if (!/^[ก-๙a-zA-Z0-9\s]+$/.test(newData.floodgate_schema_type_name)) {
            tempErrors.floodgate_name = 'ชื่อกลุ่มโครงการต้องเป็นภาษาไทย ภาษาอังกฤษ หรือตัวเลขเท่านั้น';
        }

        setErrors(tempErrors);
        return Object.keys(tempErrors).length === 0;
    };

    const handleChange = (field: keyof AddFloodgateSchemaRequest) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setNewData({ ...newData, [field]: event.target.value });
        setErrors((prev) => ({ ...prev, [field]: '' }));
    };

    const handleSave = async () => {
        if (validate()) {
            try {
                await dispatch(manageFloodgateSchema(newData)).unwrap();
                toast.success('บันทึกข้อมูลสำเร็จ');

                // รีโหลดข้อมูลใหม่หลังจากที่บันทึกสำเร็จ
                dispatch(fetchFloodGateInfo(floodgateAction.getAllByAdmin));

                resetForm();
                onClose();
            } catch (error) {
                toast.error('บันทึกข้อมูลไม่สำเร็จ');
            }
        }
    };

    const handleClose = () => {
        resetForm();
        onClose();
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}
            closeAfterTransition
        >
            <Fade in={open}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '30%',
                        maxHeight: '80vh',
                        bgcolor: 'background.paper',
                        borderRadius: 2,
                        boxShadow: 24,
                        p: 4,
                        overflowY: 'auto',
                    }}
                >
                    <Typography variant="h6" component="h2" gutterBottom>
                        เพิ่มข้อมูลกลุ่มโครงการ
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                fullWidth
                                label="ชื่อกลุ่มโครงการ"
                                value={newData.floodgate_schema_type_name}
                                onChange={handleChange('floodgate_schema_type_name')}
                                required
                                error={!!errors.floodgate_name}
                                helperText={errors.floodgate_name}
                            />
                        </Grid>
                    </Grid>

                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                        <Button onClick={handleClose} sx={{ mr: 2 }}>
                            ยกเลิก
                        </Button>
                        <Button variant="contained" onClick={handleSave}>
                            บันทึก
                        </Button>
                    </Box>
                </Box>
            </Fade>
        </Modal>
    );
};

export default ManageFloodGateSchemaCreateModal;
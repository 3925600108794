import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import config from "../../config";
import { MonitorDetailRequest } from "../../models/monitor/MonitorDetailRequest.model";
import { MonitorDetailResponse } from "../../models/monitor/MonitorDetailResponse.model";
import { TOKEN } from "../../services/Constants/systems";
import { httpClient } from "../../services/Connection/httpclient";

// Interface สำหรับ State
interface MonitorDetailState {
    data: MonitorDetailResponse['data'] | null;
    isLoading: boolean;
    isError: boolean;
    errorMessage: string | null;
}

// Initial state
const initialState: MonitorDetailState = {
    data: null,
    isLoading: false,
    isError: false,
    errorMessage: null,
};

// Async thunk สำหรับการดึงข้อมูล monitor detail
export const fetchMonitorDetail = createAsyncThunk<
    MonitorDetailResponse['data'], // Response type
    MonitorDetailRequest, // Request type
    { rejectValue: string }
>(
    "monitorDetail/fetchMonitorDetail",
    async (monitorDetailRequest, { rejectWithValue }) => {
        try {
            const token = localStorage.getItem(TOKEN);
            if (!token) {
                return rejectWithValue("Token is missing");
            }

            const response = await httpClient.post(
                config.floodgate.floodgate_url + config.floodgate.floodgate_detail,
                monitorDetailRequest,
                {
                    headers: {
                        'authorization': token,
                        'Content-Type': 'application/json',
                    },
                }
            );

            if (response.data.status) {
                return response.data.data;
            } else {
                return rejectWithValue(response.data.message);
            }
        } catch (error: any) {
            return rejectWithValue(error.message || "เกิดข้อผิดพลาด หรือ session หมดอายุ กรุณาเข้าสู่ระบบใหม่อีกครั้ง");
        }
    }
);

// สร้าง Slice
const monitorDetailSlice = createSlice({
    name: "monitorDetail",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchMonitorDetail.pending, (state) => {
                state.isLoading = true;
                state.isError = false;
                state.errorMessage = null;
            })
            .addCase(
                fetchMonitorDetail.fulfilled,
                (state, action: PayloadAction<MonitorDetailResponse['data']>) => {
                    state.isLoading = false;
                    state.data = action.payload;
                }
            )
            .addCase(
                fetchMonitorDetail.rejected,
                (state, action: PayloadAction<string | undefined>) => {
                    state.isLoading = false;
                    state.isError = true;
                    state.errorMessage = action.payload || "เกิดข้อผิดพลาด หรือ session หมดอายุ กรุณาเข้าสู่ระบบใหม่อีกครั้ง";
                }
            );
    },
});

export const monitorDetailSelector = (store: RootState) => store.monitorDetail;
export default monitorDetailSlice.reducer;

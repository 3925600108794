import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from "../../../stores/store";
import { userLogout } from "../../../stores/slices/LoginSlice";
import { TOKEN } from '../../../services/Constants/systems';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { Avatar, Button, Menu, MenuItem, Tooltip } from "@mui/material";
import { fetchUserInfo, userInfoSelector } from '../../../stores/slices/UserInfoSlice';
import { UserInfo } from '../../../models/userInfo/UserInfo.model';

const drawerWidth = 240;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

type HeaderProp = {
  open: boolean;
  onDrawerOpen: () => void;
  setOpen: (open: boolean) => void;
};

function stringToColor(string: string) {
  let hash = 0;
  let i;
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }
  let color = '#';
  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  return color;
}

function stringAvatar(name: string) {
  const names = name.split(' ');
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${names[0][0]}${names[1]?.[0] || ''}`,
  };
}

export default function Header({ open, onDrawerOpen, setOpen }: HeaderProp) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const { dataUserLogin: userData, isLoading, isError, errorMessage } = useAppSelector(userInfoSelector); // Rename for clarity
  const [avatarName, setAvatarName] = useState<string>('Unknown User'); // Set default state for avatarName

  // ดึงข้อมูลผู้ใช้เมื่อ component mount
  useEffect(() => {
    dispatch(fetchUserInfo(false)); // Fetch user info on mount
  }, [dispatch]);

  // อัปเดต avatarName เมื่อมีข้อมูลผู้ใช้ใหม่
  useEffect(() => {
    if (userData) {
      const user = Array.isArray(userData) ? userData[0] : userData;
      const name = `${user?.user_first_name_th} ${user?.user_last_name_th || ''}`.trim();
      setAvatarName(name);
    }
  }, [userData]);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleDrawerOpen = () => {
    onDrawerOpen();
  };

  const handleLogout = () => {
    localStorage.removeItem(TOKEN);
    dispatch(userLogout());
    setOpen(true);
    navigate("/login");
  };

  const handleMenuClick = (setting: string) => {
    handleCloseUserMenu();
    if (setting === 'ออกจากระบบ') {
      handleLogout();
    }
  };

  return (
    <AppBar position="fixed" open={open}>
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          sx={{ mr: 2, ...(open && { display: 'none' }) }}
        >
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" padding={0.7} noWrap component="div" sx={{ display: { xs: "none", sm: "flex", md: "flex" } }}>
          ระบบแสดงผลและควบคุมประตูระบายน้ำ<br />
          (Flood Gates Monitoring and Control)
        </Typography>

        <Box sx={{ flexGrow: 1 }} />

        <Box sx={{ flexGrow: 0 }}>
          <Tooltip title="แสดงเมนูทั้งหมด">
            <Button onClick={handleOpenUserMenu} sx={{ p: 0 }}>
              <Avatar {...stringAvatar(avatarName)} />
              <Typography padding={0.4} fontSize={14} noWrap component="div" sx={{ 'textAlign': 'left', 'color': 'white' }}>
                {avatarName}
              </Typography>
            </Button>
          </Tooltip>
          <Menu
            sx={{ mt: '45px' }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            {['ออกจากระบบ'].map((setting) => (
              <MenuItem key={setting} onClick={() => handleMenuClick(setting)}>
                <Typography textAlign="center">{setting}</Typography>
              </MenuItem>
            ))}
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
}
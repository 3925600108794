import * as React from "react";
import { Link } from "react-router-dom";

type NotfoundProps = {
  //
};

const Notfound: React.FC<any> = () => (
  <div>
    <h1>404 - Not Found!</h1>
    <Link to="/">Go Home</Link>
  </div>
);

export default Notfound;

import * as React from "react";
import { useNavigate } from "react-router-dom";
import { Formik, FormikProps } from "formik";
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  Stack,
  SxProps,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { AuthenReq } from "../../../models/authen/AuthenReq.model";
import { useAppDispatch, useAppSelector } from "../../../stores/store";
import { loginSelector, userLogin } from "../../../stores/slices/LoginSlice";
import { fetchUserRoles } from "../../../stores/slices/UserRolesSlice";
import { fetchUserInfo } from "../../../stores/slices/UserInfoSlice";

interface LoginPageProps {
  setOpen: (open: boolean) => void;  // รับฟังก์ชัน setOpen ผ่าน props
}

const LoginPage: React.FC<LoginPageProps> = ({ setOpen }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const loginReducer = useAppSelector(loginSelector);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleLogin = async (AuthenReq: AuthenReq) => {
    try {
      const res = await dispatch(userLogin(AuthenReq)).unwrap();
      if (res?.status) {
        toast.success('เข้าสู่ระบบสำเร็จ ยินดีต้อนรับ');
        dispatch(fetchUserRoles(false)).unwrap();
        await dispatch(fetchUserInfo(false));
        if (isMobile) {
          setOpen(false);
        } else {
          setOpen(true);
        }
        navigate('/floodgate/report');
      } else {
        toast.error(res?.error?.details || 'เข้าสู่ระบบไม่สำเร็จ โปรตรสจสอบข้อมูล username และ password');
      }
    } catch (error: any) {
      toast.error('เข้าสู่ระบบไม่สำเร็จ โปรตรสจสอบข้อมูล username และ password');
    }
  };

  const classes: SxProps<Theme> | any = {
    root: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      minHeight: "70vh", // ตั้งค่าให้ Box สูงเท่ากับหน้าจอ
    },
    buttons: { marginTop: 2 },
  };

  const showFormV2 = ({
    handleSubmit,
    getFieldProps,
    isSubmitting,
  }: FormikProps<AuthenReq>) => (
    <form onSubmit={handleSubmit}>
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        id="username"
        label="Username"
        {...getFieldProps('username')}
        autoFocus
      />

      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        id="password"
        label="Password"
        {...getFieldProps('password')}
        type="password"
      />
      <br />
      {loginReducer.isError && (
        <Alert severity="error">เข้าสู่ระบบไม่สำเร็จ: {loginReducer.result?.error?.details || 'Unknown error'}</Alert>
      )}
      <Stack direction="row" spacing={2} sx={classes.buttons}>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          disabled={loginReducer.isFetching || isSubmitting}
        >
          Login
        </Button>
      </Stack>
    </form>
  );

  const initialValues: AuthenReq = { username: "", password: "" };
  return (
    <>
      <Box sx={classes.root}>
        <Card sx={{ maxWidth: 345 }}>
          <CardContent>
            <Typography gutterBottom variant="h5" component="h2">
              เข้าสู่ระบบ
            </Typography>
            <Formik
              onSubmit={handleLogin}
              initialValues={initialValues}
            >
              {(props) => showFormV2(props)}
            </Formik>
          </CardContent>
        </Card>
      </Box>
    </>
  );
};

export default LoginPage;

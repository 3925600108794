import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import config from "../../config";
import { ManageFloodgateSchemaRequest } from "../../models/manageFloodgateGroupSchema/ManageFloodgateSchemaRequest.model";
import { ManageFloodgateSchemaResponse } from "../../models/manageFloodgateGroupSchema/ManageFloodgateSchemaResponse.model";
import { TOKEN } from "../../services/Constants/systems";
import { httpClient } from "../../services/Connection/httpclient";
import { floodgateManageAction } from '../../services/Constants/enum/enum.model';

// Interface สำหรับ State
interface ManageFloodgateSchemaState {
    data: ManageFloodgateSchemaResponse['data']['response'] | null;
    isLoading: boolean;
    isError: boolean;
    errorMessage: string | null;
}

// Initial state
const initialState: ManageFloodgateSchemaState = {
    data: null,
    isLoading: false,
    isError: false,
    errorMessage: null,
};

export const manageFloodgateSchema = createAsyncThunk<
    ManageFloodgateSchemaResponse['data']['response'],
    ManageFloodgateSchemaRequest,
    { rejectValue: string }
>("manageFloodgateSchema/manageFloodgateSchema", async (schemaInfo, { rejectWithValue }) => {
    try {
        const token = localStorage.getItem(TOKEN);
        if (token) {

            let actionHeader = '';
            switch (schemaInfo.action) {
                case floodgateManageAction.ADD:
                    actionHeader = 'ADD';
                    break;
                case floodgateManageAction.UPDATE:
                    actionHeader = 'UPDATE';
                    break;
                case floodgateManageAction.DELETE:
                    actionHeader = 'DELETE';
                    break;
                case floodgateManageAction.GET:
                    actionHeader = 'GET';
                    break;
                default:
                    return rejectWithValue("Invalid action");
            }

            // Prepare the request config
            const configRequest = {
                headers: {
                    'authorization': token,
                    'action': actionHeader,
                    'Content-Type': 'application/json',
                },
            };

            if ('action' in schemaInfo && schemaInfo.action) {
                delete schemaInfo?.action;
            }

            // For GET, we don't send a body
            const response = actionHeader === 'GET'
                ? await httpClient.post(config.floodgate.floodgate_url + config.floodgate.floodgate_manage_schema, {}, configRequest)
                : await httpClient.post(config.floodgate.floodgate_url + config.floodgate.floodgate_manage_schema, schemaInfo, configRequest);

            if (response.data.status) {
                return response.data.data.response;
            } else {
                return rejectWithValue(response.data.message);
            }
        } else {
            return rejectWithValue("Token is missing");
        }
    } catch (error: any) {
        return rejectWithValue(error.message || "เกิดข้อผิดพลาด หรือ session หมดอายุ กรุณาเข้าสู่ระบบใหม่อีกครั้ง");
    }
});

// สร้าง Slice
const manageFloodgateSchemaSlice = createSlice({
    name: "manageFloodgateSchema",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(manageFloodgateSchema.pending, (state) => {
                state.isLoading = true;
                state.isError = false;
                state.errorMessage = null;
            })
            .addCase(
                manageFloodgateSchema.fulfilled,
                (state, action: PayloadAction<ManageFloodgateSchemaResponse['data']['response']>) => {
                    state.isLoading = false;
                    state.data = action.payload;
                }
            )
            .addCase(
                manageFloodgateSchema.rejected,
                (state, action: PayloadAction<string | undefined>) => {
                    state.isLoading = false;
                    state.isError = true;
                    state.errorMessage = action.payload || "เกิดข้อผิดพลาด หรือ session หมดอายุ กรุณาเข้าสู่ระบบใหม่อีกครั้ง";
                }
            );
    },
});

export const manageFloodgateSchemaSelector = (store: RootState) => store.manageFloodgateSchema;
export default manageFloodgateSchemaSlice.reducer;
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import config from "../../config";
import { httpClient } from "../../services/Connection/httpclient";
import { RootState } from "../store";
import { TOKEN } from "../../services/Constants/systems";
import { AuthenReq } from "../../models/authen/AuthenReq.model";
import { AuthenRes } from "../../models/authen/AuthenRes.model";

export interface LoginState {
  isFetching: boolean;
  isError: boolean;
  result: any;
  isLoggedIn: boolean;
}

const initialState: LoginState = {
  isFetching: false,
  isError: false,
  result: null,
  isLoggedIn: false,
};

export const userLogin = createAsyncThunk(
  "login/login",
  async (AuthenReq: AuthenReq | null, { rejectWithValue, dispatch }) => {
    try {
      if (AuthenReq) {
        const result = await httpClient.post<AuthenRes>(
          config.user_info.user_url + config.user_info.user_authenticatin,
          AuthenReq
        );
        if (result.data.status) {
          localStorage.setItem(TOKEN, result.data.data?.token!);
          return result.data;
        } else {
          return rejectWithValue(result.data.error);
        }
      } else {
        const token = localStorage.getItem(TOKEN);
        if (token) {
          const result = await httpClient.post<AuthenRes>(
            config.user_info.user_url + config.user_info.user_authenticatin_check,
            {},
            {
              headers: {
                authorization: token,
              },
            }
          );
          if (result.data.status) {
            localStorage.setItem(TOKEN, result.data.data?.token!);
            return result.data;
          } else {
            if (result.data.error?.code === "TOKEN_EXPIRED") {
              localStorage.removeItem(TOKEN);
              dispatch(userLogout());
              return rejectWithValue("Token expired. Please login again.");
            }
            return rejectWithValue(result.data.error);
          }
        } else {
          dispatch(userLogout());
          return rejectWithValue("Token not found. Please login.");
        }
      }
    } catch (error: any) {
      const customError = {
        status: false,
        message: error.message || "เกิดข้อผิดพลาด หรือ session หมดอายุ กรุณาเข้าสู่ระบบใหม่อีกครั้ง",
        error: error?.response?.data?.error || { code: "UNKNOWN_ERROR", details: "No additional details" },
      };

      if (customError.error.code === "TOKEN_EXPIRED") {
        localStorage.removeItem(TOKEN);
        dispatch(userLogout());
        return rejectWithValue("Token expired. Please login again.");
      }

      return rejectWithValue(customError);
    }
  }
);

const LoginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    userLogout: (state) => {
      state.isFetching = false;
      state.isError = false;
      state.result = null;
      state.isLoggedIn = false;
      localStorage.removeItem(TOKEN);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(userLogin.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(userLogin.fulfilled, (state, action) => {
      state.isFetching = false;
      state.isError = false;
      state.result = action.payload;
      state.isLoggedIn = true;
    });
    builder.addCase(userLogin.rejected, (state, action) => {
      state.isFetching = false;
      state.isError = true;
      state.result = action.payload;
      state.isLoggedIn = false;
      localStorage.removeItem(TOKEN);
    });
  },
});

export const { userLogout } = LoginSlice.actions;
export const loginSelector = (store: RootState) => store.login;
export default LoginSlice.reducer;

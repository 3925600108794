import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import config from "../../config";
import { AddFloodgateNodeRequest, UpdateFloodgateNodeRequest, DeleteFloodgateNodeRequest } from "../../models/manageFloodgateNode/ManageFloodgateNodeRequest.model";
import { ManageFloodgateNodeResponse } from "../../models/manageFloodgateNode/ManageFloodgateNodeResponse.model";
import { TOKEN } from "../../services/Constants/systems";
import { floodgateManageAction } from '../../services/Constants/enum/enum.model';
import { httpClient } from "../../services/Connection/httpclient";

// Interface สำหรับ State
interface ManageFloodgateNodeState {
    data: ManageFloodgateNodeResponse['data']['response'] | null;
    isLoading: boolean;
    isError: boolean;
    errorMessage: string | null;
}

// Initial state
const initialState: ManageFloodgateNodeState = {
    data: null,
    isLoading: false,
    isError: false,
    errorMessage: null,
};

// Async thunk สำหรับการจัดการ Floodgate Node (ADD, UPDATE, DELETE)
export const manageFloodgateNode = createAsyncThunk<
    ManageFloodgateNodeResponse['data']['response'],
    AddFloodgateNodeRequest | UpdateFloodgateNodeRequest | DeleteFloodgateNodeRequest,
    { rejectValue: string }
>(
    "manageFloodgateNode/manageFloodgateNode",
    async (nodeInfo, { rejectWithValue }) => {
        try {
            const token = localStorage.getItem(TOKEN);
            if (!token) {
                return rejectWithValue("Token is missing");
            }

            // Map action header to the API request
            let actionHeader = '';
            switch (nodeInfo.action) {
                case floodgateManageAction.ADD:
                    actionHeader = 'ADD';
                    break;
                case floodgateManageAction.UPDATE:
                    actionHeader = 'UPDATE';
                    break;
                case floodgateManageAction.DELETE:
                    actionHeader = 'DELETE';
                    break;
                default:
                    return rejectWithValue("Invalid action");
            }

            // ลบ action ออกจาก request body ก่อนส่งไป API
            const { action, ...body } = nodeInfo;

            const response = await httpClient.post(
                config.floodgate.floodgate_url + config.floodgate.floodgate_manage_node,
                body,
                {
                    headers: {
                        'authorization': token,
                        'action': actionHeader,
                        'Content-Type': 'application/json',
                    },
                }
            );

            if (response.data.status) {
                return response.data.data.response;
            } else {
                return rejectWithValue(response.data.message);
            }
        } catch (error: any) {
            return rejectWithValue(error.message || "เกิดข้อผิดพลาด หรือ session หมดอายุ กรุณาเข้าสู่ระบบใหม่อีกครั้ง");
        }
    }
);

// สร้าง Slice
const manageFloodgateNodeSlice = createSlice({
    name: "manageFloodgateNode",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(manageFloodgateNode.pending, (state) => {
                state.isLoading = true;
                state.isError = false;
                state.errorMessage = null;
            })
            .addCase(
                manageFloodgateNode.fulfilled,
                (state, action: PayloadAction<ManageFloodgateNodeResponse['data']['response']>) => {
                    state.isLoading = false;
                    state.data = action.payload;
                }
            )
            .addCase(
                manageFloodgateNode.rejected,
                (state, action: PayloadAction<string | undefined>) => {
                    state.isLoading = false;
                    state.isError = true;
                    state.errorMessage = action.payload || "เกิดข้อผิดพลาด หรือ session หมดอายุ กรุณาเข้าสู่ระบบใหม่อีกครั้ง";
                }
            );
    },
});

export const manageFloodgateNodeSelector = (store: RootState) => store.manageFloodgateNode;
export default manageFloodgateNodeSlice.reducer;
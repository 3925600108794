import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { DataGrid, GridColDef, GridRowId, GridToolbarContainer, GridToolbarContainerProps, GridRowSelectionModel, GridCallbackDetails } from '@mui/x-data-grid';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import Button from '@mui/material/Button';
import Swal from 'sweetalert2';
import { useAppDispatch, useAppSelector } from '../../../stores/store';
import { fetchUserInfo, userInfoSelector } from '../../../stores/slices/UserInfoSlice';
import { manageUserInfo } from '../../../stores/slices/ManageUserInfoSlice';
import UserEditModal from './UserEditModal';
import { UserInfo } from '../../../models/userInfo/UserInfo.model';
import { ManageUserInfoRequest } from '../../../models/userInfo/ManageUserInfo.model';
import PermissionModal from './PermissionModal';
import { floodgateRelationSelector, manageFloodgateRelation } from '../../../stores/slices/FloodgateRelationSlice';
import { floodgateManageAction } from '../../../services/Constants/enum/enum.model';

interface UserInfoRow extends UserInfo {
    id: string;
    isNew?: boolean;
}

export default function UserTable() {
    const dispatch = useAppDispatch();
    const { data, isLoading, isError, errorMessage } = useAppSelector(userInfoSelector);
    const [rows, setRows] = useState<UserInfoRow[]>([]);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [editUser, setEditUser] = useState<ManageUserInfoRequest | null>(null);
    const [selectedRowId, setSelectedRowId] = useState<GridRowId | null>(null);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedAccountId, setSelectedAccountId] = useState<string | null>(null); // เก็บ account_id ของผู้ใช้ที่เลือก

    const { dataForFetch } = useAppSelector(floodgateRelationSelector);

    const handleOpenModal = () => {
        const selectedUser = rows.find((row) => row.id === selectedRowId);
        if (selectedUser) {
            setSelectedAccountId(selectedUser.Account.account_id || '');
            setIsModalOpen(true);
        }
    };

    const handleCloseModal = () => setIsModalOpen(false);

    useEffect(() => {
        dispatch(fetchUserInfo(true));
        dispatch(manageFloodgateRelation({ action: floodgateManageAction.GET }))
    }, [dispatch]);

    useEffect(() => {
        if (Array.isArray(data)) {
            const formattedRows = data.map((user) => ({
                ...user,
                id: user.user_id || '',
            }));
            setRows(formattedRows);
        }
    }, [data]);

    const handleEditClick = () => {
        const userToEdit = rows.find((row) => row.id === selectedRowId);
        if (userToEdit) {
            const userEditData: ManageUserInfoRequest = {
                action: 'update',
                ...userToEdit,
            };
            setEditUser(userEditData);
            setEditModalOpen(true);
        }
    };

    const handleDeleteClick = async () => {
        const rowToDelete = rows.find((row) => row.id === selectedRowId);
        if (rowToDelete) {
            const result = await Swal.fire({
                title: 'คุณแน่ใจหรือไม่?',
                text: 'คุณจะไม่สามารถย้อนกลับการลบข้อมูลนี้ได้!',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'ใช่, ลบเลย!',
                cancelButtonText: 'ไม่, ยกเลิก!',
            });

            if (result.isConfirmed) {

                const floodgateRelationIdsToDelete = dataForFetch
                    ?.filter(f => f.account_id === rowToDelete.Account.account_id) // กรองเฉพาะที่มี account_id ตรงกัน
                    .map(item => item.floodgate_relation_id);  // ดึง floodgate_relation_id

                if (floodgateRelationIdsToDelete && floodgateRelationIdsToDelete.length > 0) {
                    // ใช้ Promise.all เพื่อจัดการลบทั้งหมดในรูปแบบคิว
                    const deletePromises = floodgateRelationIdsToDelete.map(floodgate_relation_id => {
                        //console.log(floodgate_relation_id, rowToDelete.Account.account_id);
                        return dispatch(manageFloodgateRelation({
                            action: floodgateManageAction.DELETE,
                            floodgate_relation_id: floodgate_relation_id, // ลบทีละตัว
                            account_id: rowToDelete.Account.account_id,
                        }));
                    });

                    // รอให้ทุกการลบในคิวเสร็จสิ้น
                    await Promise.all(deletePromises);
                }
                // หลังจากลบ floodgate relations เสร็จแล้วค่อยลบ user
                const response = await dispatch(manageUserInfo({ action: 'delete', user_id: rowToDelete.user_id })).unwrap();

                if (response.status) {
                    setRows((prevRows) => prevRows.filter((row) => row.id !== selectedRowId));
                    Swal.fire('ลบแล้ว!', 'ข้อมูลของคุณถูกลบแล้ว.', 'success');
                } else {
                    Swal.fire('เกิดข้อผิดพลาด!', response.message, 'error');
                }
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire('ยกเลิกแล้ว', 'ข้อมูลของคุณยังปลอดภัย :)', 'info');
            }
        }
    };

    const handleRowSelection = (rowSelectionModel: GridRowSelectionModel) => {
        setSelectedRowId(rowSelectionModel.length === 1 ? rowSelectionModel[0] : null);
    };

    const CustomToolbar = (props: GridToolbarContainerProps) => (
        <GridToolbarContainer {...props} >
            <Button
                startIcon={<EditIcon />}
                onClick={handleEditClick}
                disabled={!selectedRowId}
            >
                แก้ไข
            </Button>
            <Button
                startIcon={<DeleteIcon />}
                onClick={handleDeleteClick}
                disabled={!selectedRowId}
            >
                ลบ
            </Button>
            <Button
                startIcon={<AddIcon />}
                onClick={handleOpenModal}
                disabled={!selectedRowId}
            >
                สิทธิ์การเข้าถึงโครงการ
            </Button>
        </GridToolbarContainer>
    );

    const columns: GridColDef[] = [
        { field: 'user_prefix', headerName: 'คำนำหน้า', width: 100 },
        { field: 'user_first_name_th', headerName: 'ชื่อภาษาไทย', width: 180 },
        { field: 'user_last_name_th', headerName: 'นามสกุลภาษาไทย', width: 180 },
        { field: 'user_first_name_en', headerName: 'ชื่อภาษาอังกฤษ', width: 180 },
        { field: 'user_last_name_en', headerName: 'นามสกุลภาษาอังกฤษ', width: 180 },
        { field: 'user_phone_number', headerName: 'เบอร์โทรศัพท์', width: 150 },
        { field: 'user_age', headerName: 'อายุ', width: 80 },
        { field: 'user_email', headerName: 'อีเมลล์', width: 220 },
        { field: 'user_addr_house_num', headerName: 'บ้านเลขที่', width: 150 },
        { field: 'user_addr_floor', headerName: 'ชั้น', width: 100 },
        { field: 'user_addr_village', headerName: 'หมู่บ้าน', width: 150 },
        { field: 'user_addr_village_num', headerName: 'บ้านเลขที่', width: 150 },
        { field: 'user_addr_alley', headerName: 'ตรอก', width: 150 },
        { field: 'user_addr_soi', headerName: 'ซอย', width: 150 },
        { field: 'user_addr_road', headerName: 'ถนน', width: 150 },
        { field: 'user_addr_subdistrict', headerName: 'ตำบล', width: 150 },
        { field: 'user_addr_district', headerName: 'เขต/อำเภอ', width: 150 },
        { field: 'user_addr_province', headerName: 'จังหวัด', width: 150 },
        { field: 'user_addr_postcode', headerName: 'ไปรษณีย์', width: 150 },
    ];

    if (isLoading) return <CircularProgress />;
    if (isError) return <Typography>Error: {errorMessage}</Typography>;

    return (
        <Box sx={{ height: 500, width: '100%' }}>
            <DataGrid
                rows={rows}
                columns={columns}
                checkboxSelection
                onRowSelectionModelChange={(rowSelectionModel: GridRowSelectionModel, details: GridCallbackDetails) =>
                    handleRowSelection(rowSelectionModel)
                }
                slots={{
                    toolbar: CustomToolbar,
                }}
            />

            {editUser && (
                <UserEditModal
                    open={editModalOpen}
                    onClose={() => setEditModalOpen(false)}
                    user={editUser}
                    onSave={(updatedUser: ManageUserInfoRequest) => {
                        setRows((prevRows) =>
                            prevRows.map((row) => (row.id === updatedUser.user_id ? { ...row, ...updatedUser } : row))
                        );
                        setEditModalOpen(false);
                    }}
                />
            )}

            {selectedAccountId && (
                <PermissionModal open={isModalOpen} handleClose={handleCloseModal} account_id={selectedAccountId} />
            )}
        </Box>
    );
}

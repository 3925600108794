import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import config from "../../config";
import { ManageFloodgateTypeRequest } from "../../models/manageFloodgateNodeType/ManageFloodgateTypeRequest.model";
import { ManageFloodgateTypeResponse } from "../../models/manageFloodgateNodeType/ManageFloodgateTypeResponse.model";
import { TOKEN } from "../../services/Constants/systems";
import { httpClient } from "../../services/Connection/httpclient";
import { floodgateManageAction } from '../../services/Constants/enum/enum.model';

// Define the state interface
interface ManageFloodgateTypeState {
    data: ManageFloodgateTypeResponse['data']['response'] | null;
    isLoading: boolean;
    isError: boolean;
    errorMessage: string | null;
}

// Initial state
const initialState: ManageFloodgateTypeState = {
    data: null,
    isLoading: false,
    isError: false,
    errorMessage: null,
};

// Async thunk to handle API actions
export const manageFloodgateType = createAsyncThunk<
    ManageFloodgateTypeResponse['data']['response'],
    ManageFloodgateTypeRequest,
    { rejectValue: string }
>("manageFloodgateType/manageFloodgateType", async (typeInfo, { rejectWithValue }) => {
    try {
        const token = localStorage.getItem(TOKEN);
        if (token) {
            let actionHeader = '';

            switch (typeInfo.action) {
                case floodgateManageAction.ADD:
                    actionHeader = 'ADD';
                    break;
                case floodgateManageAction.UPDATE:
                    actionHeader = 'UPDATE';
                    break;
                case floodgateManageAction.DELETE:
                    actionHeader = 'DELETE';
                    break;
                case floodgateManageAction.GET:
                    actionHeader = 'GET';
                    break;
                default:
                    return rejectWithValue("Invalid action");
            }

            const configRequest = {
                headers: {
                    'authorization': token,
                    'action': actionHeader,
                    'Content-Type': 'application/json',
                },
            };

            if ('action' in typeInfo && typeInfo.action) {
                delete typeInfo?.action;
            }

            const response = actionHeader === 'GET'
                ? await httpClient.post(config.floodgate.floodgate_url + config.floodgate.floodgate_manage_node_type, {}, configRequest)
                : await httpClient.post(config.floodgate.floodgate_url + config.floodgate.floodgate_manage_node_type, typeInfo, configRequest);

            if (response.data.status) {
                return response.data.data.response;
            } else {
                return rejectWithValue(response.data.message);
            }
        } else {
            return rejectWithValue("Token is missing");
        }
    } catch (error: any) {
        return rejectWithValue(error.message || "เกิดข้อผิดพลาด หรือ session หมดอายุ กรุณาเข้าสู่ระบบใหม่อีกครั้ง");
    }
});

// Create the slice
const manageFloodgateTypeSlice = createSlice({
    name: "manageFloodgateType",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(manageFloodgateType.pending, (state) => {
                state.isLoading = true;
                state.isError = false;
                state.errorMessage = null;
            })
            .addCase(
                manageFloodgateType.fulfilled,
                (state, action: PayloadAction<ManageFloodgateTypeResponse['data']['response']>) => {
                    state.isLoading = false;
                    state.data = action.payload;
                }
            )
            .addCase(
                manageFloodgateType.rejected,
                (state, action: PayloadAction<string | undefined>) => {
                    state.isLoading = false;
                    state.isError = true;
                    state.errorMessage = action.payload || "เกิดข้อผิดพลาด หรือ session หมดอายุ กรุณาเข้าสู่ระบบใหม่อีกครั้ง";
                }
            );
    },
});

// Export selectors and reducer
export const manageFloodgateTypeSelector = (store: RootState) => store.manageFloodgateType;
export default manageFloodgateTypeSlice.reducer;
